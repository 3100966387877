const FontsComponent = () => {
  return (
    <>
      <link rel="preload" href="https://monday.com/static/fonts/poppins/poppins-v20-latin-300.woff2" as="font" type="font/woff2" crossOrigin="anonymous"/>
      <link rel="preload" href="https://monday.com/static/fonts/poppins/poppins-v20-latin-regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous"/>
      <link rel="preload" href="https://monday.com/static/fonts/poppins/poppins-v20-latin-600.woff2" as="font" type="font/woff2" crossOrigin="anonymous"/>
      <link rel="preload" href="https://monday.com/static/fonts/poppins/poppins-v20-latin-700.woff2" as="font" type="font/woff2" crossOrigin="anonymous"/>
    </>
  );
};

export default FontsComponent;
