import { PAGE_PERFORMANCE_TRACKING_DATA, PAGE_PERFORMANCE_TRACKING_ERROR } from "../../constants/bigbrain-event-types";

export default class PageLoadTracking {
  constructor(pageLoadUniqueId) {
    this.pageLoadUniqueId = pageLoadUniqueId;
  }
  getPaintsData() {
    const paints = window.performance.getEntriesByType("paint");
    const paintsData = {};

    if (!paints) return paintsData;

    paints.map((paint) => {
      const { name, startTime } = paint;

      paintsData[name] = startTime;
    });

    return paintsData;
  }

  getPerformenceData(data) {
    const {
      template,
      navigationStart,
      "first-paint": firstPaint,
      domInteractive,
      domContentLoadedEventEnd,
      loadEventStart
    } = data;
    return {
      template,
      firstPaint,
      timeToInteractive: domInteractive - navigationStart,
      timeToContentLoad: domContentLoadedEventEnd - navigationStart,
      timeToToalLoad: loadEventStart - navigationStart
    };
  }

  getInfoData(performanceData) {
    const { template, firstPaint, timeToInteractive, timeToContentLoad, timeToToalLoad } = performanceData;

    return {
      kind: template, // template
      info1: firstPaint, // time to first paint
      info2: timeToInteractive, // time to interactive
      info3: timeToContentLoad, // time to content load
      direct_object_id: timeToToalLoad // time to total load
    };
  }

  setPerformenceOnWindow(performenceData) {
    window.mondayPerformence = performenceData;
  }

  track(template) {
    try {
      const timing = window.performance.timing.toJSON();
      const navigation = window.performance.navigation;
      const memory = window.performance.memory; //	not supported in firefox
      const paintsData = this.getPaintsData();

      const data = {
        ...timing,
        redirectCount: navigation && navigation.redirectCount,
        ...paintsData,
        totalJSHeapSize: memory && memory.totalJSHeapSize,
        template
      };

      const performanceData = this.getPerformenceData(data);

      this.setPerformenceOnWindow(performanceData);

      const infoData = this.getInfoData(performanceData);

      BigBrain("track", PAGE_PERFORMANCE_TRACKING_DATA, { data, ...infoData, placement: this.pageLoadUniqueId });
    } catch (error) {
      BigBrain("track", PAGE_PERFORMANCE_TRACKING_ERROR, {
        data: { error: error.message },
        placement: this.pageLoadUniqueId
      });
    }
  }
}
