import { LG, LGR, MD, SM, XL, XS, XS_L, XS_M, XXL, XXS } from "constants/sizes";

export const titleFontSizeNumber = {
  [XXS]: 0.6875,
  [XS_M]: 0.875,
  [XS_L]: 1.125,
  [XS]: 1.375,
  [SM]: 1.75,
  [MD]: 2.25,
  [LG]: 2.75,
  [LGR]: 3.375,
  [XL]: 4,
  [XXL]: 4.5
};

export const titleFontSizes = {
  [XXS]: `${titleFontSizeNumber[XXS]}rem`, // 11px
  [XS_M]: `${titleFontSizeNumber[XS_M]}rem`, // 14px
  [XS_L]: `${titleFontSizeNumber[XS_L]}rem`, // 18px
  [XS]: `${titleFontSizeNumber[XS]}rem`, // 22px
  [SM]: `${titleFontSizeNumber[SM]}rem`, // 28px
  [MD]: `${titleFontSizeNumber[MD]}rem`, // 36px
  [LG]: `${titleFontSizeNumber[LG]}rem`, // 44px
  [LGR]: `${titleFontSizeNumber[LGR]}rem`, // 54px
  [XL]: `${titleFontSizeNumber[XL]}rem`, // 64px
  [XXL]: `${titleFontSizeNumber[XXL]}rem` // 72px
};
