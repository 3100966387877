import { PureComponent } from "react";
import BigBrainScript from "./bigbrain-script";

import { initHeaderScripts } from "/services/header-scripts-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import Polyfills from "components/core/head/polyfills";

const isProduction = process.env.NODE_ENV === "production";
class HeaderScriptsComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      coupon,
      extendTrialCookie,
      marketingTemplateBoardIds,
      clusterId,
      subClusterId,
      clusterIdSource,
      subClusterIdSource,
      localeId,
      template,
      skipClustersQuestion,
      enablePageUsageDurationTracking
    } = this.props;

    initHeaderScripts({
      isProduction,
      coupon,
      marketingTemplateBoardIds,
      clusterId,
      subClusterId,
      localeId,
      extendTrialCookie,
      template,
      skipClustersQuestion,
      enablePageUsageDurationTracking,
      clusterIdSource,
      subClusterIdSource
    });
  }

  render() {
    const { pageType, template, clusterId, subClusterId } = this.props;

    return (
      <>
        <BigBrainScript
          isProduction={isProduction}
          pageType={pageType}
          template={template}
          clusterId={clusterId}
          subClusterId={subClusterId}
        />
        <Polyfills />
      </>
    );
  }
}

export default withAbTests(withGenericConfig(HeaderScriptsComponent));
