import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import { LG, LGR, MD, SM, XL, XS, XS_L, XS_M, XXL, XXS } from "constants/sizes";
import { titleFontSizes } from "./consts";

export const componentStyles = css.global`
  .core-title {
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 48px;
    word-break: break-word;

    &.xss {
      font-size: ${titleFontSizes[XXS]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs_m {
      font-size: ${titleFontSizes[XS_M]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs_l {
      font-size: ${titleFontSizes[XS_L]};
      line-height: 32px;
      font-weight: 400;
    }

    &.xs {
      font-size: ${titleFontSizes[XS]};
      line-height: 32px;
      font-weight: 400;
    }

    &.sm {
      font-size: ${titleFontSizes[SM]};
      line-height: 40px;
      font-weight: 400;
    }

    &.md {
      font-size: ${titleFontSizes[MD]};
      line-height: 48px;
      font-weight: 400;
    }

    &.lg {
      font-size: ${titleFontSizes[LG]};
      line-height: 60px;
      font-weight: 700;
    }

    &.lgr {
      font-size: ${titleFontSizes[LGR]};
      line-height: 68px;
      font-weight: 700;
    }

    &.xl {
      font-size: ${titleFontSizes[XL]};
      line-height: 72px;
      font-weight: 700;
    }

    &.xxl {
      font-size: ${titleFontSizes[XXL]};
      line-height: 85px;
      font-weight: 700;
    }
  }

  .title-and-icon-wrapper {
    display: flex;

    .icon-wrapper {
      margin-right: 14px;
      display: flex;
      align-items: center;

      .picture-icon-wrapper {
        display: flex;
        height: 32px;

        .title-icon {
          height: 100%;
        }
      }

      &.xss {
        .picture-icon-wrapper {
          height: 8px;
        }
      }

      &.xs_m {
        .picture-icon-wrapper {
          height: 12px;
        }
      }

      &.xs {
        .picture-icon-wrapper {
          height: 18px;
        }
      }

      &.sm {
        .picture-icon-wrapper {
          height: 24px;
        }
      }

      &.md {
        .picture-icon-wrapper {
          height: 32px;
        }
      }

      &.lg {
        .picture-icon-wrapper {
          height: 38px;
        }
      }

      &.lgr {
        .picture-icon-wrapper {
          height: 44px;
        }
      }

      &.xl {
        .picture-icon-wrapper {
          height: 56px;
        }
      }

      &.xxl {
        .picture-icon-wrapper {
          height: 62px;
        }
      }

      &.lgr,
      &.xl,
      &.xxl {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .core-title {
      font-size: 2.25rem;
      line-height: 48px;
      font-weight: 400;

      &.md,
      &.lg,
      &.xl {
        font-size: 1.75rem;
        line-height: 40px;
        font-weight: 700;
      }
    }

    .title-and-icon-wrapper {
      .icon-wrapper {
        .picture-icon-wrapper {
          height: 32px;
        }

        &.md,
        &.lg,
        &.xl {
          .picture-icon-wrapper {
            height: 24px;
          }
        }
      }
    }
  }
`;
