import { useEffect } from "react";
import Modal from "react-modal";
import Icon from "monday-ui-react-core/dist/Icon";
import Close from "monday-ui-react-core/dist/icons/Close";
import ModalContentComponent from "./modal-content/modal-content-component";
import { componentStyles } from "./modal-with-signup-component.scss";
import DefaultProps from "./default-props";

const ModalWithSignupComponent = (props) => {
  const { onCloseModal } = props;

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <Modal {...props}>
      <div className="close-button" onClick={onCloseModal}>
        <Icon iconType={Icon.type.SVG} icon={Close} iconSize={24} />
      </div>
      <ModalContentComponent {...props} />
      <style jsx>{componentStyles}</style>
    </Modal>
  );
};

ModalWithSignupComponent.defaultProps = DefaultProps;

export default ModalWithSignupComponent;