import { getQueryStringAsHash } from "utils/url";
import {
  M_AW_AD,
  M_AW_GRP,
  M_AW_KW,
  M_BANNER,
  M_CAMPAIGN,
  M_CAMPAIGN_ID,
  M_CONTENT,
  M_GIFT_SEND,
  M_LANDING_PAGE,
  M_MEDIUM,
  M_REFERRER,
  M_RETARGETING,
  M_SOURCE,
  M_VERTICAL,
  MARKETING_TEMPLATE_BOARD_IDS
} from "constants/cookies";
import { StoriesCookies, TemplateTypeCookie, UserCampaignType } from "services/community/constants-service";
import { setCookie, setCookieIfNeeded } from "services/cookies-service";

const windowLocationIEFix = () => {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
};

const setMarketingCookies = (marketingCookies) => {
  Object.keys(marketingCookies).forEach((key) => {
    setCookieIfNeeded(key, marketingCookies[key]);
  });
};

export const saveMarketingCookies = (url = window.location) => {
  try {
    // Parse URI, get query string hash
    const queryString = getQueryStringAsHash(url);

    // IE doesn't have location.origin
    if (!window.location.origin) {
      window.location.origin = windowLocationIEFix();
    }

    // Extract martketing parameters from url
    const marketingCookies = {
      [M_CAMPAIGN]: queryString.marketing_campaign || queryString.utm_campaign,
      [M_CAMPAIGN_ID]: queryString.marketing_campaign_id || queryString.utm_campaign_id,
      [M_SOURCE]: queryString.marketing_source || queryString.utm_source,
      [M_BANNER]: queryString.marketing_banner || queryString.utm_banner,
      [M_MEDIUM]: queryString.marketing_medium || queryString.utm_medium,
      [M_CONTENT]: queryString.marketing_content || queryString.utm_content,
      [M_RETARGETING]: queryString.marketing_retargeting || queryString.utm_retargeting,
      [M_REFERRER]: queryString.utm_referrer || document.referrer,
      [M_VERTICAL]: queryString.marketing_vertical || "",
      [M_LANDING_PAGE]: window.location.origin + window.location.pathname,
      [M_GIFT_SEND]: queryString.gift_send,
      [M_AW_GRP]: queryString.aw_grp,
      [M_AW_KW]: queryString.aw_kw,
      [M_AW_AD]: queryString.aw_ad,

      [UserCampaignType.STORIES_CAMPAIGN]: queryString.stories_campaign,
      [UserCampaignType.STORIES_SOURCE]: queryString.stories_source,
      [UserCampaignType.STORIES_MEDIUM]: queryString.stories_medium,
      [UserCampaignType.STORIES_CONTENT]: queryString.stories_content,
      [UserCampaignType.STORIES_CAMPAIGN_DATE]: queryString.stories_campaign_date,
      [UserCampaignType.STORIES_SLUG]: queryString.stories_slug,
      [TemplateTypeCookie.STORIES_TEMPLATE_TYPE]: queryString[TemplateTypeCookie.STORIES_TEMPLATE_TYPE],
      [StoriesCookies.MONDAY_USER_ID]: queryString[StoriesCookies.MONDAY_USER_ID]
    };

    // Save marketing parameters into the cookie
    setMarketingCookies(marketingCookies);
  } catch (e) {
    console.log(e);
  }
};

export const saveMarketingTemplateBoardIdsCookie = (marketingTemplateBoardIds) => {
  marketingTemplateBoardIds && setCookie(MARKETING_TEMPLATE_BOARD_IDS, marketingTemplateBoardIds);
};
