import { componentStyles } from "./automations-tab-component.scss";
import { automationsGridItems, TOP_VIDEO_PROPS } from "./automations-tab-consts.js";
import { withGenericConfig } from "/contexts/generic-config-context";
import LiveBoardTabComponent from "../live-board-tab-component";

const AutomationsTabContentComponent = (props) => {
  const { translate, onCardClick } = props;
  return (
    <div className="automations-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.automationsTab.description")}
        gridItems={automationsGridItems}
        hideCardParagraph={true}
        onCardClick={onCardClick}
        topAssetProps={TOP_VIDEO_PROPS}
        hideVideoControls={true}
        onAssetClick={onCardClick}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(AutomationsTabContentComponent);
