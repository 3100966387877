import { SLUG_DETAILS_COOKIE_NAME } from "services/monday-platform/existing-accounts-service";
import { COOKIES_DOMAIN } from "../constants/cookies";

export const cookieValueByNameInlineScript = `
		const cookieValueByName = cookieName => {
			if (!cookieName) return null;
			var b = document.cookie.match('(^|;)\\\\s*' + cookieName + '\\\\s*=\\\\s*([^;]+)');
			return b ? b.pop() : null;
		}
`;

export const setCookieInlineScript = `
		const setCookie = (name, value, exdays) => {
				const d = new Date();
				d.setTime(d.getTime() + (exdays*24*60*60*1000));
				var expires = "expires="+ d.toUTCString();
				document.cookie = name + "=" + value + ";" + expires + ";domain=${COOKIES_DOMAIN};path=/";
		}
`;

export const shouldRecordFromDrawInlineScript = `
		const shouldRecordFromDraw = (recordEveryNth) => {
		if (!recordEveryNth) return false;
			return Math.ceil(Math.random() * recordEveryNth) === recordEveryNth;
		}
`;

export const bigBrainTrackEventInlineScript = `
		const trackBigbrainEvent = (eventName, eventParams={}) => {
		if (window.BigBrain) {
			window.BigBrain("track", eventName, eventParams);
			}
		}
`;

export const getSlugDataInlineScript = `
		${cookieValueByNameInlineScript}
		const onlyNewFirstUserSlug = (date) => {
			try {
				let slugsFromCookie = cookieValueByName("${SLUG_DETAILS_COOKIE_NAME}");
				let slugs = [];
				if (slugsFromCookie) {
					slugsFromCookie = decodeURIComponent(slugsFromCookie);
					slugs = JSON.parse(slugsFromCookie);
				}
				const newSlugs = slugs.filter((slug) => slug.ac && new Date(slug.ac) >= date && slug.us === 1);
				return slugs.length > 0 && newSlugs.length === slugs.length;
			}
			catch {
				return false
			}
		}
`;
