import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
export const componentStyles = css`
  .two-link-buttons-button {
    display: flex;
    align-items: center;
    gap: 24px;
    &.swap-buttons-order {
      flex-direction: row-reverse;
    }
  }
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .two-link-buttons-button {
      flex-direction: column-reverse;
      gap: 24px;
      &.swap-buttons-order {
        flex-direction: column;
      }
    }
  }
`;
