import css from "styled-jsx/css";

export const componentStyles = css.global`
  .consent-checkbox {
    display: flex;
    flex-direction: row;
    text-align: left;

    .checkbox {
      margin-right: 8px;
    }
  }
`;
