import { PureComponent } from "react";
import colors from "styles/colors";
import classnames from "classnames";
import { encloseBoldTag } from "utils/strings";
import { componentStyles } from "./paragraph.scss";
import DefaultProps from "./default-props";

export default class Paragraph extends PureComponent {
  getStyle = () => {
    const { bodyColor, textWidth } = this.props;

    let customStyle = {
      maxWidth: textWidth
    };

    if (bodyColor && colors[bodyColor]) {
      customStyle = {
        ...customStyle,
        color: colors[bodyColor]
      };
    }

    return customStyle;
  };

  render() {
    const { body, textSize, isFirstSegment } = this.props;
    const CustomTag = isFirstSegment ? "h2" : "div";

    const style = this.getStyle();

    return (
      <>
        <CustomTag
          className={classnames("paragraph-body", textSize)}
          dangerouslySetInnerHTML={{ __html: encloseBoldTag(body) }}
          style={style}
        />
        <style jsx>{componentStyles}</style>
      </>
    );
  }
}

Paragraph.defaultProps = DefaultProps;
