import { PureComponent } from "react";
import { componentStyles } from "./button.scss";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import TwoLinkButtonsButton from "segments/desktop/core-components/button/two-link-buttons-button/two-link-buttons-button";
import SignupComboButtonsButton from "segments/desktop/core-components/signup-combo-buttons-button/signup-combo-buttons-button";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import SubscribeButton from "segments/desktop/core-components/button/subscribe-button/subscribe-button";
import YouTubePopupButton from "segments/desktop/core-components/button/youtube-popup-button/youtube-popup-button";
import SignupAndYoutubeComboButton from "segments/desktop/core-components/button/signup-and-youtube-combo-button/signup-and-youtube-combo-button";

import {
  REGULAR_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  SIGNUP_BUTTON_TYPE,
  TWO_LINK_BUTTONS_TYPE,
  SUBSCRIBE_BUTTON_TYPE,
  YOUTUBE_POPUP_LINK_TYPE,
  SIGNUP_COMBO_BUTTONS_TYPE,
  SIGNUP_AND_YOUTUBE_COMBO_BUTTONS_TYPE
} from "./button-consts";

import DefaultProps from "./default-props";

export default class Button extends PureComponent {
  getButtonComponent() {
    const { buttonType } = this.props;

    switch (buttonType) {
      case REGULAR_BUTTON_TYPE:
        return RegularButton;
      case SECONDARY_BUTTON_TYPE:
        return SecondaryButton;
      case SIGNUP_BUTTON_TYPE:
        return SignupButton;
      case TWO_LINK_BUTTONS_TYPE:
        return TwoLinkButtonsButton;
      case SIGNUP_COMBO_BUTTONS_TYPE:
        return SignupComboButtonsButton;
      case SUBSCRIBE_BUTTON_TYPE:
        return SubscribeButton;
      case YOUTUBE_POPUP_LINK_TYPE:
        return YouTubePopupButton;
      case SIGNUP_AND_YOUTUBE_COMBO_BUTTONS_TYPE:
        return SignupAndYoutubeComboButton;

      default:
        return null;
    }
  }

  render() {
    const ButtonComponent = this.getButtonComponent();
    if (!ButtonComponent) return null;

    const { buttonType, ...restButtonConfig } = this.props;

    return (
      <div className="button">
        <ButtonComponent {...restButtonConfig} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Button.defaultProps = DefaultProps;
