import css from "styled-jsx/css";
import Colors from "styles/colors";
import { getComponentShadow, getComponentShadowWithHoverEffect } from "segments/desktop/constants";

import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";

export const componentStyles = css`
  .card-grid-link {
    text-decoration: none;
    color: ${Colors[BRAND_BLACK_TEXT_COLOR]};
    height: 100%;
    width: 100%;
    display: flex;
    &.without-link {
      .grid-paragraph-with-image {
        cursor: initial;
      }
    }

    .grid-paragraph-with-image {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      cursor: pointer;
      border-radius: 18px;
      overflow: hidden;
      &.top {
        justify-content: flex-start;
      }
      &.center {
        justify-content: center;
      }
      &.bottom {
        justify-content: flex-end;
      }
      .tag {
        border-style: solid;
        border-width: 2px;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px 5px;
      }

      &:not(.without-shadow-hover):hover {
        ${getComponentShadow()}
      }

      &.shadow {
        ${getComponentShadow()}
        &:not(.without-shadow-hover):hover {
          ${getComponentShadowWithHoverEffect()}
        }
      }

      :global(.grid-image-wrapper) {
        :global(.grid-image) {
          :global(.picture-component) {
            display: flex;
            :global(img) {
              border-bottom-right-radius: 16px;
              border-bottom-left-radius: 16px;
            }
          }
        }
      }

      .grid-paragraph-wrapper {
        height: 100%;
        padding: 24px 24px 40px 24px;
      }
    }
  }
`;
