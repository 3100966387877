import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

export const componentStyles = css`
  .signup-and-youtube-combo-button {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    .secondary-button-wrapper{
      height: 50px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .signup-and-youtube-combo-button {
      flex-direction: column;
      align-items: center;
    }
  }
`;
