import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .embedded-board {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    @keyframes tooltip-toggle {
      0% {
        transform: translateY(0);
      }
      25% {
        transform: translateY(-5px);
      }
      50% {
        transform: translateY(-10px);
      }
      75% {
        transform: translateY(-5px);
      }
      100% {
        transform: translateY(0);
      }
    }

    .tooltip-wrapper {
      display: flex;
      justify-content: center;
      bottom: 60px;
      position: relative;
      animation-name: tooltip-toggle;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in;
      animation-duration: 1s;
      transition: bottom 3s linear;
    }

    .embedded-wrapper {
      border-radius: 8px;
      -moz-border-radius: 8px;
      overflow: hidden;

      border: ${`1px solid ${Colors["border-gray"]}`};
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;

      .embedded-board-top-frame {
        background-color: ${Colors["riverstone"]};
        height: 48px;
        display: flex;
        cursor: default;
        justify-content: flex-end;

        .powered-by-wrapper {
          margin-right: 16px;
          font-size: 0.8125rem;
          line-height: 22px;
          display: flex;
          align-items: center;

          .monday-logo-image {
            margin-left: 8px;
            padding-top: 6px;
            width: 100px;
          }
        }
      }

      .board-iframe-content-wrapper {
        height: 100%;
        width: 100%;

        .board-iframe {
          height: 100%;
          width: 100%;
          border-width: 0;
        }
      }
    }
  }
`;
