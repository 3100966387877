import css from "styled-jsx/css";
import Colors from "styles/colors";

export default css.global`
  .button-component {
    @mixin button-theme($color) {
      &:not(.outline) {
        background-color: $color;

        &:hover {
          background-color: darken($color, 5%);
        }
      }

      &.outline {
        color: $color;
        border: 1px solid $color;
        transition: background-color 200ms ease, color 200ms ease;

        &:hover {
          color: ${Colors["white"]} !important;
          background-color: $color;
          &.white {
            color: ${Colors["black"]} !important;
          }
        }
      }
    }

    display: inline-block;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 300;
    user-select: none;
    transition: background-color 200ms ease;

    &:hover {
      outline: 0;
    }

    &.disabled {
      background-color: ${Colors["dark-marble"]} !important;
      color: ${Colors["brand-gray"]} !important;
      pointer-events: none;
    }

    // default size: md
    font-size: 0.8125rem;
    padding: 12px 24px;
    &.with-arrow {
      padding-right: 16px;
    }

    &.sm {
      font-size: 0.6875rem;
      padding: 8px 16px;
      &.with-arrow {
        padding-right: 12px;
      }
    }

    &.md {
      font-size: 0.8125rem;
      padding: 12px 24px;
      &.with-arrow {
        padding-right: 16px;
      }
    }

    &.lg {
      font-size: 0.875rem;
      padding: 16px 32px;
      &.with-arrow {
        padding-left: 30px;
        padding-right: 20px;
      }
    }

    &.full {
      font-size: 0.875rem;
      padding: 16px 0;
      width: 100%;
      text-align: center;
    }

    &.blue {
      @include button-theme(#0085ff);
    }

    &.mint-green {
      @include button-theme(#25dbc5);
    }

    &.bold-blue {
      @include button-theme(#15bbe4);
    }

    &.purple {
      @include button-theme(#a358df);
    }
    &.iris {
      @include button-theme(#595ad4);
    }
    &.riverstone {
      @include button-theme(#f5f6f8);
    }
    &.royal {
      @include button-theme(#597bfc);
    }
    &.green {
      @include button-theme(#00ca72);
    }
    &.orange {
      @include button-theme(#fdab3d);
    }
    &.yellow {
      @include button-theme(#ffcb00);
    }
    &.pink {
      @include button-theme(#ff0476);
    }
    &.marketing-red-dark {
      @include button-theme(#ca0c6b);
    }
    &.dev-green-dark {
      @include button-theme(#037f4c);
    }
    &.projects-orange-dark {
      @include button-theme(#f86700);
    }
    &.crm-green-dark {
      @include button-theme(#00a0a0);
    }
    &.workos-iris {
      @include button-theme(#6c6cff);
    }
    &.brand-wm-primary-color {
      @include button-theme(#6161ff);
    }
    &.crm-dark-tint-01 {
      @include button-theme(#00889b);
    }
    &.brand-green-dark-tint-01 {
      @include button-theme(#00854d);
    }
    &.white {
      @include button-theme(#ffffff);
    }
  }
`;
