import { setCookie } from "/services/cookies-service";
import { trackEvent, setUtmLocaleId } from "/services/bigbrain-service";
import { SET_LOCALE_ID_EVENT_NAME } from "/constants/bigbrain-event-types";
import { LOCALE_COOKIE, COOKIE_EXPIRY_DAYS_FOR_LOCALIZATION } from "/constants/cookies.js";

const setLocaleIdAndTrackEventIfNeeded = (localeId, reason, options) => {
  setCookie(LOCALE_COOKIE, localeId, {
    expires: COOKIE_EXPIRY_DAYS_FOR_LOCALIZATION
  });
  setUtmLocaleId(localeId);

  if (options.sendBigBrainEvent) {
    trackEvent(SET_LOCALE_ID_EVENT_NAME, { kind: localeId, info1: reason, info2: window.location.href });
  }
};

export const setLocaleIdIfNeeded = (configLocaleId, options = {}) => {
  if (configLocaleId) {
    setLocaleIdAndTrackEventIfNeeded(configLocaleId, "set_locale_from_page_config", options);
  }
};
