import { PureComponent } from "react";
import Button from "segments/desktop/core-components/button/button";
import Bullets from "segments/desktop/core-components/bullets/bullets";
import classnames from "classnames";
import Colors from "styles/colors";
import { withSegmentsData } from "contexts/segment-context";
import Title from "segments/desktop/core-components/title/title";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import { withRouter } from "next/router";
import { withGenericConfig } from "/contexts/generic-config-context";
import {
  getDynamicSubTitleConfigForPath,
  getDynamicTitleConfigForPath
} from "client/services/cluster-config-service/cluster-config-service";
import Icons from "segments/desktop/core-components/icons/icons";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "constants/vp-sizes";
import DefaultProps from "./default-props";
import { componentStyles } from "./full-paragraph.scss";

export const PARAGRAPH_HORIZONTAL_PADDING = 32;
export const PARAGRAPH_HORIZONTAL_PADDING_STRING = `${PARAGRAPH_HORIZONTAL_PADDING}px`;

class FullParagraph extends PureComponent {
  getTextAlignBasedOnDeviceType = () => {
    const { isMobileBrowser, textAlign, mobileTextAlign } = this.props;
    if (!isMobileBrowser) return textAlign;

    return mobileTextAlign || textAlign;
  };

  getComponentInlineStyles() {
    const { textColor, paragraphVerticalColoredLineColor } = this.props;
    const verticalLeftLineColor = Colors[paragraphVerticalColoredLineColor];
    let inlineStyles = { textAlign: this.getTextAlignBasedOnDeviceType(), color: textColor };
    if (paragraphVerticalColoredLineColor) {
      inlineStyles = {
        ...inlineStyles,
        borderImage: `linear-gradient(to bottom, ${verticalLeftLineColor}, transparent) 0 0 0 1`
      };
    }
    return inlineStyles;
  }

  getTitleStyle = () => {
    const { titleMinWidth, titleWidth, topicText, spaceFromParagraph } = this.props;

    let style = {
      maxWidth: `min(${titleWidth}, ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH})`
    };

    if (!topicText) {
      style = { ...style, marginTop: 0 };
    }

    if (spaceFromParagraph) {
      style = { ...style, marginBottom: spaceFromParagraph };
    }

    if (this.getTextAlignBasedOnDeviceType() === "center") {
      const minWidth = `min(${titleMinWidth}, ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}, calc(100vw - ${PARAGRAPH_HORIZONTAL_PADDING_STRING}))`;
      style = {
        ...style,
        minWidth
      };
    }

    return style;
  };

  getButtonWrapperStyle = () => {
    const { buttonTopMargin } = this.props;

    if (!buttonTopMargin) return null;

    return {
      marginTop: buttonTopMargin
    };
  };

  renderTopic = () => {
    const { topic, topicBottomMargin } = this.props;
    const { text: topicText, color, image } = topic;

    if (image?.src || image?.href) {
      return (
        <div
          className={classnames("full-paragraph-topic-image-wrapper", this.getTextAlignBasedOnDeviceType())}
          style={{ marginBottom: topicBottomMargin }}
        >
          <PictureWithLinkComponent {...image} pictureComponentHeight={image?.height} />
        </div>
      );
    }

    if (topicText) {
      return (
        <div className="full-paragraph-topic" style={{ color: Colors[color], marginBottom: topicBottomMargin }}>
          <MondayMarkupLanguageComponent text={topicText} />
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      body,
      bodyColor,
      buttonConfig,
      bulletsConfig,
      textSize,
      titleSize,
      titleColor,
      isFirstSegment,
      withDynamicParagraphTitle,
      withDynamicParagraphSubtitle,
      title,
      titleClassName,
      clusterConfig,
      activePagePath,
      titleIcon,
      iconsConfig,
      paragraphVerticalColoredLineColor,
      textAlign,
      textWidth,
      removeBulletsMobileMarginTop
    } = this.props;

    const inlineComponentStyles = this.getComponentInlineStyles();

    const titleToUse =
      (withDynamicParagraphTitle && getDynamicTitleConfigForPath(clusterConfig, activePagePath)) || title;
    const subTitleToUse =
      (withDynamicParagraphSubtitle && getDynamicSubTitleConfigForPath(clusterConfig, activePagePath)) || body;
    const alignItemsClassName = `align-items-${textAlign}`;

    return (
      <div
        className={classnames("full-paragraph", {
          "with-left-line": paragraphVerticalColoredLineColor,
          [alignItemsClassName]: textAlign
        })}
        style={inlineComponentStyles}
      >
        {this.renderTopic()}
        {titleToUse && (
          <div className="title-wrapper" style={this.getTitleStyle()}>
            <Title
              title={titleToUse}
              titleSize={titleSize}
              titleColor={titleColor}
              titleIcon={titleIcon}
              isFirstSegment={isFirstSegment}
              titleClassName={titleClassName}
            />
          </div>
        )}

        {subTitleToUse && (
          <div className="paragraph-body-wrapper">
            <Paragraph
              body={subTitleToUse}
              bodyColor={bodyColor}
              textSize={textSize}
              isFirstSegment={isFirstSegment}
              textWidth={textWidth}
            />
          </div>
        )}

        {bulletsConfig && (
          <div
            className={classnames("bullets-wrapper", this.getTextAlignBasedOnDeviceType(), {
              "no-mobile-margin": removeBulletsMobileMarginTop
            })}
          >
            <Bullets {...bulletsConfig} />
          </div>
        )}

        {iconsConfig && (
          <div className={classnames("icons-wrapper", this.getTextAlignBasedOnDeviceType())}>
            <Icons {...iconsConfig} />
          </div>
        )}

        {buttonConfig && buttonConfig.buttonType && (
          <div
            className={classnames("button-wrapper", this.getTextAlignBasedOnDeviceType())}
            style={this.getButtonWrapperStyle()}
          >
            <Button {...buttonConfig} />
          </div>
        )}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

FullParagraph.defaultProps = DefaultProps;

export default withSegmentsData(withRouter(withGenericConfig(FullParagraph)));
