import css from "styled-jsx/css";
import { BASE_TEMPLATE_ROOT } from "constants/z-index";

export default css.global`
  .accessibility-menu-wrapper {
    position: relative;
    z-index: ${BASE_TEMPLATE_ROOT + 1};
  }

  .template-content-wrapper {
    position: relative;
    z-index: ${BASE_TEMPLATE_ROOT};

    &.with-background-color {
      background-color: white;
    }
  }
`;
