import { getQueryStringAsHash, getDecodedParamFromQuery } from "utils/url";
import { getCookie } from "/services/cookies-service";
import { CLUSTER_COOKIE } from "/constants/cookies";

const HUBSPOT_FORM_URL = "https://api.hsforms.com/submissions/v3/integration/submit";

export const submitHubspotForm = async (portalId, formId, emailValue) => {
  const url = `${HUBSPOT_FORM_URL}/${portalId}/${formId}`;
  const utmParams = getUtmParams();
  const data = { fields: [{ name: "email", value: emailValue }].concat(utmParams) };

  try {
    const serverResponse = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    if (serverResponse.status !== 200) {
      throw new Error(serverResponse.statusText);
    }
    await serverResponse.json();
    return true;
  } catch (err) {
    console.log("err", err);
    return false;
  }
};

const getUtmParams = () => {
  const url = window.location;
  const queryString = getQueryStringAsHash(url);
  const utmParams = [];

  const utm_source = queryString.marketing_source || queryString.utm_source;
  if (utm_source) utmParams.push({ name: "utm_source", value: utm_source });

  const utm_campaign = queryString.marketing_campaign || queryString.utm_campaign;
  if (utm_campaign) utmParams.push({ name: "utm_campaign", value: utm_campaign });

  const utm_banner = queryString.marketing_banner || queryString.utm_banner;
  if (utm_banner) utmParams.push({ name: "utm_banner", value: utm_banner });

  const utm_content = queryString.marketing_content || queryString.utm_content;
  if (utm_content) utmParams.push({ name: "utm_content", value: utm_content });

  const utm_cluster_id = getCookie(CLUSTER_COOKIE);
  if (utm_cluster_id) utmParams.push({ name: "utm_cluster_id", value: utm_cluster_id });

  return utmParams;
};

export const getSingleUrlParamToHubspotForm = (location, urlParamName, hubspotApiName) => {
  return { name: hubspotApiName, value: getDecodedParamFromQuery(location, urlParamName) };
};

export const sendHubspotForm = async (portalId, formId, fields) => {
  if (!portalId || !formId) return;

  const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const request = await fetch(HUBSPOT_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      fields
      // skipValidation: true
    }),
    headers: {
      "content-type": "application/json"
    }
  });

  const response = await request.json();
  return response;
};
