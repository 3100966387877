import { MODAL_BACKGROUND } from "constants/z-index";

export default {
  footerText: "",
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  style: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: `${MODAL_BACKGROUND}`
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "none",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "transparent",
      minHeight: "630px",
      minWidth: "960px",
      overflow: "hidden",
      borderRadius: "16px"
    }
  },
  isOpen: false
}