import { usePrefetchedSignupAssets } from "client/services/signup/prefetch-signup-service";
import * as Sentry from "@sentry/nextjs";
import { PREFETCH_ALL_ASSETS_COMPLETED, PREFETCH_ASSET_COMPLETED } from "constants/bigbrain-event-types";
import { trackEvent } from "services/bigbrain-service";
import { PREFETCH_TYPES } from "constants/prefetch-constants";

const SignupPrefetchedAssetsLinksComponent = () => {
  const allPrefetchedSignupAssets = usePrefetchedSignupAssets();
  let numberOfLinksSuccessfullyPrefetched = 0;

  const dnsPrefetchedDomains = allPrefetchedSignupAssets.filter((asset) => asset.type === PREFETCH_TYPES.DNS_PREFETCH);
  const preConnectedDomains = allPrefetchedSignupAssets.filter((asset) => asset.type === PREFETCH_TYPES.PRECONNECT);
  const prefetchedSignupAssets = allPrefetchedSignupAssets.filter((asset) => asset.type === PREFETCH_TYPES.PREFETCH);

  const totalNumberOfAssetsToBePrefetched = prefetchedSignupAssets?.length;
  const PREFETCHED_LINK_ID = (index) => `prefetched_link_${index}`;

  const onLinkRendered = ({ href, index }) => {
    try {
      // setTimeout needed to ensure it's not run synchronously, and link elements were already rendered in the DOM
      setTimeout(() => {
        const linkElement = document.querySelector(`link[id=${PREFETCHED_LINK_ID(index)}]`);
        if (linkElement) {
          linkElement.onload = function () {
            trackEvent(PREFETCH_ASSET_COMPLETED, {
              kind: href,
              info1: index,
              info2: numberOfLinksSuccessfullyPrefetched++,
              info3: totalNumberOfAssetsToBePrefetched
            });
            if (numberOfLinksSuccessfullyPrefetched === totalNumberOfAssetsToBePrefetched) {
              trackEvent(PREFETCH_ALL_ASSETS_COMPLETED, { info3: totalNumberOfAssetsToBePrefetched });
            }
          };

          linkElement.onerror = function () {
            Sentry.captureMessage(`Prefetch of the following asset failed: ${href}`);
          };
        } else {
          Sentry.captureMessage(`Couldn't query link with id: ${PREFETCHED_LINK_ID(index)} and href: ${href}`);
        }
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return [
    dnsPrefetchedDomains?.map((prefetchedDomain) => <link rel={prefetchedDomain.type} href={prefetchedDomain.href} />),
    preConnectedDomains?.map((preConnectedDomain) => (
      <link rel={preConnectedDomain.type} href={preConnectedDomain.href} />
    )),
    prefetchedSignupAssets?.map((prefetchedAsset, index) => (
      <link
        id={PREFETCHED_LINK_ID(index)}
        rel={prefetchedAsset.type}
        href={prefetchedAsset.href}
        onLoad={onLinkRendered({ ...prefetchedAsset, index })}
      />
    ))
  ];
};

export default SignupPrefetchedAssetsLinksComponent;
