import css from "styled-jsx/css";

export const componentStyles = css.global`
  .secondary-button-wrapper {
    &:hover {
      outline: 0;
    }
  }
  .play-wrapper {
    padding-right: 16px;
    svg {
      display: block;
      circle {
        stroke: currentColor;
      }
      path {
        fill: currentColor;
      }
    }
  }
`;
