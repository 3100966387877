import css from "styled-jsx/css";
import Colors from "styles/colors";
import {
  COMPONENTS_SHADOW_PROPS,
  COMPONENTS_SHADOW_FILTER,
  MOBILE_COMPONENTS_SHADOW_FILTER
} from "segments/desktop/constants";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .window-mask {
    display: flex;
    flex-direction: column;
    padding: ${`${COMPONENTS_SHADOW_PROPS.blurRadius + COMPONENTS_SHADOW_PROPS.offsetY}px
         ${COMPONENTS_SHADOW_PROPS.blurRadius + COMPONENTS_SHADOW_PROPS.offsetX}px`};
    ${COMPONENTS_SHADOW_FILTER}
    .window-header {
      display: flex;
      height: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: rgba(255, 255, 255, 0.4);

      .circle-wrapper {
        display: flex;
        align-items: center;
        margin-left: 8px;

        .circle {
          background: ${Colors.white};
          height: 5px;
          width: 5px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
    }

    .content-wrapper {
      display: flex;
      flex-grow: 1;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .window-mask {
      padding: 28px 8px;
      ${MOBILE_COMPONENTS_SHADOW_FILTER}
      .window-header {
        height: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .circle-wrapper {
          margin-left: 4px;

          .circle {
            width: 2.5px;
            height: 2.5px;
            margin-right: 3px;
          }
        }
      }
      :global(img),
      :global(video) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;
