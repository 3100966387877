import { PureComponent, Fragment } from "react";
import { componentStyles } from "./textarea-input.scss";
import { commonInputStyles } from "../common-inputs.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";

class TextareaInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { inputData, errorState, onInputChange, onInputBlur, translate, invalid, errorLabelId, id } = this.props;
    const { inputName, isRequired, placeholder } = inputData;
    const { isValid } = errorState;

    const showRequiredError = !isValid && isRequired;
    const requiredClass = {
      "input-required": showRequiredError
    };

    return (
      <Fragment>
        <textarea
          id={id}
          onChange={onInputChange}
          onBlur={onInputBlur}
          className={classnames("hp-input hp-textarea-input", requiredClass)}
          name={inputName}
          placeholder={translate(placeholder)}
          aria-required={isRequired}
          aria-invalid={invalid}
          aria-describedby={errorLabelId}
        ></textarea>
        <style jsx>{commonInputStyles}</style>
        <style jsx>{componentStyles}</style>
      </Fragment>
    );
  }
}

TextareaInput.defaultProps = {
  invalid: false,
  errorLabelId: null
};

export default withGenericConfig(TextareaInput);
