import { usePrefetchedTypeformAssets } from "client/services/typeform/prefetch-typeform-service";
import { PREFETCH_TYPES } from "constants/prefetch-constants";

const TypeformPrefetchedAssetsLinksComponent = () => {
  const allPrefetchedAssets = usePrefetchedTypeformAssets();

  const dnsPrefetchedDomains =
    allPrefetchedAssets && allPrefetchedAssets.filter((asset) => asset.type === PREFETCH_TYPES.DNS_PREFETCH);
  const preConnectedDomains =
    allPrefetchedAssets && allPrefetchedAssets?.filter((asset) => asset.type === PREFETCH_TYPES.PRECONNECT);
  const prefetchedAssets =
    allPrefetchedAssets && allPrefetchedAssets?.filter((asset) => asset.type === PREFETCH_TYPES.PREFETCH);

  const PREFETCHED_LINK_ID = (index) => `prefetched_link_${index}`;

  return [
    dnsPrefetchedDomains?.map((prefetchedDomain) => <link rel={prefetchedDomain.type} href={prefetchedDomain.href} />),
    preConnectedDomains?.map((preConnectedDomain) => (
      <link rel={preConnectedDomain.type} href={preConnectedDomain.href} />
    )),
    prefetchedAssets?.map((prefetchedAsset, index) => (
      <link id={PREFETCHED_LINK_ID(index)} rel={prefetchedAsset.type} href={prefetchedAsset.href} />
    ))
  ];
};

export default TypeformPrefetchedAssetsLinksComponent;
