const getCapitilizedString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * returns the given string, surrounded by quotes
 * @param {*} str
 */
const addQuotesIfMissing = (str) => {
  if (!str) {
    return str;
  }
  if (!str.startsWith('"')) {
    str = '"' + str;
  }
  if (!str.endsWith('"')) {
    str += '"';
  }
  return str;
};

const toCamelCase = (str) => {
  if (!str) {
    return "";
  }
  const result = str
    // Lower cases the string
    .toLowerCase()
    // Replaces any - or _ characters with a space
    .replace(/[-_]+/g, " ")
    // Removes any non alphanumeric characters
    .replace(/[^\w\s]/g, "")
    // Uppercases the first character in each group immediately following a space
    // (delimited by spaces)
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    });
  // upper case first letter
  return result.substring(0, 1).toUpperCase() + result.substring(1);
};

const toKebabCase = (str) =>
  str
    ?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    ?.join("-");

// Returns the words count in the given text
const getWordsCount = (text) => {
  if (!text || !text.match) {
    return 0;
  }
  return text.match(/\S+/g).length;
};

// THIS IS TEMPORARY FIX FOR TEMPLATES GENERATOR
// Because historically a lot of pages contain bold tags
// With no closing tag in the end, causing the page to break
const encloseBoldTag = (str) => {
  if (!str || !str.match) {
    return str;
  }

  const openningBoldsCount = (str.replace(/ /g,'').match(/<b>/g) || []).length;
  const closingBoldsCount = (str.replace(/ /g,'').match(/<\/b>/g) || []).length;

  return openningBoldsCount > closingBoldsCount ? `${str}</b>` : str;
}

module.exports = {
  getCapitilizedString,
  addQuotesIfMissing,
  toCamelCase,
  toKebabCase,
  getWordsCount,
  encloseBoldTag
};
