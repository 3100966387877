import defer from "lodash/defer";
import { getRandomInt } from "/services/utils-service";
import {
  PAGE_PERFORMANCE_TRACKING_NOT_SUPPORTED,
  PAGE_PERFORMANCE_TRACKING_GENERIC_ERROR
} from "constants/bigbrain-event-types";
import ResourcesLoadTracking from "./performance/resources-load-tracking";
import PageLoadTracking from "./performance/page-load-tracking";

class PagePerformanceEventsTracker {
  constructor() {
    this.pageLoadUniqueId = getRandomInt(100000000);
    this.pageLoadTracking = new PageLoadTracking(this.pageLoadUniqueId);
    this.resourcesLoadTracking = new ResourcesLoadTracking(this.pageLoadUniqueId);
  }

  track(template) {
    try {
      if (!window.performance) {
        BigBrain("track", PAGE_PERFORMANCE_TRACKING_NOT_SUPPORTED);
        return;
      }

      this.pageLoadTracking.track(template);
      this.resourcesLoadTracking.track();
    } catch (error) {
      BigBrain("track", PAGE_PERFORMANCE_TRACKING_GENERIC_ERROR, {
        data: { error: error.message },
        placement: this.pageLoadUniqueId
      });
    }
  }
}

export const trackPagePerformance = ({ template }) => {
  const pagePerformanceEventsTracker = new PagePerformanceEventsTracker();
  defer(() => pagePerformanceEventsTracker.track(template));
};
