import { PureComponent, Fragment } from "react";
import classnames from "classnames";
import colors from "styles/colors";
import ColorfulTitleComponent from "/components/core/colorful-title/colorful-title-component";
import { componentStyles, bodyStyles, bottomLinkStyles } from "./paragraph-component.scss";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import PictureWithLinkComponent from "/components/core/picture-with-link/picture-with-link-component";
import { withSegmentsData } from "contexts/segment-context";
import { withGenericConfig } from "/contexts/generic-config-context";
import replace from "lodash/replace";

class ParagraphComponent extends PureComponent {
  renderInnerBody = () => {
    const { body } = this.props;
    return (
      <Fragment>
        {body.map((paragraph, index) => (
          <span className="inner-body" key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
        <style jsx>{bodyStyles}</style>
      </Fragment>
    );
  };

  renderBody = () => {
    return <h3 className="body"> {this.renderInnerBody()} </h3>;
  };

  renderBottomLink() {
    const { text, href } = this.props.bottomLink;
    const { textColor } = this.props;

    return (
      <div className="bottom-link-wrapper">
        <a href={href} style={{ color: colors[textColor] }}>
          {text}
        </a>
        <style jsx>{bottomLinkStyles}</style>
      </div>
    );
  }

  renderSignup() {
    const { signupForm, isFirstSegment } = this.props;

    return (
      <div className="signup-form-with-decorations-wrapper">
        <SignupFormComponent emailInputOpened={!!isFirstSegment} {...signupForm} />
      </div>
    );
  }

  getTitle() {
    const { title, hasFreeTier } = this.props;
    return hasFreeTier ? replace(title, "Start your 14-day free trial", "") : title;
  }

  render() {
    const {
      image,
      topic,
      title,
      body,
      textDirection,
      bottomLink,
      titleFontSize,
      showSignup,
      isFirstSegment
    } = this.props;

    return (
      <div
        className={classnames("paragraph-component", {
          [textDirection]: textDirection
        })}
      >
        {topic.text && (
          <div className="topic" style={{ color: colors[topic.color] }}>
            {topic.text}
          </div>
        )}
        {title && (
          <div className="title">
            {image && image.src && <PictureWithLinkComponent className="title-image" {...image} />}
            <ColorfulTitleComponent text={this.getTitle()} fontSize={titleFontSize} isH1Heading={isFirstSegment} />
          </div>
        )}
        {body.length > 0 && this.renderBody()}
        {bottomLink.href && this.renderBottomLink()}
        {showSignup && this.renderSignup()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withSegmentsData(withGenericConfig(ParagraphComponent));

ParagraphComponent.defaultProps = {
  topic: {},
  title: "",
  body: [],
  bottomLink: {},
  showSignup: false
};
