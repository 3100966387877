import { PureComponent } from "react";
import colors from "/styles/colors";
import classnames from "classnames";

const COLOR_END_TAG = "</end>";

// This component gets a single text in the form of
// "This is an <brand-blue>Example</end> for a <brand-pink>colorful title</end>."
export default class ColorfulTitleComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  convertToHtml(text, fontSize) {
    text = text || "";
    // converting to spans with colors
    let html = "";
    // searching for first '<'
    const index = text.indexOf("<brand");
    if (index === -1) {
      return text;
    }
    // regular text
    html = text.substring(0, index);
    // getting color
    const colorEndIndex = text.indexOf(">", index);
    const color = text.substring(index + 1, colorEndIndex);
    let endOfColoredText = text.indexOf(COLOR_END_TAG);
    if (endOfColoredText === -1) {
      endOfColoredText = text.length;
    }
    const colorText = text.substring(colorEndIndex + 1, endOfColoredText);
    html += `<span class="colored" style="color: ${colors[color]}; font-size: ${fontSize}">${colorText}</span>`;
    const restOfText = text.substring(endOfColoredText + COLOR_END_TAG.length);
    return html + this.convertToHtml(restOfText);
  }

  renderTitle = () => {
    const { text, fontSize, isH1Heading } = this.props;
    const title = this.convertToHtml(text, fontSize);
    const className = "colorful-title";
    const style = { fontSize };

    if (isH1Heading) {
      return <h1 className={className} dangerouslySetInnerHTML={{ __html: title }} style={style} />;
    } else {
      return <h2 className={className} dangerouslySetInnerHTML={{ __html: title }} style={style} />;
    }
  };

  render() {
    const { className } = this.props;

    return <div className={classnames("title-container", className)}>{this.renderTitle()}</div>;
  }
}

ColorfulTitleComponent.defaultProps = {
  isH1Heading: false
};
