import { PureComponent } from "react";
import { componentStyles } from "./grid-image-with-paragraph.scss";
import DefaultProps from "./default-props";
import { GRID_CARD_CLICKED, GRID_CARD_HOVERED } from "/constants/bigbrain-event-types";
import GridImage from "segments/desktop/core-components/grid-image/grid-image";
import Title from "segments/desktop/core-components/title/title";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import classnames from "classnames";

export default class GridImageWithParagraph extends PureComponent {
  handleClick = () => {
    const { fullParagraph } = this.props;
    const { buttonConfig, title } = fullParagraph;
    const { url, buttonText } = buttonConfig || {};

    BigBrain("track", GRID_CARD_CLICKED, { kind: buttonText, info1: title, info2: url });
  };

  handleHover = () => {
    const { fullParagraph } = this.props;
    const { buttonConfig, title } = fullParagraph;
    const { url, buttonText } = buttonConfig || {};

    BigBrain("track", GRID_CARD_HOVERED, { kind: buttonText, info1: title, info2: url });
  };

  renderParagraph = () => {
    const { fullParagraph, titleSize, textSize, width, hideButton } = this.props;
    const { title, body, buttonConfig } = fullParagraph;

    return (
      <div className="grid-paragraph-wrapper" style={{ width }}>
        <div className="title-wrapper">
          <Title title={title} titleSize={titleSize} />
        </div>
        <div className="paragraph-body-wrapper">
          <Paragraph body={body} textSize={textSize} />
        </div>
        {!hideButton && (
          <div className="button-wrapper">
            <SecondaryButton {...buttonConfig} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  renderComponent() {
    const { fullParagraph, titleSize, textSize, ...restImageProps } = this.props;
    return (
      <div className="grid-image-with-paragraph">
        {restImageProps?.src && (
          <div className="grid-image-wrapper">
            <GridImage {...restImageProps} />
          </div>
        )}
        {this.renderParagraph()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }

  render() {
    const { fullParagraph } = this.props;
    const { buttonConfig } = fullParagraph;
    const { url, isOpenLinkInNewTab } = buttonConfig || {};

    return (
      <a
        className={classnames("card-grid-link", { "without-link": !url })}
        href={url}
        onClick={this.handleClick}
        onMouseEnter={this.handleHover}
        target={isOpenLinkInNewTab ? "_blank" : "_self"}
      >
        {this.renderComponent()}
        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

GridImageWithParagraph.defaultProps = DefaultProps;
