import { cookieValueByNameInlineScript, bigBrainTrackEventInlineScript } from "/utils/inline-scripts";
import { BIG_BRAIN_VISITOR_ID, CLUSTER_COOKIE, SUB_CLUSTER_COOKIE } from "/constants/cookies";

import {
  DRIFT_CHAT_LOADED,
  DRIFT_COVERSATION_STARTED,
  DRIFT_MEETING_BOOKED,
  DRIFT_AGENT_REPLIED,
  DRIFT_MESSAGE_RECEIVED
} from "constants/bigbrain-event-types";

export const loadDriftChatbot = () => {
  window?.drift?.load("sc4535n3muv9");
};

const DriftScript = (props) => {
  const { shouldShowOnLoad, Cluster, subClusterId, driftDelayedLoadMilliseconds, driftOpenChatDialogOnLaunch } = props;

  const driftTimeoutScript = `setTimeout(function(){drift.load('sc4535n3muv9')},${driftDelayedLoadMilliseconds});`;

  const renderDriftScript = () => {
    return `
      !function() {
        var t = window.driftt = window.drift = window.driftt || [];
        if (!t.init) {
          if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
          t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
          t.factory = function(e) {
            return function() {
              var n = Array.prototype.slice.call(arguments);
          return n.unshift(e), t.push(n), t;
        };
          }, t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          }), t.load = function(t) {
            var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
          o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
      }();
      ${cookieValueByNameInlineScript}
      ${bigBrainTrackEventInlineScript}
      visitorId = cookieValueByName('${BIG_BRAIN_VISITOR_ID}');
      cluster = cookieValueByName('${CLUSTER_COOKIE}') || '${Cluster}';
      subCluster = cookieValueByName('${SUB_CLUSTER_COOKIE}') || '${subClusterId}';
      drift.SNIPPET_VERSION = '0.3.1';
      ${shouldShowOnLoad ? "drift.load('sc4535n3muv9');" : ""} 
      ${driftDelayedLoadMilliseconds ? driftTimeoutScript : ""}
      drift.on('ready',function(api, payload) {
        if (!visitorId) {
          visitorId = cookieValueByName('${BIG_BRAIN_VISITOR_ID}');
        }
        ${driftOpenChatDialogOnLaunch ? "api.openChat();" : ""} 
        api.setUserAttributes({visitor_id: visitorId, utm_cluter_id: cluster, sub_cluster: subCluster});
        trackBigbrainEvent('${DRIFT_CHAT_LOADED}', { info1: cluster, info2: subCluster });
       }); 
      drift.on("message", function(data) {
        if (!visitorId) {
          visitorId = cookieValueByName('${BIG_BRAIN_VISITOR_ID}');
        }
        drift.api.setUserAttributes({received_message: true, visitor_id: visitorId});
        trackBigbrainEvent('${DRIFT_MESSAGE_RECEIVED}');
        if (data?.teamMember?.name && data?.teamMember?.name !== 'monday.com' ) {
          drift.api.setUserAttributes({agent_replied: true});
          trackBigbrainEvent('${DRIFT_AGENT_REPLIED}');
        }
      });
      drift.on("scheduling:meetingBooked", function(data) {
        drift.api.setUserAttributes({book_meeting_event: true});
        trackBigbrainEvent('${DRIFT_MEETING_BOOKED}');
      });
      drift.on("startConversation", function(data) {
        drift.api.setUserAttributes({conversation_started: true});
        trackBigbrainEvent('${DRIFT_COVERSATION_STARTED}');
      });
    `;
  };

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: renderDriftScript()
      }}
    />
  );
};

export default DriftScript;
