import { PureComponent } from "react";
import Colors from "styles/colors";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { TAB_CLICKED } from "/constants/bigbrain-event-types";
import classnames from "classnames";
import Title from "segments/desktop/core-components/title/title";
import { XS_L } from "constants/sizes";
import { componentStyles } from "./tab-component.scss";

export default class TabComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onTabClicked = this.onTabClicked.bind(this);
    this.onKeyDown = generateOnKeyDownHandler(this.tabClickedAction);
  }

  onTabClicked(e) {
    this.blurTab(e);
    this.tabClickedAction();
  }

  blurTab = (e) => {
    e?.target?.closest(".tab-component")?.blur();
  };

  tabClickedAction = () => {
    const { onClick, index, label, labelTranslationKey, extraData } = this.props;
    BigBrain("track", TAB_CLICKED, {
      kind: label,
      info1: index,
      info2: labelTranslationKey,
      data: extraData
    });
    onClick(index);
  };

  render() {
    const { label, hideLabel, color, isActive, useSmallLabels, tabIcon, useXSMLabels, tabsWithIcons, tabClassName } =
      this.props;
    const tabColor = Colors[color];
    const tabLabel = hideLabel ? "" : label;

    return (
      <div
        className={classnames("tab-component", {
          active: isActive,
          "small-labels": useSmallLabels,
          "half-screen-tabs": useXSMLabels,
          [tabClassName]: tabClassName
        })}
        style={{ "--tab-color": tabColor }}
        onClick={this.onTabClicked}
        role="tab"
        aria-selected={isActive}
        tabIndex={0}
        onKeyDown={this.onKeyDown}
      >
        {!tabsWithIcons && (
          <span className="tab-label" style={{ color: isActive && tabColor }}>
            {tabLabel}
          </span>
        )}
        {tabsWithIcons && (
          <div className="title-wrapper">
            <Title title={tabLabel} titleIcon={tabIcon?.src} titleSize={XS_L} />
          </div>
        )}

        <div className="tab-underline-placeholder">
          <div className="tab-underline" style={{ background: tabColor }} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TabComponent.defaultProps = {
  useSmallLabels: false
};
