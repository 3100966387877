import css from "styled-jsx/css";

export const componentStyles = css.global`
  .paragraph-body {
    font-size: 1.125rem;
    line-height: 32px;
    margin: 0; //overide h3 global definition
    word-break: break-word;

    &.xs {
      font-size: 0.8125rem;
      line-height: 20px;
    }

    &.sm {
      font-size: 0.875rem;
      line-height: 22px;
    }

    &.md {
      font-size: 1.125rem;
      line-height: 32px;
    }

    &.lg {
      font-size: 1.375rem;
      line-height: 40px;
    }
  }
`;
