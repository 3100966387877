import { createContext, useContext, useEffect, useState } from "react";
import { getPlatformLinkByEnvironment } from "constants/platform-links";
import { MONDAY_AUTH_DOMAIN } from "constants/links";
import { PREFETCH_TYPES } from "constants/prefetch-constants";

const SIGNUP_CLOUDINARY_ASSETS_DIRECTORY = "https://dapulse-res.cloudinary.com/image/upload/monday_platform/signup";

const SIGNUP_BACKGROUND_IMAGE_SRC = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/signup-background.png`;
const SIGNUP_LOADER_IMAGE_SRC = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/loader_transparent_new.gif`;
const SET_UP_YOUR_ACCOUNT_RIGHT_SIDE_ASSET = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/signup-right-side-assets/set-up-your-account-right-side-asset.png`;
const COMPANY_AND_TEAM_SIZE_RIGHT_SIDE_ASSET = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/signup-right-side-assets/company-and-team-size-right-side-asset.png`;
const HOW_WOULD_YOU_LIKE_TO_USE_MONDAY_RIGHT_SIDE_ASSET = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/signup-right-side-assets/how-would-you-like-to-use-monday-right-side-asset.png`;
const WHAT_BRINGS_YOU_HERE_RIGHT_SIDE_ASSET = `${SIGNUP_CLOUDINARY_ASSETS_DIRECTORY}/signup-right-side-assets/what-brings-you-here-right-side-asset.png`;

const STATIC_ASSETS_HREFS = [
  SIGNUP_BACKGROUND_IMAGE_SRC,
  SIGNUP_LOADER_IMAGE_SRC,
  SET_UP_YOUR_ACCOUNT_RIGHT_SIDE_ASSET,
  COMPANY_AND_TEAM_SIZE_RIGHT_SIDE_ASSET,
  HOW_WOULD_YOU_LIKE_TO_USE_MONDAY_RIGHT_SIDE_ASSET,
  WHAT_BRINGS_YOU_HERE_RIGHT_SIDE_ASSET
];

const PREFETCHED_DOMAINS = [getPlatformLinkByEnvironment(MONDAY_AUTH_DOMAIN)];
const PRECONNECT_DOMAINS = PREFETCHED_DOMAINS;

const PrefetchedSignupAssetsContext = createContext("PrefetchedSignupAssets");


export const PrefetchSignupAssetsProvider = (props) => {
  const { children, signupDynamicAssetsLinks } = props;
  const [prefetchedSignupAssetsLinks, setPrefetchedSignupAssetsLinks] = useState([]);

  useEffect(() => {
    const setData = async () => {
      const signupAssetsLinks = [];
      // dns prefetches
      PREFETCHED_DOMAINS.forEach((href) => {
        signupAssetsLinks.push({ type: PREFETCH_TYPES.DNS_PREFETCH, href });
      });

      // pre-connects
      PRECONNECT_DOMAINS.forEach((href) => {
        signupAssetsLinks.push({ type: PREFETCH_TYPES.PRECONNECT, href });
      });

      // static assets
      STATIC_ASSETS_HREFS.forEach((href) => {
        signupAssetsLinks.push({ type: PREFETCH_TYPES.PREFETCH, href });
      });

      // dynamic assets
      signupAssetsLinks.push(...signupDynamicAssetsLinks);
      setPrefetchedSignupAssetsLinks(signupAssetsLinks);
    };

    setData();
  }, []);

  return (
    <PrefetchedSignupAssetsContext.Provider value={prefetchedSignupAssetsLinks}>
      {children}
    </PrefetchedSignupAssetsContext.Provider>
  );
};

export const usePrefetchedSignupAssets = () => {
  const prefetchedSignupAssetsLinks = useContext(PrefetchedSignupAssetsContext) || [];

  return prefetchedSignupAssetsLinks;
};
