import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MIN_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
import colors from "styles/colors";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";

const bannerHeight = "72px";

export const getComponentStyles = (buttonTextColor) => {
  return css.global`
    .banner {
      height: ${bannerHeight};
      display: flex;
      justify-content: center;
      position: relative;
      &.top-margin {
        margin-top: ${`${Constants.SECONDARY_HEADER_HEIGHT}px`};
      }

      .banner-content {
        display: flex;
        /* justify-content: space-between; */
        width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};

        .image-wrapper {
          display: flex;
          flex-grow: 0;
          .banner-image {
            height: ${bannerHeight};
          }
        }

        .banner-text-and-cta {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 1;

          .banner-text-and-link {
            display: flex;
            align-items: center;
            .banner-text {
              display: inline;
              font-size: 1rem;
              line-height: 24px;
              text-align: center;
            }
          }

          .banner-cta-wrapper {
            display: inline-block;
            margin-left: 24px;
            min-width: 190px;
            .ladda-button {
              padding: 12px 20px;
              background: ${colors["white"]};
              color: ${colors[buttonTextColor]};
            }
          }
        }
      }
      .close-icon-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: ${colors["white"]};
          }
        }
      }

      &.hide-banner {
        display: none;
      }
    }

    @media (max-width: ${`${MIN_HEADER_DESKTOP_MENU}px`}) {
      .with-background-banner-component {
        .banner {
          height: unset;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .banner {
        &.top-margin {
          margin-top: 0px;
        }
        .banner-content {
          width: initial;

          .image-wrapper {
            .banner-picture {
              display: flex;
              align-items: center;

              .banner-image {
                height: 50px;
              }
            }
          }
          .banner-text-and-cta {
            margin-left: 20px;
            margin-right: 35px;
            justify-content: center;
            flex-grow: 0;
            flex-direction: column;

            .banner-cta-wrapper {
              margin-left: 0;
              min-width: unset;
              align-self: start;

              :global(.secondary-button) {
                font-size: 0.8125rem;
              }
            }

            .banner-text-and-link {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 10px;

              .banner-text {
                font-size: 1rem;
                text-align: left;
                font-weight: 400;
              }

              .banner-link {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      .banner {
        .banner-content {
          .banner-text-and-cta {
            .banner-text-and-link {
              display: inline-block;
              text-align: center;
              max-width: 274px;
              .banner-text {
                font-size: 0.8125rem;
              }
              .banner-link {
                font-size: 0.8125rem;
              }
              .banner-cta-wrapper {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  `;
};
