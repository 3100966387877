import { PureComponent } from "react";
import { componentStyles } from "./images-gallery-row-component.scss";
import ImageGalleryComponent from "./image-gallery/image-gallery-component";

export default class ImagesGalleryRowComponent extends PureComponent {
  render() {
    const { assets, shadow, screenRatios, grayscale, shouldTrackMouseEvents } = this.props;

    return (
      <div className="images-gallery-row">
        {assets.map((asset) => (
          <ImageGalleryComponent
            key={`${asset.url}_${asset.name}`}
            {...asset}
            alt={asset.name}
            shadow={shadow}
            grayscale={grayscale}
            screenRatios={screenRatios}
            shouldTrackMouseEvents={shouldTrackMouseEvents}
          />
        ))}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
