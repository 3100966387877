import componentStyles from "./youtube-video-component.scss";
import classnames from "classnames";
import { PureComponent } from "react";
import Youtube from "react-youtube";
import { withGenericConfig } from "contexts/generic-config-context";
import { trackEvent } from "/services/bigbrain-service";
import { isInClientAndCookieCategoryNotApproved } from "services/cookies-service";
import { COOKIE_CATEGORIES } from "constants/cookies-categories";
import { YOUTUBE_VIDEO_STARTED, YOUTUBE_VIDEO_ENDED } from "/constants/bigbrain-event-types";

const DEFAULT_PLAYER_VARS = { modestbranding: 1, controls: 1, rel: 0, frameBorder: 0, showinfo: 1, playsinline: 0 };

const DEFAULT_OPTS = { playerVars: DEFAULT_PLAYER_VARS };

export const YOUTUBE_EMBEDED_HOST = "https://www.youtube.com";
export const YOUTUBE_EMBEDED_NOCOOKIE_HOST = "https://www.youtube-nocookie.com";

class YouTubeVideoComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.onReady = this.onReady.bind(this);
    this._player = null;
  }

  onReady(event) {
    const { onReady, playing } = this.props;
    this._player = event.target;
    this.toggleMute();
    this.setVolume();

    if (playing) {
      // We want do to it only if playing = true so we don't pause the video.
      this.togglePlay();
    }

    onReady(event);
  }

  onPlay = (event) => {
    const { onPlay, videoId } = this.props;

    trackEvent(YOUTUBE_VIDEO_STARTED, { kind: videoId });
    if (onPlay) onPlay(event);
  };

  onEnd = (event) => {
    const { onEnd, videoId } = this.props;
    trackEvent(YOUTUBE_VIDEO_ENDED, { kind: videoId });
    if (onEnd) onEnd(event);
  };

  componentDidUpdate(prevProps) {
    if (this.props.muted !== prevProps.muted) {
      this.toggleMute();
    }

    if (this.props.playing !== prevProps.playing) {
      this.togglePlay();
    }

    if (this.props.volume !== prevProps.volume) {
      this.setVolume();
    }
  }

  toggleMute() {
    const { muted } = this.props;
    if (!this._player) {
      return;
    }

    if (muted) {
      this._player.mute();
    } else {
      this._player.unMute();
    }
  }

  togglePlay() {
    const { playing } = this.props;
    if (!this._player) {
      return;
    }

    if (playing) {
      this._player.playVideo();
    } else {
      this._player.pauseVideo();
    }
  }

  setVolume() {
    const { volume } = this.props;
    if (!this._player || volume === null) {
      return;
    }

    this._player.setVolume(volume);
  }

  getYoutubeHost() {
    return isInClientAndCookieCategoryNotApproved(COOKIE_CATEGORIES.MARKETING) //GDPR relevant - in other countries Marketing category is always approved
      ? YOUTUBE_EMBEDED_NOCOOKIE_HOST
      : YOUTUBE_EMBEDED_HOST;
  }

  generateOpts() {
    const { opts } = this.props;
    return { ...opts, host: this.getYoutubeHost() };
  }

  render() {
    const { videoId, background, showShadow } = this.props;
    return (
      <div className="youtube-video-component">
        <div className={classnames(`video-container`, { [background]: background })}>
          <div className={classnames("content", { "with-shadow": showShadow })}>
            <Youtube
              {...this.props}
              videoId={videoId}
              onReady={this.onReady}
              opts={this.generateOpts()}
              onEnd={this.onEnd}
              onPlay={this.onPlay}
            />
          </div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

YouTubeVideoComponent.defaultProps = {
  playing: false,
  volume: null,
  muted: false,
  onReady: () => {},
  onEnd: () => {},
  onPlay: () => {},
  opts: DEFAULT_OPTS
};

export default withGenericConfig(YouTubeVideoComponent);
