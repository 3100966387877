import Head from "/components/core/head";
import { PureComponent } from "react";
import HeaderScriptsComponent from "/components/core/head/header-scripts-component";
import BodyScriptsComponent from "/components/core/body-scripts/body-scripts-component";
import { GenericConfigContext } from "/contexts/generic-config-context";
import { AbTestsContext } from "/contexts/ab-tests-context";
import throttle from "lodash.throttle";
import { trackEvent } from "/services/bigbrain-service";
import { saveCloudfrontViewerCountryCookie } from "services/cookies/cloudfront-viewer-country-cookies-service";
import { getExactEventNameAbTestVariant } from "services/ab-tests/lp-ab-tests-service";
import {
  getDocumentFullHeight,
  getPassiveIfSupported,
  getScrollPercent,
  getViewportHeight
} from "../../services/dom-utils-service";
import { PAGE_SCROLL_BY_PERCENTAGE } from "/constants/bigbrain-event-types";
import { getRandomInt } from "/services/utils-service";
import BypassBlocksMenuComponent from "components/accessibility/bypass-blocks-menu/bypass-block-menu";
import { fireGtmEvent } from "../../client/services/gtm-service/gtm-service";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { ConditionalWrapper } from "utils/react";
import { CompanyDataProvider } from "client/services/enrichment/company-data";
import { PrefetchSignupAssetsProvider } from "client/services/signup/prefetch-signup-service";
import componentStyles from "./base-template.scss";
import { getUrlParamValue } from "utils/url";
import classnames from "classnames";
import SignupPopupModalComponent from "components/core/signups/signup-popup/signup-popup-modal";
import LiveBoardModalComponent from "components/core/live-board-experience/live-board-modal";
import { SIGNUP_POPUP_CLOSED, LIVE_BOARD_CLOSED, LIVE_BOARD_OPENED } from "constants/bigbrain-event-types";
import { PrefetchTypeformAssetsProvider } from "client/services/typeform/prefetch-typeform-service";
import { PrefetchLiveBoardAssetsProvider } from "client/services/live-board/prefetch-liveboard-service";
import { DEBUG_AB_TEST_PARAM_NAME } from "constants/new-ab-tests";
import { setCookie } from "/services/cookies-service";
import { WEBSITE_AB_TEST_DEBUG_COOKIE_NAME } from "constants/cookies";
import { shouldRenderSecondaryMenu } from "segments/desktop/header/layouts/base-header/base-header-service";
import { StoreUTMDataForEnrichment } from "services/sales-enrichment/sales-enrichment-service";
import { shouldGetTypeformExperience } from "services/typeform/typeform-service";

const SCROLL_PERCENTAGE_THROTTLE_TIME = 1000; // Execute a method only once per THROTTLE_TIME
const SCROLL_PERCENTAGE_STEPS = 10;
const UTM_URL_TERM = "utm";

export default class BaseTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.pageLoadUnique = getRandomInt(100000000);
    this.passiveIfSupported = getPassiveIfSupported();
    this.scrollPercentageEvent = throttle(this.scrollingByPercentage, SCROLL_PERCENTAGE_THROTTLE_TIME, {
      leading: false,
      trailing: true
    });

    this.state = {
      maxRecordedScrollPercentage: 0,
      showSignupPopupModal: false,
      showLiveBoard: false,
      showLiveBoardImage: true,
      liveBoardClosed: false,
      liveBoardProps: {}
    };
  }

  openSignupPopup = ({ beforeSubmitCallback }) => {
    this.setState({ showSignupPopupModal: true, signupPopupModalBeforeSubmitCallback: beforeSubmitCallback });
  };

  closeSignupPopup = (data) => {
    this.setState({ showSignupPopupModal: false });
    trackEvent(SIGNUP_POPUP_CLOSED, data);
  };

  openLiveBoard = (props) => {
    this.setState({ showLiveBoard: true, showLiveBoardImage: false, liveBoardProps: props });
    trackEvent(LIVE_BOARD_OPENED);
  };

  closeLiveBoard = () => {
    this.setState({ showLiveBoard: false, showLiveBoardImage: true, liveBoardClosed: true });
    trackEvent(LIVE_BOARD_CLOSED);
  };

  scrollingByPercentage = async () => {
    const { maxRecordedScrollPercentage } = this.state;
    try {
      const currentScrollPercentage = await getScrollPercent();
      const documentHeight = await getDocumentFullHeight();
      const viewportHeight = await getViewportHeight();
      if (currentScrollPercentage > maxRecordedScrollPercentage + SCROLL_PERCENTAGE_STEPS) {
        this.setState({ maxRecordedScrollPercentage: currentScrollPercentage });
        trackEvent(PAGE_SCROLL_BY_PERCENTAGE, {
          kind: parseInt(currentScrollPercentage),
          info1: viewportHeight,
          info2: documentHeight,
          info3: this.pageLoadUnique //	for easily find the last event per page load
        });
      }
    } catch (e) {}
  };

  componentDidMount() {
    const { cloudfrontViewerCountry } = this.props;
    window.addEventListener("scroll", this.scrollPercentageEvent, this.passiveIfSupported);
    saveCloudfrontViewerCountryCookie(cloudfrontViewerCountry);
    if (window?.location?.href?.includes(UTM_URL_TERM)) {
      StoreUTMDataForEnrichment();
    }
    this.sendGenericAbTestEventIfNeeded();
    this.setAbTestDebugCookieIfNeeded();
  }

  setAbTestDebugCookieIfNeeded = () => {
    const { asPath } = this.props;
    const abTestDebugParamValue = getUrlParamValue(asPath, DEBUG_AB_TEST_PARAM_NAME);
    if (abTestDebugParamValue) {
      setCookie(WEBSITE_AB_TEST_DEBUG_COOKIE_NAME, abTestDebugParamValue, {
        expires: 1 / 24 // 1 hour
      });
    }
  };

  sendGenericAbTestEventIfNeeded() {
    const { abTestVariants, abTestExactEventName } = this.props;
    const abTestVariant = getExactEventNameAbTestVariant(abTestExactEventName, abTestVariants);

    if (abTestExactEventName && abTestVariant) {
      trackEvent(abTestExactEventName, { ab_test: abTestVariant });
      fireGtmEvent(DataLayerEventTypes.IN_PAGE_AB_TEST_EVENT_NAME, {
        abTestName: abTestExactEventName,
        abTestVariant
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollPercentageEvent, this.passiveIfSupported);
  }

  translate = (key) => {
    const { translations } = this.props;
    return translations[key] || key;
  };

  translateWithParam = (key, parameter1) => {
    const translation = this.translate(key);
    const templateString = translation.replace("%param1%", parameter1);
    return templateString;
  };

  translateOrTranslateWithParam = (keyToTranslate) => {
    if (!keyToTranslate) return null;
    if (typeof keyToTranslate === "object") {
      const { key, param } = keyToTranslate;
      return this.translateWithParam(key, param);
    }
    return this.translate(keyToTranslate);
  };

  render() {
    const {
      children,
      title,
      description,
      canonicalUrl,
      asPath,
      fontLazyLoad,
      showHeaderScripts,
      marketingTemplateBoardIds,
      showTrustpilotReviews,
      coupon,
      extendTrialCookie,
      clusterId,
      pageProductId,
      pageClusterId,
      pageSubClusterId,
      pageClusterConfig,
      subClusterId,
      clusterIdSource,
      subClusterIdSource,
      clusterConfig,
      isClusterMiniSitePage,
      useProductMiniSiteConfig,
      localeId,
      userSelectedLanguage,
      withFullstory,
      fullstoryRecordingFrequency,
      abTestVariants,
      abTestExactEventName,
      template,
      useExpandingSignup,
      disableGTM,
      isMobileOrTabletBrowser,
      isMobileBrowser,
      imagesLazyLoad,
      noIndex,
      noFollow,
      gtmContainerId,
      useSectionsMenu,
      isPreview,
      currency,
      cloudfrontViewerCountry,
      enableDriftChatBot,
      driftDelayedLoadMilliseconds,
      driftOpenChatDialogOnLaunch,
      driftPreventLoadOnLaunch,
      enableHotjar,
      hotjarFinalDate,
      pricingVersion,
      hasFreeTier,
      hideBannerWithBackground,
      cookies, // bad practice - should move needed cookies to persistantCookies (they are aligned in cloudFront cache ass well)
      persistantCookies,
      lazyImages,
      grantedFeatures,
      freeTierForcePrevent,
      skipClustersQuestion,
      forcePricing,
      forceCurrency,
      hasStudentPlan,
      facebookMetaOverride,
      twitterMetaOverride,
      enablePageUsageDurationTracking,
      dynamicAssetParam,
      activePagePath,
      activeOriginalPagePath,
      loadCompanyDetails,
      localizedPages,
      pageBackgroundColorName,
      is404Page,
      useSignUpPopUp,
      testTypeformContactSales,
      forceTypeformContactSalesExperience,
      sendToSignupWithProductPage,
      seoFAQ,
      addLiveBoardExperience,
      contactSalesTestMode,
      shouldGetSignupPrefetchedAssets,
      signupDynamicAssetsLinks,
      embeddedBoardLink
    } = this.props;

    const { showSignupPopupModal, showLiveBoard, showLiveBoardImage, liveBoardClosed, liveBoardProps } = this.state;
    const isSecondaryMenuNeeded = shouldRenderSecondaryMenu(
      isClusterMiniSitePage,
      pageClusterConfig,
      useProductMiniSiteConfig
    );

    const shouldGetTypeformPrefetchedAssets = shouldGetTypeformExperience({
      abTests: abTestVariants,
      cloudfrontViewerCountry,
      testTypeformContactSales,
      forceTypeformContactSalesExperience
    });

    return (
      <GenericConfigContext.Provider
        value={{
          localeId,
          clusterId,
          pageProductId,
          pageClusterId,
          pageSubClusterId,
          pageClusterConfig,
          subClusterId,
          clusterConfig,
          isClusterMiniSitePage,
          useProductMiniSiteConfig,
          userSelectedLanguage,
          useExpandingSignup,
          isMobileOrTabletBrowser,
          isMobileBrowser,
          imagesLazyLoad,
          noIndex,
          noFollow,
          isPreview,
          hasFreeTier,
          pricingVersion,
          cloudfrontViewerCountry,
          lazyImages,
          translate: this.translate,
          translateWithParam: this.translateWithParam,
          translateOrTranslateWithParam: this.translateOrTranslateWithParam,
          openSignupPopup: this.openSignupPopup,
          hideBannerWithBackground,
          cookies,
          persistantCookies,
          currency,
          grantedFeatures,
          freeTierForcePrevent,
          forcePricing,
          forceCurrency,
          hasStudentPlan,
          dynamicAssetParam,
          activePagePath,
          activeOriginalPagePath,
          useSectionsMenu,
          is404Page,
          useSignUpPopUp,
          testTypeformContactSales,
          forceTypeformContactSalesExperience,
          sendToSignupWithProductPage,
          isSecondaryMenuNeeded,
          contactSalesTestMode,
          addLiveBoardExperience,
          openLiveBoard: this.openLiveBoard,
          showLiveBoardImage,
          liveBoardClosed
        }}
      >
        <AbTestsContext.Provider
          value={{
            abTestVariants,
            abTestExactEventName
          }}
        >
          <ConditionalWrapper
            condition={loadCompanyDetails}
            wrapper={(children) => <CompanyDataProvider>{children}</CompanyDataProvider>}
          >
            <ConditionalWrapper
              condition={shouldGetSignupPrefetchedAssets}
              wrapper={(children) => (
                <PrefetchSignupAssetsProvider signupDynamicAssetsLinks={signupDynamicAssetsLinks}>
                  {children}
                </PrefetchSignupAssetsProvider>
              )}
            >
              <ConditionalWrapper
                condition={shouldGetTypeformPrefetchedAssets}
                wrapper={(children) => (
                  <PrefetchTypeformAssetsProvider cloudfrontViewerCountry={cloudfrontViewerCountry}>
                    {children}
                  </PrefetchTypeformAssetsProvider>
                )}
              >
                <ConditionalWrapper
                  condition={addLiveBoardExperience}
                  wrapper={(children) => <PrefetchLiveBoardAssetsProvider>{children}</PrefetchLiveBoardAssetsProvider>}
                >
                  <>
                    <Head
                      title={title}
                      description={description}
                      canonicalUrl={canonicalUrl}
                      seoFAQ={seoFAQ}
                      fontLazyLoad={fontLazyLoad}
                      template={template}
                      disableGTM={disableGTM}
                      gtmContainerId={gtmContainerId}
                      isMobileOrTabletBrowser={isMobileOrTabletBrowser}
                      pagePath={asPath}
                      facebookMetaOverride={facebookMetaOverride}
                      twitterMetaOverride={twitterMetaOverride}
                      localizedPages={localizedPages}
                      shouldGetSignupPrefetchedAssets={shouldGetSignupPrefetchedAssets}
                      shouldGetTypeformPrefetchedAssets={shouldGetTypeformPrefetchedAssets}
                      addLiveBoardExperience={addLiveBoardExperience}
                    />

                    {showHeaderScripts && (
                      <HeaderScriptsComponent
                        coupon={coupon}
                        extendTrialCookie={extendTrialCookie}
                        marketingTemplateBoardIds={marketingTemplateBoardIds}
                        clusterId={clusterId}
                        subClusterId={subClusterId}
                        clusterIdSource={clusterIdSource}
                        subClusterIdSource={subClusterIdSource}
                        localeId={localeId}
                        template={template}
                        skipClustersQuestion={skipClustersQuestion}
                        enablePageUsageDurationTracking={enablePageUsageDurationTracking}
                      />
                    )}

                    {!isPreview && (
                      <div className="accessibility-menu-wrapper">
                        <BypassBlocksMenuComponent />
                      </div>
                    )}

                    <div
                      className={classnames("template-content-wrapper", {
                        "with-background-color": !!pageBackgroundColorName
                      })}
                    >
                      {children}
                      <div className="signup-popup-wrapper-main">
                        <SignupPopupModalComponent
                          showSignupPopupModal={showSignupPopupModal}
                          openSignupPopup={this.openSignupPopup}
                          closeSignupPopup={this.closeSignupPopup}
                          translate={this.translate}
                          beforeSubmitCallback={this.state.signupPopupModalBeforeSubmitCallback}
                        />
                      </div>
                      {addLiveBoardExperience && (
                        <>
                          <div className="live-board-popup-wrapper">
                            <LiveBoardModalComponent
                              showLiveBoard={showLiveBoard}
                              closeLiveBoard={this.closeLiveBoard}
                              translate={this.translate}
                              openPeekToBoard={this.openPeekToBoard}
                              closePeekToBoard={this.closePeekToBoard}
                              embeddedBoardLink={embeddedBoardLink}
                              {...liveBoardProps}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {showHeaderScripts && (
                      <BodyScriptsComponent
                        withFullstory={withFullstory}
                        fullstoryRecordingFrequency={fullstoryRecordingFrequency}
                        showTrustpilotReviews={showTrustpilotReviews}
                        pagePath={asPath}
                        fontLazyLoad={fontLazyLoad}
                        template={template}
                        disableGTM={disableGTM}
                        gtmContainerId={gtmContainerId}
                        enableDriftChatBot={enableDriftChatBot}
                        driftDelayedLoadMilliseconds={driftDelayedLoadMilliseconds}
                        driftOpenChatDialogOnLaunch={driftOpenChatDialogOnLaunch}
                        driftPreventLoadOnLaunch={driftPreventLoadOnLaunch}
                        enableHotjar={enableHotjar}
                        hotjarFinalDate={hotjarFinalDate}
                        clusterId={clusterId}
                        hasFreeTier={hasFreeTier}
                        localeId={localeId}
                        subClusterId={subClusterId}
                        isMobileOrTabletBrowser={isMobileOrTabletBrowser}
                        loadCompanyDetails={loadCompanyDetails}
                        is404Page={is404Page}
                      />
                    )}
                    <style jsx>{componentStyles}</style>
                  </>
                </ConditionalWrapper>
              </ConditionalWrapper>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </AbTestsContext.Provider>
      </GenericConfigContext.Provider>
    );
  }
}

BaseTemplate.defaultProps = {
  showHeaderScripts: true,
  withFullstory: false,
  useExpandingSignup: false,
  translations: {},
  isPreview: false,
  skipClustersQuestion: false
};
