import { useState } from "react";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import PictureComponent from "/components/core/picture/picture-component";
import Button from "segments/desktop/core-components/button/button";
import CloseIcon from "/static/svg/icons/close.svg";
import classnames from "classnames";
import { setCookie } from "services/cookies-service";
import { HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME } from "constants/cookies";
import { trackEvent } from "services/bigbrain-service";
import { TOP_BANNER_CLOSED_CLICK, BANNER_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { themes } from "segments/segments-service";
import Colors from "styles/colors";
import {
  REGULAR_BUTTON_TYPE,
  SECONDARY_BUTTON_TYPE,
  YOUTUBE_POPUP_LINK_TYPE
} from "segments/desktop/core-components/button/button-consts";
import { SM } from "components/core/new-button/button-component";
import { getComponentStyles } from "./banner.scss";
import DefaultProps from "./default-props";
import { withGenericConfig } from "/contexts/generic-config-context";

const BANNER_EXPIRE_DAYS = 7;
const SIGNUP_BANNER_PLACEMENT = "top_with_background_banner";

const Banner = (props) => {
  const {
    bannerText,
    bannerLinkData,
    bannerCta,
    bannerleftImage,
    bannerRightImage,
    withCloseIcon,
    theme,
    buttonConfig,
    isMobileOrTabletBrowser,
    isSecondaryMenuNeeded,
    placement,
    hideBannerWithBackground,
    extraData
  } = props;

  const [hideBanner, setHideBanner] = useState(hideBannerWithBackground && withCloseIcon); // for now only banner that can be closed use the cookie

  const componentStyles = getComponentStyles(theme);

  const getThemes = () => {
    return {
      ...themes,
      "dodger-blue": { color: Colors.white, background: Colors["dodger-blue"] }
    };
  };

  const getThemeStyle = () => {
    return getThemes()[theme] || {};
  };

  const themeStyle = getThemeStyle();

  const onCloseBanner = () => {
    trackEvent(TOP_BANNER_CLOSED_CLICK);
    setCookie(HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME, true, {
      expires: BANNER_EXPIRE_DAYS
    });
    setHideBanner(true);
  };

  const onBannerLinkClicked = (link) => {
    trackEvent(BANNER_LINK_CLICKED, { kind: link });
  };

  const getBannerButtonProps = () => {
    let buttonProps = {
      ctaText: bannerCta,
      placement: placement || SIGNUP_BANNER_PLACEMENT,
      buttonSpinnerColor: themeStyle.background,
      parentSegment: "Banner",
      useCtaText: true,
      extraData
    };

    if (isMobileOrTabletBrowser) {
      const MOBILE_NOT_ALLOWED_BUTTON_TYPES = [REGULAR_BUTTON_TYPE];

      const buttonType = buttonConfig.buttonType;
      const buttonDisplayType = buttonConfig.buttonDisplayType;

      const extraButtonProps = {};
      if (MOBILE_NOT_ALLOWED_BUTTON_TYPES.includes(buttonType)) {
        extraButtonProps.buttonType = SECONDARY_BUTTON_TYPE;
      }
      if (
        buttonConfig.buttonType === YOUTUBE_POPUP_LINK_TYPE &&
        MOBILE_NOT_ALLOWED_BUTTON_TYPES.includes(buttonDisplayType)
      ) {
        extraButtonProps.buttonDisplayType = SECONDARY_BUTTON_TYPE;
      }

      buttonProps = {
        ...buttonProps,
        ...extraButtonProps,
        buttonSize: SM
      };
    }
    return buttonProps;
  };

  return (
    <div
      className={classnames("banner", {
        "hide-banner": hideBanner,
        "top-margin": isSecondaryMenuNeeded
      })}
      style={themeStyle}
    >
      <div className="banner-content">
        {bannerleftImage && !isMobileOrTabletBrowser && (
          <div className="image-wrapper">
            <PictureComponent src={bannerleftImage} className="banner-image" pictureClass="banner-picture" />
          </div>
        )}
        <div className="banner-text-and-cta">
          <div className="banner-text-and-link">
            <MondayMarkupLanguageComponent className="banner-text" text={bannerText} />
            {bannerLinkData && (
              <a
                className="banner-link"
                href={bannerLinkData.src}
                style={{ color: themeStyle.color }}
                target="_blank"
                rel="noreferrer"
                onClick={() => onBannerLinkClicked(bannerLinkData.src)}
              >
                {bannerLinkData.text}
              </a>
            )}
            {buttonConfig && buttonConfig.buttonType && (
              <div className="banner-cta-wrapper">
                <Button {...buttonConfig} {...getBannerButtonProps()} />
              </div>
            )}
          </div>
        </div>
        {bannerRightImage && !isMobileOrTabletBrowser && (
          <div className="image-wrapper">
            <PictureComponent src={bannerRightImage} className="banner-image" pictureClass="banner-picture" />
          </div>
        )}
      </div>
      {withCloseIcon && (
        <div className="close-icon-wrapper" onClick={onCloseBanner}>
          <CloseIcon />
        </div>
      )}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

Banner.defaultProps = DefaultProps;

export default withGenericConfig(Banner);
