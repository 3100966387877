import { shouldGetWelcomeBackPageTest } from "services/ab-tests/acquisition-ab-test-service";
import { getAbTestVariantNew } from "services/new-ab-tests/new-ab-tests-service";
import { WELCOME_BACK_PAGE_TEST } from "constants/new-ab-tests";
import {
  WELCOME_PAGE_FULL_PATH,
  PLATFORM_VISITOR_AB_TEST_NAME_PARAM_NAME,
  PLATFORM_VISITOR_AB_TEST_VARIANT_PARAM_NAME
} from "constants/welcome-page-constants";
import { LAST_ACCOUNT_SLUG } from "constants/cookies";
import { getSlugDataInlineScript } from "/utils/inline-scripts";

const platformDomain = process.env.PLATFORM_DOMAIN;

const RedirectToPlatfromScript = ({ abTests, persistantCookies }) => {
  const shouldGetWelcomeBackPage = shouldGetWelcomeBackPageTest(abTests, persistantCookies);
  const welcomeBackVariant = getAbTestVariantNew(abTests, WELCOME_BACK_PAGE_TEST.test_name, false);

  const insertUrlRedirectDefinition = () => {
    let platformTestParams = "";

    if (shouldGetWelcomeBackPage) {
      return `appUrl = "${WELCOME_PAGE_FULL_PATH}";`;
    }

    if (welcomeBackVariant) {
      platformTestParams = `?${PLATFORM_VISITOR_AB_TEST_NAME_PARAM_NAME}=${WELCOME_BACK_PAGE_TEST.test_name}&${PLATFORM_VISITOR_AB_TEST_VARIANT_PARAM_NAME}=${welcomeBackVariant}`;
    }

    return `
          appUrl = "//"+lastActiveSlug + ".${platformDomain}${platformTestParams}";
    `;
  };

  const redirectToPlatformIfNeeded = () => {
    return `
        ${getSlugDataInlineScript}
        const lastActiveSlug = cookieValueByName('${LAST_ACCOUNT_SLUG}');  // cookieValueByName defined inside getSlugDataInlineScript
 
        const preventRedirect = window.location.search.indexOf('no-redirect') > 0;

        if(lastActiveSlug && !preventRedirect) {
          var appUrl = "//"+lastActiveSlug + ".${platformDomain}";
          const singleNewSlugFirstUser = onlyNewFirstUserSlug(new Date("${WELCOME_BACK_PAGE_TEST.launch_date}"));
          if (singleNewSlugFirstUser) {
            ${insertUrlRedirectDefinition()}
          }
           
          window.location.replace(appUrl);
        }
    `;
  };

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: redirectToPlatformIfNeeded()
      }}
    />
  );
};

export default RedirectToPlatfromScript;
