import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M } from "/constants/vp-sizes";

export const componentStyles = css`
  .signup-combo-buttons-button {
    display: flex;
    align-items: center;
    gap: 24px;

    &.swap-buttons-order {
      flex-direction: row-reverse;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .signup-combo-buttons-button {
      &.swap-buttons-order {
        flex-direction: column;
      }
    }
  }
`;
