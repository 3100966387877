const MONDAY_BASE_PATH = process.env.MONDAY_BASE_PATH;

export const getFacebookMetaTags = ({
  title,
  description,
  pagePath,
  imagePath = "https://s3.amazonaws.com/general-assets/monday-1200x628.png",
  imageType = "image/png",
  imageWidth = "1200",
  imageHeight = "628"
}) => {
  return [
    <meta property="og:title" content={title} />,
    <meta property="og:type" content="website" />,
    <meta property="og:url" content={`${MONDAY_BASE_PATH}${pagePath}`} />,
    <meta property="og:description" content={description} />,
    <meta property="og:site_name" content="monday.com" />,

    <meta property="og:image" content={imagePath} />,
    <meta property="og:image:type" content={imageType} />,
    <meta property="og:image:width" content={imageWidth} />,
    <meta property="og:image:height" content={imageHeight} />
  ];
};
