import css from "styled-jsx/css";
import { ALWAYS_ON_TOP, ZERO } from "constants/z-index";

export const componentStyles = css.global`
  .live-board-modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .live-board-modal-overlay {
    position: fixed;
    top: 100vh;
    opacity: 0;
    z-index: ${ZERO};
    transition: opacity 1s;
    &.fullscreen-board {
      top: 0;
      opacity: 1;
      z-index: ${ALWAYS_ON_TOP};
    }
  }

  .modal-open {
    overflow-y: scroll;
    &.fullscreen-board {
      overflow-y: hidden;
    }
  }
`;
