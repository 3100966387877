import { PureComponent } from "react";
import { componentStyles } from "./secondary-button.scss";
import DefaultProps from "./default-props";
import colors from "/styles/colors";
import { SECONDARY_BUTTON_CLICKED } from "/constants/bigbrain-event-types";
import { getAccessibleButtonColor } from "client/services/accessibility/accessibility-service";
import {
  DODGER_BLUE_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  ELEVATE_BUTTON_GRADIANT_COLOR_NAME,
  WHITE_COLOR_NAME
} from "styles/color-consts";
import { withAbTests } from "contexts/ab-tests-context";
import { getLastPartOfUrl } from "utils/url";
import classnames from "classnames";

import RightArrow from "/static/svg/arrow-right-3.svg";
import { hexToRgba } from "utils/general";

class SecondaryButton extends PureComponent {
  onClick = (e) => {
    const { buttonText, url, onClick } = this.props;

    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    BigBrain("track", SECONDARY_BUTTON_CLICKED, {
      kind: buttonText,
      info1: isKeyboardEvent ? "keyboard" : "mouse",
      info2: url,
      info3: getLastPartOfUrl(url)
    });

    if (onClick) {
      onClick(e);
    }
  };

  getButtonColor = (color) => {
    if (color === DODGER_BLUE_COLOR_NAME) return SPACE_BLUE_COLOR_NAME;
    if (color === ELEVATE_BUTTON_GRADIANT_COLOR_NAME) return WHITE_COLOR_NAME;
    return color;
  };

  render() {
    const { buttonText, url, isOpenLinkInNewTab, color, isWithArrow, iconRenderer, isWithIconInsteadOfArrow } =
      this.props;
    const accessibleColor = getAccessibleButtonColor(color);

    const finalColor = this.getButtonColor(accessibleColor);

    const showIcon = iconRenderer && !isWithIconInsteadOfArrow;
    const showArrow = isWithArrow && !isWithIconInsteadOfArrow;
    const showIconInsteadOfArrow = isWithIconInsteadOfArrow && iconRenderer;

    let aProps = {
      style: {
        color: colors[finalColor],
        "--underline-color": colors[finalColor],
        "--underline-color-with-opacity": hexToRgba(colors[finalColor], 0.4)
      }
    };
    if (url) {
      aProps = { ...aProps, href: url, target: isOpenLinkInNewTab ? "_blank" : "_self" };
    }

    return (
      <div className="secondary-button">
        <a
          {...aProps}
          onClick={this.onClick}
          className={classnames("with-underline", {
            "with-icon": iconRenderer,
            "with-icon-instead-of-arrow": showIconInsteadOfArrow
          })}
        >
          {showIcon && iconRenderer()}
          <span className="secondary-button-text">{buttonText}</span>
          {showArrow && (
            <span className="arrow-wrapper">
              <RightArrow />
            </span>
          )}
          {showIconInsteadOfArrow && <span className="icon-instead-of-arrow-wrapper">{iconRenderer()}</span>}
        </a>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SecondaryButton.defaultProps = DefaultProps;

export default withAbTests(SecondaryButton);
