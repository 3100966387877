const BODY_CONTENT_HORIZONTAL_PADDING = 96;

export const COMPONENTS_SHADOW_PROPS = {
  offsetX: 0,
  offsetY: 8,
  blurRadius: 48,
  defaultOpacity: 0.16,
  hoverOpacity: 0.32
};

const getComponentBoxShadow = (opacity) => {
  return `box-shadow: 0px ${COMPONENTS_SHADOW_PROPS.offsetY}px ${COMPONENTS_SHADOW_PROPS.blurRadius / 2}px 0 rgba(29, 140, 242, ${opacity});`;
};

const getComponentShadowFilter = (opacity = COMPONENTS_SHADOW_PROPS.defaultOpacity) => {
  return `filter: drop-shadow(0px ${COMPONENTS_SHADOW_PROPS.offsetY}px ${COMPONENTS_SHADOW_PROPS.blurRadius / 2}px rgba(29, 140, 242, ${opacity}));`;
};

const getMobileComponentShadowFilter = (opacity) => {
  return `filter: drop-shadow(0px ${COMPONENTS_SHADOW_PROPS.offsetY}px ${COMPONENTS_SHADOW_PROPS.blurRadius / 3}px rgba(29, 140, 242, ${opacity}));`;
};

const getComponentShadow = () => getComponentBoxShadow(COMPONENTS_SHADOW_PROPS.defaultOpacity);
const getComponentShadowWithHoverEffect = () => getComponentBoxShadow(COMPONENTS_SHADOW_PROPS.hoverOpacity);

const COMPONENTS_SHADOW_FILTER = getComponentShadowFilter(0.5);
const MOBILE_COMPONENTS_SHADOW_FILTER = getMobileComponentShadowFilter(0.3);

module.exports = {
  BODY_CONTENT_HORIZONTAL_PADDING,
  COMPONENTS_SHADOW_PROPS,
  COMPONENTS_SHADOW_FILTER,
  MOBILE_COMPONENTS_SHADOW_FILTER,
  getComponentShadow,
  getComponentShadowWithHoverEffect,
  getComponentShadowFilter,
  getMobileComponentShadowFilter
};
