import withResponsiveHOC from "/components/enhancements/responsive-hoc/responsive-hoc-component";
import FlexibleCardsGridDesktopComponent from "segments/desktop/grid/layouts/flexible-cards-grid/components/flexible-cards-grid-desktop/flexible-cards-grid-desktop-component";
import FlexibleCardsGridMobileComponent from "segments/desktop/grid/layouts/flexible-cards-grid/components/flexible-cards-grid-mobile/flexible-cards-grid-mobile-component";
import FlexibleCardGridDefaultProps from "./components/default-props";

const FlexibleCardGridComponent = withResponsiveHOC(
  FlexibleCardsGridDesktopComponent,
  FlexibleCardsGridMobileComponent
);
export default FlexibleCardGridComponent;

FlexibleCardGridComponent.defaultProps = FlexibleCardGridDefaultProps;
