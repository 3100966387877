import Carousel from "segments/desktop/core-components/carousel/carousel";
import { componentStyles } from "./flexible-cards-grid-mobile-component.scss";
import GridParagraphWithImage from "segments/desktop/core-components/grid-paragraph-with-image/grid-paragraph-with-image";
import classnames from "classnames";

const FlexibleCardsGridMobileComponent = (props) => {
  const { gridItems, titleSize, textSize, shadowCards, removeShadowOnHover, cancelMobileMarginTop } = props;

  const itemRenderer = (gridItem) => {
    const { mobileImage, textColor, cardColor, fullParagraph } = gridItem;
    return (
      <div className="grid-paragraph-with-image-component-wrapper">
        <GridParagraphWithImage
          {...mobileImage}
          fullParagraph={fullParagraph}
          width={"100%"}
          height={"auto"}
          titleSize={titleSize}
          textSize={textSize}
          cardColor={cardColor}
          textColor={textColor}
          shadowCards={shadowCards}
          removeShadowOnHover={removeShadowOnHover}
        />
      </div>
    );
  };

  const carouselProps = {
    items: gridItems,
    itemRenderer,
    defaultIndex: 0,
    infinite: false,
    slidesToShow: 1,
    dots: true,
    centerPadding: "10%"
  };

  return (
    <div className="flexible-cards-grid-mobile-component">
      <div className={classnames("carousel-wrapper", { "no-margin": cancelMobileMarginTop })}>
        <Carousel {...carouselProps} />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default FlexibleCardsGridMobileComponent;
