import { PureComponent } from "react";
import { getExactEventNameAbTestVariant } from "services/ab-tests/lp-ab-tests-service";
import { fireHotjarEventScript } from "client/services/hotjar-service/hotjar-service";

export default class HotjarScript extends PureComponent {
  startHotjarSessionByVariant(abTestVariant) {
    return fireHotjarEventScript(abTestVariant);
  }

  renderHotjarScript() {
    return `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1566695,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
  }

  render() {
    const { abTests, abTestExactEventName } = this.props;
    const abTestVariant = getExactEventNameAbTestVariant(abTestExactEventName, abTests);
    return (
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: this.renderHotjarScript()
          }}
        />
        {abTestVariant && (
          <script
            async
            dangerouslySetInnerHTML={{
              __html: this.startHotjarSessionByVariant(abTestVariant)
            }}
          />
        )}
      </>
    );
  }
}
