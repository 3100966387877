import { PureComponent } from "react";
import classnames from "classnames";
import colors from "styles/colors";
import { ConditionalWrapper } from "utils/react";
import {
  generateOnKeyDownHandler,
  getAccessibleButtonColor
} from "client/services/accessibility/accessibility-service";
import ButtonArrow from "segments/desktop/core-components/button/button-arrow/button-arrow";
import { getHoverColor } from "styles/colors-utils";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
import componentStyles from "./button-component.scss";

// BUTTON SIZES
export const XXS = "xxs";
export const XS = "xs";
export const SM = "sm";
export const MD = "md";
export const LG = "lg";
export const FULL = "full";

// BUTTON COLORS
export const BLUE = "blue";
export const MINT_GREEN = "mint-green";
export const BOLD_BLUE = "bold-blue";
export const WHITE = "white";
export const IRIS = "iris";


export default class ButtonComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDown = generateOnKeyDownHandler(this.onClick);
    this.state = { isHovered: false };
  }

  getComponentInlineStyles() {
    const { outline, color, textColor } = this.props;

    return {
      color: outline ? color : textColor
    };
  }

  onClick = (e) => {
    const { onClick, disabled } = this.props;
    e?.target.blur(); //remove focus on mouse click

    if (disabled) return;
    onClick(e);
  };

  linkWrapper = (children) => {
    const { isOpenLinkInNewTab, seoFriendlyUrl } = this.props;
    return (
      <a href={seoFriendlyUrl} target={isOpenLinkInNewTab ? "_blank" : "_self"}>
        {children}
      </a>
    )
  };

  render() {
    const { children, className, color, size, outline, disabled, isWithArrow, seoFriendlyUrl } = this.props;
    const { isHovered } = this.state;
    const inlineComponentStyles = this.getComponentInlineStyles();

    const accessibleColor = getAccessibleButtonColor(color);
    return (
      <ConditionalWrapper condition={!!seoFriendlyUrl} wrapper={this.linkWrapper}>
        <div
          className={classnames("button-component", className, size, accessibleColor, {
            outline,
            disabled,
            "with-arrow": isWithArrow
          })}
          onClick={this.onClick}
          style={inlineComponentStyles}
          onKeyDown={this.onKeyDown}
          tabIndex={disabled ? -1 : 0}
          role={"button"}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          data-button-color={accessibleColor}
        >
          {children}
          {isWithArrow && <ButtonArrow size={size} hover={isHovered} />}
          <style jsx global>{`
          .button-component[data-button-color="${accessibleColor}"] {
            &:not(.outline) {
              background: ${colors[accessibleColor]};
              &:hover {
                background-color: ${getHoverColor(colors[accessibleColor])};
                &.white {
                  background-color: ${colors[BACKGROUND_GRAY_COLOR_NAME]};
                }
              }
            }
          }
          `}</style>
          <style jsx>{componentStyles}</style>
        </div>
      </ConditionalWrapper>
    );
  }
}

ButtonComponent.defaultProps = {
  className: "",
  color: BLUE,
  size: MD,
  outline: false,
  textColor: colors["white"],
  disabled: false
};
