import { cloneElement, useRef, useState } from "react";
import classNames from "classnames";
import { ALIGN_TYPES } from "constants/align-types";
import { withRouter } from "next/router";
import { isNodeFullyInView, scrollTo } from "/services/dom-utils-service";
import querystring from "querystring";
import { ACTIVE_TAB_ID } from "constants/query-params";
import useInterval from "client/services/hooks/use-interval";
import { componentStyles } from "./tabs-component.scss";

const TabsComponent = (props) => {
  const {
    children,
    defaultActiveTabIndex,
    router,
    autoTransition,
    className,
    tabsAlign,
    scrollSpeed,
    scrollOnTabClick,
    distanceFromTop,
    onTabClick
  } = props;

  const getInitialActiveTabIndex = () => {
    try {
      const params = querystring.parse(router?.asPath?.split("?")?.[1]);
      const activeTabId = params?.[ACTIVE_TAB_ID];
      if (activeTabId) {
        const activeTabIndexById = children?.findIndex((tab) => {
          return tab?.props?.tabId === activeTabId;
        });
        if (activeTabIndexById && activeTabIndexById >= 0) {
          return activeTabIndexById;
        }
      }
    } catch (e) {
      return defaultActiveTabIndex;
    }
    return defaultActiveTabIndex;
  };

  const [activeTabIndex, setActiveTabIndex] = useState(getInitialActiveTabIndex());
  const contentNode = useRef();

  const autoTransitionAsNumber = Number(autoTransition || 0);
  if (autoTransitionAsNumber > 0) {
    useInterval(
      () => {
        setActiveTabIndex((activeTabIndex + 1) % children.length);
      },
      autoTransitionAsNumber,
      [activeTabIndex]
    );
  }

  const handleTabClick = async (index) => {
    const node = contentNode?.current;
    if (onTabClick) {
      onTabClick(index);
    }

    try {
      if (scrollOnTabClick && node && !(await isNodeFullyInView(node))) {
        scrollTo(contentNode.current.offsetTop - distanceFromTop, scrollSpeed);
      }
    } catch (e) {}
    setActiveTabIndex(index);
  };

  const tabsRenderer = () => {
    return children.map((child, index) => {
      return cloneElement(child, {
        onClick: handleTabClick,
        index,
        isActive: index === activeTabIndex
      });
    });
  };

  const contentRenderer = () => {
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  };

  return (
    <div
      className={classNames("tabs-component", className, tabsAlign)}
      ref={contentNode}
      role="tablist"
      aria-level="documentation-navigation"
    >
      <div className="tabs">{tabsRenderer()}</div>
      <div className="content" role="tabpanel">
        {contentRenderer()}
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withRouter(TabsComponent);

TabsComponent.defaultProps = {
  children: [],
  defaultActiveTabIndex: 0,
  autoTransition: "30000",
  scrollSpeed: 200,
  scrollOnTabClick: false,
  distanceFromTop: 0,
  className: "",
  tabsAlign: ALIGN_TYPES.CENTER
};
