import { useCallback, useMemo, useState } from "react";
import PictureComponent from "/components/core/picture/picture-component";
import Colors from "/styles/colors";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { GRID_TAG_CLICKED, GRID_TAG_HOVERED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";

import classnames from "classnames";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { fireGtmEvent } from "client/services/gtm-service/gtm-service";

import TagCheckbox from "./components/tag-checkbox";
import { componentStyles } from "./square-tag-component.scss";

const SquareTagComponent = (props) => {
  const CLUSTER_CLICK_ANIMATION_TIME = 300;
  const [isHovered, setIsHovered] = useState(false);

  const [wasClusterSelected, setWasClusterSelected] = useState(false);
  const { starText, starImage, starColorName, onStarClick, tagScheme, isSelected, isDarkTheme, checkboxScheme, textScheme } =
    props;

  const blurTag = (e) => {
    e?.target?.closest(`.square-tag-component`)?.blur();
  };

  const getCurrentColor = useCallback(
    (selectedColor, hoverColor, baseColor) => {
      if (isSelected) return Colors[selectedColor];
      return isHovered ? Colors[hoverColor] : Colors[baseColor];
    },
    [isHovered, isSelected]
  );

  const tagBackgroundColor = useMemo(
    () => getCurrentColor(tagScheme?.selectedBackgroundColor, tagScheme?.hoverColor, null),
    [getCurrentColor, tagScheme?.hoverColor, tagScheme?.selectedBackgroundColor]
  );

  const tagTextColor = useMemo(
    () => getCurrentColor(textScheme?.selectedColor, textScheme?.hoverColor, textScheme?.color),
    [getCurrentColor, textScheme?.selectedColor, textScheme?.hoverColor, textScheme?.color]
  );

  const onClick = (e) => {
    blurTag(e);
    onTagSelection();
    trackEvent(GRID_TAG_CLICKED, { kind: starText });
    fireGtmEvent(DataLayerEventTypes.TAG_SELECTED, { name: starText });
  };

  const onTagSelection = () => {
    setWasClusterSelected(true);
    setTimeout(() => setWasClusterSelected(false), CLUSTER_CLICK_ANIMATION_TIME);
    onStarClick && onStarClick();
  };

  const tagBorderColor = useMemo(
    () => (tagScheme?.borderColor && (isSelected || isHovered) ? "transparent" : Colors[tagScheme?.borderColor]),
    [isHovered, isSelected, tagScheme?.borderColor]
  );

  const onMouseOver = () => {
    setIsHovered(true);
    trackEvent(GRID_TAG_HOVERED, { kind: starText });
  };

  const onMouseLeave = () => setIsHovered(false);

  const starColor =
    Colors[tagScheme?.selectedBackgroundColor] || Colors[starColorName] || Colors[WORK_OS_IRIS_COLOR_NAME];

  return (
    <div
      className={classnames("square-tag-component", {
        selected: isSelected,
        "selectable-cluster": true,
        "light-theme": !isDarkTheme,
        "cluster-selected": wasClusterSelected
      })}
      style={{ color: starColor, borderColor: tagBorderColor, backgroundColor: tagBackgroundColor }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onKeyDown={generateOnKeyDownHandler(onTagSelection)}
      tabIndex={0}
      role="checkbox"
      aria-checked={isSelected}
    >
      <div className="background-circle" />
      <TagCheckbox {...checkboxScheme} isSelected={isSelected} tagBorderColor={tagScheme?.borderColor} />
      <div className="star-image">
        <PictureComponent className="star-icon" pictureClass="star-picture" src={starImage} />
      </div>
      <div className="star-text" style={{ color: tagTextColor }}>
        {starText}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SquareTagComponent;

SquareTagComponent.defaultProps = {
  starText: "Marketing",
  starImage:
    "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/products/product-icons/icons_3d/Marketing_icon_circle.png",
  starColorName: "marketing-red"
};
