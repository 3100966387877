import { PureComponent } from "react";
import RegularSignupComponent from "components/core/signups/regular-signup-component/regular-signup-component";
import { componentStyles } from "./signup-button.scss";
import DefaultProps from "./default-props";

export default class SignupButton extends PureComponent {
  render() {
    return (
      <div className="signup-button">
        <RegularSignupComponent {...this.props} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SignupButton.defaultProps = DefaultProps;
