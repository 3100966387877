import { SM } from "components/core/new-button/button-component";

export default {
  bannerText: "<monday-bold>Is your team working from home?</monday-bold> Use monday.com to get remote work done",
  bannerCta: "Start Collaborating",
  withCloseIcon: true,
  theme: "dodger-blue",
  buttonConfig: {
    buttonSize: SM
  },
  extraData: {}
};
