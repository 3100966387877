import { useState, useEffect, createContext, useContext } from "react";
import { getCompanyData } from "./api-service";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID, EXPERIMENT_VISITOR_ID } from "/constants/cookies";
import { truncateQueryParams } from "utils/url";

const CompanyDataContext = createContext("CompanyData");

export const CompanyDataProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState({ enrichedAttempted: false });

  useEffect(() => {
    const setData = async () => {
      const pagePath = truncateQueryParams(window?.location?.href);
      const visitorId = getCookie(BIG_BRAIN_VISITOR_ID);
      const experimentVisitorId = getCookie(EXPERIMENT_VISITOR_ID);
      const clearbitResponse = await getCompanyData(pagePath, visitorId, experimentVisitorId);
      setCompanyData({ ...clearbitResponse, enrichedAttempted: true }); // Failed enrichments will not have data field
    };

    setData();
  }, []);

  return <CompanyDataContext.Provider value={companyData}>{children}</CompanyDataContext.Provider>;
};

export const useCompanyData = () => {
  const companyData = useContext(CompanyDataContext);

  return companyData;
};
