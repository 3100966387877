import titleDefaultProps from "segments/desktop/core-components/title/default-props";
import paragraphDefaultProps from "segments/desktop/core-components/paragraph/default-props";

export default {
  topic: { text: "Lorem ipsum dolor", color: "brand-blue" },
  ...titleDefaultProps,
  ...paragraphDefaultProps,
  textAlign: "left",
  mobileTextAlign: null // has to be null so it will default to regular text align
};
