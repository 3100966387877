import { PureComponent } from "react";
import { componentStyles } from "./embedded-board.scss";
import DefaultProps from "./default-props";
import { MONDAY_LOGO_SMALL_SRC } from "constants/links";
import PictureComponent from "components/core/picture/picture-component";
import { EMBEDDED_BOARD_INITIAL_INTERACTION } from "/constants/bigbrain-event-types";
import TooltipComponent from "components/enhancements/tooltip/tooltip-component";
import { DARK_THEME } from "components/enhancements/tooltip/tooltip-constants";
import { getCookie } from "/services/cookies-service";
import { ALWAYS_FS } from "constants/cookies";
import { addParamToURL, getUrlParamValue, getLastPartOfUrl } from "utils/url";

const IFRAME_CLASS_NAME = "board-iframe";
const FULL_STORY_EMBEDDED_PARAM = "fullstory";

const PLATFORM_REGION_PARAM_NAME = "r";
const IS_LIVE_BOARD_PLATFORM_PARAM = "live_board";

export default class EmbeddedBoard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showTooltip: !!props.tooltipText };
  }

  componentDidMount() {
    window.addEventListener("blur", this.blurAction);
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.blurAction);
  }

  getBoardEmbeddedURL = () => {
    const { sharableLink, boardIdAndToken, isLiveBoard } = this.props;
    let boardIdAndTokenProp = boardIdAndToken; // Old prop support
    let region;
    if (sharableLink) {
      region = getUrlParamValue(sharableLink, PLATFORM_REGION_PARAM_NAME);
      boardIdAndTokenProp = getLastPartOfUrl(sharableLink);
    }

    let url = `https://view.monday.com/embed/${boardIdAndTokenProp}?hide_footer=true&allow_edit=true`;
    if (region) {
      url = addParamToURL(url, PLATFORM_REGION_PARAM_NAME, region);
    }
    if (isLiveBoard) {
      url = addParamToURL(url, IS_LIVE_BOARD_PLATFORM_PARAM, "true");
    }
    return url;
  };

  getIframeUrl = () => {
    let url = this.getBoardEmbeddedURL();
    const fullStoryActiveForPage = !!getCookie(ALWAYS_FS);

    if (fullStoryActiveForPage) {
      url = addParamToURL(url, FULL_STORY_EMBEDDED_PARAM, true);
    }
    return url;
  };

  blurAction = () => {
    if (document.getElementsByClassName(IFRAME_CLASS_NAME)[0] === document.activeElement) {
      const { boardIdAndToken } = this.props;
      BigBrain("track", EMBEDDED_BOARD_INITIAL_INTERACTION, { info1: boardIdAndToken });
      this.setState({ showTooltip: false });
      window.removeEventListener("blur", this.blurAction);
    }
  };

  renderTooltipIfNeeded = () => {
    const { showTooltip } = this.state;
    const { tooltipText } = this.props;

    if (!showTooltip) return null;

    return (
      <div className="tooltip-wrapper">
        <TooltipComponent theme={DARK_THEME}>{tooltipText}</TooltipComponent>
      </div>
    );
  };

  render() {
    const { hideTopFrame } = this.props;

    return (
      <div className="embedded-board">
        {this.renderTooltipIfNeeded()}

        <div className="embedded-wrapper">
          {!hideTopFrame && (
            <div className="embedded-board-top-frame">
              <div className="powered-by-wrapper">
                <span>Powered by</span>
                <PictureComponent src={MONDAY_LOGO_SMALL_SRC} className="monday-logo-image" />
              </div>
            </div>
          )}
          <div className="board-iframe-content-wrapper">
            <iframe src={this.getIframeUrl()} className={IFRAME_CLASS_NAME} />
          </div>
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

EmbeddedBoard.defaultProps = DefaultProps;
