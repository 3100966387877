const getExactEventNameAbTestVariant = (abTestExactEventName, abTestVariants) => {
  if (!abTestExactEventName || !abTestVariants || abTestVariants.length == 0) return null;

  const test = abTestVariants.find((abTest) => abTest.name === abTestExactEventName);
  return test ? test.variant : null;
};

module.exports = {
  getExactEventNameAbTestVariant
};
