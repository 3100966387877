import { useState } from "react";
import TabsComponent from "components/core/tabs/tabs-component";
import TabComponent from "components/core/tabs/tab/tab-component";
import AssetComponent from "components/core/asset/asset-component";
import classnames from "classnames";
import { componentStyles } from "./board-views-tab.scss";
import DefaultProps from "./default-props";

const BoardViewsTab = (props) => {
  const { distanceFromTop, activeTabId, fullSizeContent, tabsRenderer, onTabClick } = props;
  const [animationActive, setAnimationActive] = useState(true);

  const onTabClickCallBack = (index) => {
    setAnimationActive(false);
    setTimeout(() => setAnimationActive(true), 0);
    if (onTabClick) {
      onTabClick(index);
    }
  };

  const defaultTabsRenderer = () => {
    const { tabs, theme } = props;

    return tabs?.map((tab, index) => {
      const { tabLabel, asset, tabId, image } = tab;

      return (
        <TabComponent
          tabId={tabId}
          label={tabLabel}
          color={theme}
          key={index}
          useXSMLabels={true}
          tabIcon={image}
          tabsWithIcons={true}
        >
          <div className={classnames("asset-wrapper", { "animation-active": animationActive })}>
            <AssetComponent {...asset} key={index} />
          </div>
        </TabComponent>
      );
    });
  };

  return (
    <div className="board-views-tab-component">
      <div className={classnames("tabs-component-wrapper", { "full-size-content": fullSizeContent })}>
        <TabsComponent
          onTabClick={onTabClickCallBack}
          distanceFromTop={distanceFromTop}
          activeTabId={activeTabId}
          autoTransition={false}
          role="tabpanel"
        >
          {tabsRenderer ? tabsRenderer() : defaultTabsRenderer()}
        </TabsComponent>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

BoardViewsTab.defaultProps = DefaultProps;
export default BoardViewsTab;
