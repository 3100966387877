import {
  CRM,
  DESIGN_AND_CREATIVE,
  GENERIC_CLUSTER,
  HR,
  IT,
  MARKETING,
  OPERATIONS,
  PROJECT_MANAGEMENT_USE_CASE,
  SOFTWARE
} from "constants/clusters";

import {
  CRM_GREEN_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  IRIS_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME
} from "styles/color-consts";

export const starsClusters = [
  {
    text: "Creative & design",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/design_icon.png",
    colorName: "sofia-pink",
    clusterId: DESIGN_AND_CREATIVE
  },
  {
    text: "IT",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/it_icon.png",
    colorName: "purple-v2",
    clusterId: IT
  },
  {
    text: "Software development",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/dev_icon.png",
    colorName: DEV_GREEN_COLOR_NAME,
    clusterId: SOFTWARE
  },
  {
    text: "Marketing",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/marketing_icon.png",
    colorName: MARKETING_RED_COLOR_NAME,
    clusterId: MARKETING
  },
  {
    text: "Project management",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/pmo_icon.png",
    colorName: PROJECTS_ORANGE_COLOR_NAME,
    clusterId: GENERIC_CLUSTER,
    useCaseId: PROJECT_MANAGEMENT_USE_CASE
  },
  {
    text: "Sales & CRM",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/crm_icon.png",
    colorName: CRM_GREEN_COLOR_NAME,
    clusterId: CRM
  },
  {
    text: "Task management",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/task_icon.png",
    colorName: "light-blue-v2",
    clusterId: GENERIC_CLUSTER
  },
  {
    text: "HR",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/hr_icon.png",
    colorName: "sunset",
    clusterId: HR
  },
  {
    text: "Operations",
    image:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/first_fold/icons/operations_icon.png",
    colorName: "aquamarine",
    clusterId: OPERATIONS
  }
];

export default {
  tagsWidth: "140px",
  clusters: starsClusters,
  buttonConfig: {
    buttonColor: IRIS_COLOR_NAME
  }
};
