import css from "styled-jsx/css";
import { ALWAYS_ON_TOP } from "constants/z-index";
import Constants from "constants/main-signup-popup";

const popUpMarginTop = Constants.MAIN_POPUP_HEIGHT / 2;
const popUpMarginLeft = Constants.MAIN_POPUP_WIDTH / 2;

export const componentStyles = css.global`
  .signup-popup-modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  .signup-popup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${ALWAYS_ON_TOP};
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }
`;
