import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import PhoneInputComponent from "react-phone-input-2";
import { componentStyles } from "./phone-input.scss";
import { commonInputStyles } from "../common-inputs.scss";
import { phoneInputStyles } from "./phone-input-component.scss";

class PhoneInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { inputData, errorState, onInputChange, onInputBlur, value, cloudfrontViewerCountry, id } = this.props;
    const { inputName, isRequired } = inputData;
    const { isValid } = errorState;

    const showRequiredError = !isValid && isRequired;

    const defaultCountry = (cloudfrontViewerCountry && cloudfrontViewerCountry.toLowerCase()) || "us";

    return (
      <div className="hp-input hp-phone-input" id={id}>
        <PhoneInputComponent
          country={defaultCountry}
          value={value}
          onChange={(phone) => onInputChange({ target: { name: inputName, value: phone } })}
          onBlur={() => onInputBlur({ target: { name: inputName, value } })}
          name={inputName}
          enableSearch={true}
          disableSearchIcon={true}
          inputClass={showRequiredError ? "input-required" : ""}
          specialLabel={""}
        />

        <style jsx>{phoneInputStyles}</style>
        <style jsx>{commonInputStyles}</style>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

PhoneInput.defaultProps = {};

export default withGenericConfig(PhoneInput);
