import css from "styled-jsx/css";

export default css.global`
  .youtube-video-component {
    .video-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .content {
        display: flex;
        iframe {
          display: block;
        }
        &.with-shadow {
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
`;
