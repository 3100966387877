import { useEffect, useCallback } from "react";
import FullstoryScript from "/components/core/head/fullstory-scripts/fullstory-script";
import TrustpilotScript from "/components/core/head/seo-scripts/trustpilot-script";
import IntercomScript from "/components/core/head/intercom-scripts/intercom-script";
import { initBodyScripts } from "services/body-scripts-service";
import { withAbTests } from "/contexts/ab-tests-context";
import { withGenericConfig } from "/contexts/generic-config-context";
import GoogleTagManagerComponent from "/components/core/google-tag-manager/google-tag-manager-component";
import DriftScript, { loadDriftChatbot } from "/components/core/head/drift-script";
import HotjarScript from "/components/core/head/hotjar-script";
import { useCompanyData } from "client/services/enrichment/company-data";
import { isBigCompany, isSMBOrBigCompany } from "client/services/enrichment/clearbit-selectors";
import { trackEvent } from "/services/bigbrain-service";
import {
  ISRAEL_ISO2,
  EUROPE,
  CANADA_ISO2,
  UNITED_STATES_ISO2,
  getCountriesByContinent
} from "services/countries/countries-service";
import { shouldRenderDriftScriptCrmLpAbTest } from "services/ab-tests/acquisition-ab-test-service";
import { RETRIEVED_404_ERROR } from "constants/bigbrain-event-types";
import { withRouter } from "next/router";
import { currentSmallerThanFinalDate } from "client/services/hotjar-service/hotjar-service";

const isProduction = process.env.NODE_ENV === "production";
const HotjarWithAbTests = withAbTests(HotjarScript);

const BodyScriptsComponent = (props) => {
  const {
    abTests,
    withFullstory,
    fullstoryRecordingFrequency,
    showTrustpilotReviews,
    withIntercom,
    disableGTM,
    gtmContainerId,
    enableDriftChatBot,
    enableHotjar,
    hotjarFinalDate,
    clusterId,
    hasFreeTier,
    localeId,
    subClusterId,
    loggedInUser,
    driftDelayedLoadMilliseconds,
    driftOpenChatDialogOnLaunch,
    driftPreventLoadOnLaunch,
    cloudfrontViewerCountry,
    loadCompanyDetails,
    is404Page,
    router
  } = props;

  useEffect(() => {
    initBodyScripts({
      abTests
    });
  }, [abTests]);

  const companyData = useCompanyData();

  const isCountrySupportedForChatbot = useCallback(() => {
    if (
      cloudfrontViewerCountry === ISRAEL_ISO2 ||
      cloudfrontViewerCountry === UNITED_STATES_ISO2 ||
      cloudfrontViewerCountry === CANADA_ISO2
    )
      return true;

    return getCountriesByContinent(EUROPE).includes(cloudfrontViewerCountry);
  }, [cloudfrontViewerCountry]);

  useEffect(() => {
    if (loadCompanyDetails && enableDriftChatBot && driftPreventLoadOnLaunch && isCountrySupportedForChatbot()) {
      trackEvent("clearbit_drift_company_data_loaded", {
        data: companyData,
        info1: enableDriftChatBot,
        info2: isBigCompany(companyData)
      });
      if (
        localStorage?.getItem("loadDrift") === "true" ||
        (isSMBOrBigCompany(companyData) && shouldRenderDriftScriptCrmLpAbTest(abTests, true))
      ) {
        loadDriftChatbot();
      }
    }
  }, [
    abTests,
    companyData,
    enableDriftChatBot,
    isCountrySupportedForChatbot,
    loadCompanyDetails,
    driftPreventLoadOnLaunch
  ]);

  useEffect(() => {
    if (is404Page) {
      const href = window?.location?.href;
      const referrer = document?.referrer;
      trackEvent(RETRIEVED_404_ERROR, {
        kind: href,
        info1: referrer
      });
    }
  }, [is404Page]);

  const loggedInUserToUse = loggedInUser || {};

  const shouldAddHotjarScript = () => {
    return enableHotjar && currentSmallerThanFinalDate(hotjarFinalDate);
  };

  return (
    <>
      {isProduction && (
        <>
          {!disableGTM && (
            // This MUST be rendered in the body so componentDidMount will run
            <GoogleTagManagerComponent loadBodyScript={true} gtmId={gtmContainerId} />
          )}
          {withFullstory && (
            <FullstoryScript
              clusterId={clusterId}
              subClusterId={subClusterId}
              fullstoryRecordingFrequency={fullstoryRecordingFrequency}
              hasFreeTier={hasFreeTier}
              localeId={localeId}
            />
          )}
          {showTrustpilotReviews && <TrustpilotScript />}
          {withIntercom && (
            <IntercomScript
              userData={{
                userId: loggedInUserToUse.mondayUserId || loggedInUserToUse.id,
                name: loggedInUserToUse.name,
                email: loggedInUserToUse.email,
                additionalData: loggedInUserToUse.intercomData
              }}
              isProduction={isProduction}
            />
          )}
          {enableDriftChatBot && (
            <DriftScript
              Cluster={clusterId}
              subClusterId={subClusterId}
              shouldShowOnLoad={!driftPreventLoadOnLaunch}
              driftDelayedLoadMilliseconds={driftDelayedLoadMilliseconds}
              driftOpenChatDialogOnLaunch={driftOpenChatDialogOnLaunch}
            />
          )}
          {shouldAddHotjarScript() && <HotjarWithAbTests />}
        </>
      )}
    </>
  );
};

export default withGenericConfig(withAbTests(withRouter(BodyScriptsComponent)));

BodyScriptsComponent.defaultProps = {
  withFullstory: false,
  showTrustpilotReviews: false,
  withIntercom: false,
  fontLazyLoad: false,
  disableGTM: false
};
