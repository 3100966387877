import css from "styled-jsx/css";
import Colors from "/styles/colors";

export const commonInputStyles = css.global`
  .hp-input {
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
    border-color: ${Colors["wolf"]};
    padding: 8px;
    transition: border-color 0.1s ease;
    width: 100%;

    &.input-required {
      position: relative;
      border-color: ${Colors["brand-red"]};
    }

    &:focus {
      border-color: ${Colors["basic-blue"]};
    }
  }
`;
