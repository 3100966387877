import { useState, useEffect } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import { MAIN_ID, FOOTER_ID } from "constants/element-ids";
import { componentStyles } from "./bypass-block-menu.scss";

const BypassBlocksMenuComponent = (props) => {
  const [hasFooter, setHasFooter] = useState(false);

  useEffect(() => {
    const footer = document.querySelector(FOOTER_ID);
    setHasFooter(!!footer);
  });

  return (
    <nav className="bypass-block-menu-component" aria-label="Accessibility Menu">
      <a href={`#${MAIN_ID}`} className="menu-item">
        {props.translate("accessibility.blocksMenu.skipToMain")}
      </a>
      {hasFooter && (
        <a href={`#${FOOTER_ID}`} className="menu-item">
          {props.translate("accessibility.blocksMenu.skipToFooter")}
        </a>
      )}
      <style jsx>{componentStyles}</style>
    </nav>
  );
};

export default withGenericConfig(BypassBlocksMenuComponent);
