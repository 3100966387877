import { getGridImageWithParagraphProps } from "../live-board-tabs-service";

const firstRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/invite/invite_image.png",
      fullParagraphTitle: "invite - row: 1"
    }),
    cardNumOfColumnsWidth: 4
  }
];

const secondRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/invite/1_invite_card.png",
      fullParagraphTitle: "invite - row: 2 col: 1"
    }),
    cardNumOfColumnsWidth: 2
  },
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/invite/2_invite_card.png",
      fullParagraphTitle: "invite - row: 2 col: 2"
    }),
    cardNumOfColumnsWidth: 2
  }
];

export const inviteGridItems = [firstRow, secondRow];
