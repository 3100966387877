import { getGridImageWithParagraphProps } from "../live-board-tabs-service";

export const TOP_VIDEO_PROPS = {
  video: {
    src: {
      singleUrl:
        "https://dapulse-res.cloudinary.com/video/upload/Generator_featured%20images/live-board-2022/automations/automation.mp4"
    },
    autoPlay: true,
    playWhenVisible: false,
    loop: true
  }
};

const firstRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/1_card_automation.png",
      fullParagraphTitle: "automations - row: 1 col: 1"
    }),
    cardNumOfColumnsWidth: 1.33
  },
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/2_card_automation.png",
      fullParagraphTitle: "automations - row: 1 col: 2"
    }),
    cardNumOfColumnsWidth: 1.33
  },
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/3_card_automation.png",
      fullParagraphTitle: "automations - row: 1 col: 3"
    }),
    cardNumOfColumnsWidth: 1.33
  }
];

const secondRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/4_card_automation.png",
      fullParagraphTitle: "automations - row: 2 col: 1"
    }),
    cardNumOfColumnsWidth: 1.33
  },
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/5_card_automation.png",
      fullParagraphTitle: "automations - row: 2 col: 2"
    }),
    cardNumOfColumnsWidth: 1.33
  },
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/automations/6_card_automation.png",
      fullParagraphTitle: "automations - row: 2 col: 3"
    }),
    cardNumOfColumnsWidth: 1.33
  }
];

export const automationsGridItems = [firstRow, secondRow];
