import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .responsive-hoc-component {
    .desktop-wrapper {
      display: block;
    }

    .mobile-wrapper {
      display: none;
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      .desktop-wrapper {
        display: none;
      }

      .mobile-wrapper {
        display: block;
      }
    }
  }
`;
