import {
  CLOUDINARY_IMAGE_PREFIX,
  FALLBACK_DEFAULT_IMAGE_TRANSFORMATION_PARAM
} from "/services/community/cloudinary-service";
const FALLBACK_IMAGE_FILENAME = "default.png"; // if you don't add this file to your path, you'll have weird "?" image instead in case of missing image
const env = process.env.NODE_ENV;

export const getImageUrl = (folderPath, fileName) => {
  const folderPathForEnv = `${folderPath}/${env}`;
  const transformation = `${FALLBACK_DEFAULT_IMAGE_TRANSFORMATION_PARAM}_${folderPathForEnv.replace(
    /\//g,
    ":"
  )}:${FALLBACK_IMAGE_FILENAME},q_100`;
  return `${CLOUDINARY_IMAGE_PREFIX}upload/${transformation}/${folderPathForEnv}/${fileName}`;
};
