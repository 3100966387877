import { PureComponent } from "react";
import Button from "/components/core/button/button-component";
import EmailInput from "/components/core/inputs/email-input-component";
import { componentStyles } from "./email-button.scss";
import DefaultProps from "./default-props";
import classnames from "classnames";
import withErrorHandler from "/components/enhancements/input-error-handler/input-error-handler";
import { withGenericConfig } from "/contexts/generic-config-context";

const DEFAULT_CTA_KEY = "signup.cta";

class EmailButton extends PureComponent {
  constructor(props) {
    super(props);

    this._emailInput = null;
    this.updateEmail = this.updateEmail.bind(this);
    this.handleOnClickIfNeeded = this.handleOnClickIfNeeded.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    const { focusOnLoad, isFirstSegment, isPreview } = this.props;
    if (isPreview) return;

    const shouldFocus = focusOnLoad || isFirstSegment;
    shouldFocus && this.focusOnInput();
  }

  updateEmail(event) {
    const { errorStateCallback, onEmailChange } = this.props;
    errorStateCallback(false);
    onEmailChange(event.target.value);
  }

  focusOnInput = () => {
    this._emailInput && this._emailInput.focus();
  };

  getButtonText() {
    const { buttonText, translate } = this.props;
    return buttonText || translate(DEFAULT_CTA_KEY); //removed from default props because of localization
  }

  getPlaceholderText() {
    const { placeholder, translate } = this.props;
    return placeholder || translate("signup.emailInputPlaceholder");
  }

  onClick() {
    const { errorStateCallback, onButtonClick, emailValue } = this.props;

    if (!emailValue) {
      BigBrain("track", CLICKED_SIGNUP_WITHOUT_INSERTING_EMAIL);
    }

    if (!this._emailInput.isValid()) {
      errorStateCallback(true);
      return;
    }

    onButtonClick();
  }

  handleOnClickIfNeeded(event) {
    if (event.key === "Enter") {
      this.onClick();
      event.preventDefault();
    }
  }

  render() {
    const {
      emailValue,
      buttonColor,
      buttonSize,
      isErrorState,
      className,
      borderColor,
      disableButtonAnimation,
      placeholderFocusColor,
      withButtonArrow,
      buttonSpinnerColor,
      isLoading,
      noticeText
    } = this.props;

    const buttonTextToUse = this.getButtonText();
    const placeholderToUse = this.getPlaceholderText();
    const clickAction = this.onClick;

    return (
      <div className={classnames("email-button", className, { error: isErrorState })}>
        <div className="email-button-inner">
          <EmailInput
            className="email-button-input"
            ref={(el) => (this._emailInput = el)}
            value={emailValue}
            onChange={this.updateEmail}
            placeholder={placeholderToUse}
            onKeyPress={this.handleOnClickIfNeeded}
            autoComplete={"on"}
            placeholderFocusColor={placeholderFocusColor}
            style={{ borderColor }}
          />

          <div className="email-button-wrapper">
            <Button
              className="email-button-class"
              color={buttonColor}
              size={buttonSize}
              isLoading={isLoading}
              onClickCallback={clickAction}
              disableAnimation={disableButtonAnimation}
              dataSpinnerColor={buttonSpinnerColor}
            >
              <span className="button-text">{buttonTextToUse}</span>
              {withButtonArrow && (
                <span className="email-button-arrow-wrapper">
                  <RightArrow />
                </span>
              )}
            </Button>
          </div>
        </div>

        {noticeText && <div className="notice-text-wrapper">{noticeText}</div>}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

EmailButton.defaultProps = DefaultProps;
export default withErrorHandler(withGenericConfig(EmailButton));
