import { IPO_CAMPAIGN_ID } from "constants/sales/campaigns";
import {
  getHubspotHowCanHelpInput,
  HUBSPOT_COMPANY_INPUT,
  HUBSPOT_COMPANYSIZE_INPUT,
  HUBSPOT_EMAIL_INPUT,
  HUBSPOT_FIRSTNAME_INPUT,
  HUBSPOT_HOWPLANUSE_INPUT,
  HUBSPOT_JOBTITLE_INPUT,
  HUBSPOT_LASTNAME_INPUT,
  HUBSPOT_PHONE_INPUT
} from "./hubspot-inputs";

export const PORTAL_ID = "5945317";
export const TEST_PORTAL_ID = "22796107";

export const CONTACT_SALES_HUBSPOT_FORM_ID = "215c1dc9-0c3c-44ad-be61-add5f71dfe22";
export const REQUEST_A_DEMO_HUBSPOT_FORM_ID = "1027e76f-6a94-489b-8ee3-e6d8141cc0f6";
export const REPORT_FORM_ID = "1091544b-bff2-40ef-aada-8acfea9089b3";

// used for testing only
export const TEST_CONTACT_SALES_HUBSPOT_FORM_ID = "6fe93320-3033-4c28-9215-c19b535ebcae";

const getOverrideParamsForCampaigin = (campaignId) => {
  if (campaignId === IPO_CAMPAIGN_ID) {
    return {
      titleKey: "contactSales.hubspotForm.form.ipoCampaignFieldTitle.howCanHelp",
      placeholderKey: "contactSales.hubspotForm.form.ipoCampaignFieldPlaceholder.howCanHelp"
    };
  }

  return null;
};

const getContactSalesConfig = ({ formId, campaignId, withSubmitNotice, formTitle, lastNameFirst }) => {
  const overrideParams = getOverrideParamsForCampaigin(campaignId);

  const getFirstNameLastNameRow = () => {
    const row = [HUBSPOT_FIRSTNAME_INPUT, HUBSPOT_LASTNAME_INPUT];
    return lastNameFirst ? row.reverse() : row;
  };

  switch (formId) {
    case CONTACT_SALES_HUBSPOT_FORM_ID:
    case TEST_CONTACT_SALES_HUBSPOT_FORM_ID:
      return {
        formTitle,
        rows: [
          getFirstNameLastNameRow(),
          [HUBSPOT_EMAIL_INPUT, HUBSPOT_JOBTITLE_INPUT],
          [HUBSPOT_PHONE_INPUT],
          [HUBSPOT_COMPANY_INPUT, HUBSPOT_COMPANYSIZE_INPUT],
          [getHubspotHowCanHelpInput(overrideParams)]
        ],
        submitButtonText: withSubmitNotice
          ? "contactSales.hubspotForm.form.submitButton"
          : "contactSales.hubspotForm.form.contactUsButton"
      };
    case REQUEST_A_DEMO_HUBSPOT_FORM_ID:
      return {
        rows: [
          getFirstNameLastNameRow(),
          [HUBSPOT_EMAIL_INPUT, HUBSPOT_JOBTITLE_INPUT],
          [HUBSPOT_PHONE_INPUT],
          [HUBSPOT_COMPANY_INPUT, HUBSPOT_COMPANYSIZE_INPUT],
          [HUBSPOT_HOWPLANUSE_INPUT]
        ]
      };
    case REPORT_FORM_ID:
      return {
        rows: [
          getFirstNameLastNameRow(),
          [HUBSPOT_EMAIL_INPUT, HUBSPOT_JOBTITLE_INPUT],
          [HUBSPOT_PHONE_INPUT],
          [HUBSPOT_COMPANY_INPUT, HUBSPOT_COMPANYSIZE_INPUT]
        ],
        submitButtonText: "contactSales.hubspotForm.form.getReport"
      };
    default:
      return { rows: [] };
  }
};

export const getContactFormConfigById = ({
  formId,
  overrideCampaignId = null,
  withSubmitNotice = true,
  formTitle,
  lastNameFirst
}) => {
  return getContactSalesConfig({ formId, overrideCampaignId, withSubmitNotice, formTitle, lastNameFirst });
};
