import { PureComponent } from "react";

export default class Polyfills extends PureComponent {
  renderClosestPolyfill = () => {
    return `
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                    Element.prototype.webkitMatchesSelector;
        }
        
      if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
          var el = this;
      
          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
      }`;
  };

  //to add a new polyfill just add a new script tag inside the fragment
  render() {
    return (
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: this.renderClosestPolyfill()
          }}
        />
      </>
    );
  }
}
