import css from "styled-jsx/css";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import { hexToRgba } from "utils/general";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .square-tags {
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    .clusters-stars {
      margin-top: 48px;
      margin-bottom: 56px;
      display: flex;

      .star-wrapper {
        width: 140px;
        position: relative;
        user-select: none;
        max-width: 170px;
        min-width: 140px;
      }
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;

      .signup-button-wrapper {
        transform: scale(1);

        button.signup-button {
          background-image: var(--background);
          color: var(--text-color);
          &:hover {
            background-image: var(--background-hover);
          }
          &:disabled {
            background-image: var(--background);
            color: var(--text-color);
          }
        }

        &.cluster-selected {
          animation: breatheOut 0.3s linear;
        }

        .sign-up-combo-with-text-underline {
          .sign-up-combo-wrapper {
            .signup-combo-buttons-button {
              justify-content: center;
              .secondary-button-wrapper {
                .secondary-button {
                  .with-underline {
                    background: unset;
                    .secondary-button-text {
                      padding-left: 8px;
                    }
                  }
                }
                &:hover {
                  .secondary-button {
                    .with-underline {
                      .secondary-button-text {
                        transition: color 0.15 ease-in-out;
                        color: ${hexToRgba(Colors[WHITE_COLOR_NAME], 0.8)};
                      }
                      svg path {
                        transition: fill 0.15 ease-in-out;
                        fill: ${hexToRgba(Colors[WHITE_COLOR_NAME], 0.8)};
                      }
                    }
                  }
                }
              }
            }
          }
          .text-wrapper {
            margin-top: 32px;
            font-size: 0.8125rem;
            text-align: center;
            padding-left: 3px;
          }
        }
      }
    }
  }

  @keyframes breatheOut {
    0% {
      transform: scale(1);
    }

    15% {
      transform: scale(1.01);
    }

    20% {
      transform: scale(1.02);
    }

    25% {
      transform: scale(1.03);
    }

    32% {
      transform: scale(1.04);
    }

    50% {
      transform: scale(1.05);
    }

    68% {
      transform: scale(1.04);
    }

    75% {
      transform: scale(1.03);
    }

    80% {
      transform: scale(1.02);
    }

    85% {
      transform: scale(1.01);
    }

    100% {
      transform: scale(1);
    }
  }
`;
