import { PureComponent } from "react";
import ButtonComponent from "components/core/new-button/button-component";
import { buttonStyles } from "segments/button-service";
import { REGULAR_BUTTON_CLICKED } from "/constants/bigbrain-event-types";
import classnames from "classnames";
import { DODGER_BLUE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { withAbTests } from "contexts/ab-tests-context";
import { MONDAY_AUTH_DOMAIN } from "constants/links";
import { withGenericConfig } from "/contexts/generic-config-context";
import { getLastPartOfUrl } from "utils/url";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import { trackEvent } from "/services/bigbrain-service";
import { componentStyles } from "./regular-button.scss";
import DefaultProps from "./default-props";

const isSeoFriendlyUrl = (url) => {
  let res = false;
  if (url && url.indexOf(MONDAY_AUTH_DOMAIN) < 0) {
    res = true;
  }
  return res;
};

class RegularButton extends PureComponent {
  constructor(props) {
    super(props);
    this.isSeoFriendlyUrl = isSeoFriendlyUrl(props.url);
  }

  onClick = (e) => {
    const { buttonText, url, isOpenLinkInNewTab, onClick, extraEventData } = this.props;
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    trackEvent(REGULAR_BUTTON_CLICKED, {
      kind: buttonText,
      info1: isKeyboardEvent ? "keyboard" : "mouse",
      info2: url,
      info3: getLastPartOfUrl(url),
      data: extraEventData
    });

    if (onClick) {
      onClick(e);
    }

    if (url && !this.isSeoFriendlyUrl) {
      if (isOpenLinkInNewTab) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  };

  getButtonBackgroundColor = (color) => {
    if (color === DODGER_BLUE_COLOR_NAME) {
      return WORK_OS_IRIS_COLOR_NAME;
    }

    return color;
  };

  getButtonStrippedText = () => {
    const { buttonText } = this.props;
    const disallowedButtonChars = [/\s*>/g];
    let strippedButtonText = buttonText;

    disallowedButtonChars.forEach((ending) => {
      strippedButtonText = strippedButtonText.replace(ending, "");
    });
    return strippedButtonText;
  };

  render() {
    const {
      buttonSize,
      color,
      outline,
      isWithArrow,
      url,
      isOpenLinkInNewTab,
      additionalText,
      additionalTextPosition,
      additionalTextMaxWidth,
      className
    } = this.props;
    const buttonStyle = buttonStyles[color] || {};

    const { textColor, backgroundColorName } = buttonStyle;
    const buttonColor = this.getButtonBackgroundColor(backgroundColorName);
    const buttonStrippedText = this.getButtonStrippedText();

    return (
      <div
        className={classnames(
          "regular-button",
          buttonSize,
          additionalText && `additional-text additional-text-${additionalTextPosition}`
        )}
      >
        <ButtonComponent
          seoFriendlyUrl={this.isSeoFriendlyUrl && url}
          isOpenLinkInNewTab={isOpenLinkInNewTab}
          size={buttonSize}
          onClick={this.onClick}
          color={buttonColor}
          textColor={textColor}
          outline={outline}
          isWithArrow={isWithArrow}
          className={className}
        >
          {buttonStrippedText}
        </ButtonComponent>
        {additionalText && (
          <div
            className={classnames("additional-button-text", this.additionalTextPosition)}
            style={{ maxWidth: additionalTextMaxWidth }}
          >
            <MondayMarkupLanguageComponent text={additionalText} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

RegularButton.defaultProps = DefaultProps;
export default withGenericConfig(withAbTests(RegularButton));
