import css from "styled-jsx/css";

export default css`
  .youtube-video-wrapper {
    background-color: rgba(255, 255, 255, 0.7);

    :global(span) {
      display: flex;
    }
  }
`;
