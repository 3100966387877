import { PureComponent } from "react";
import { withRouter } from "next/router";
import { submitHubspotForm } from "client/services/hubspot-service/hubspot-service";
import { withGenericConfig } from "/contexts/generic-config-context";
import {
  SUBSCRIBE_BUTTON_BIGBRAIN_EVENT_NAME,
  SUBSCRIBE_BUTTON_RENDERED,
  CLICKED_SUBSCRIBE_BUTTON,
  SUBSCRIPTION_REQUESTED,
  SUBSCRIPTION_SUCCESS
} from "/constants/bigbrain-event-types";
import EmailButtonComponent from "segments/desktop/core-components/button/email-button/email-button.js";
import { PORTAL_ID } from "client/services/hubspot-service/contact-form-config";
import DefaultProps from "./default-props";
import { componentStyles } from "./subscribe-button.scss";

const FORM_GUID = "e13c9bb3-29ee-4ef1-b5b9-78d946c95502";

class SubscribeButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isButtonLoading: false,
      subscribed: false,
      emailValue: ""
    };
  }

  componentDidMount() {
    this.trackEvent(SUBSCRIBE_BUTTON_RENDERED);
  }

  trackEvent(eventName) {
    const { router } = this.props;
    BigBrain("track", SUBSCRIBE_BUTTON_BIGBRAIN_EVENT_NAME, {
      kind: eventName,
      placement: router.asPath
    });
  }

  onButtonClick = async () => {
    const { subscribed, emailValue } = this.state;

    this.trackEvent(CLICKED_SUBSCRIBE_BUTTON);

    if (!subscribed) {
      this.setState({ isButtonLoading: true });

      this.trackEvent(SUBSCRIPTION_REQUESTED);
      const res = await submitHubspotForm(PORTAL_ID, FORM_GUID, emailValue);
      if (res) {
        this.trackEvent(SUBSCRIPTION_SUCCESS);
        this.setState({ isButtonLoading: false, subscribed: true, emailValue: "" });
      }
    }
  };

  onEmailChange = (emailValue) => {
    this.setState({ emailValue, subscribed: false });
  };

  render() {
    const { isButtonLoading, subscribed, emailValue } = this.state;
    const { translate } = this.props;
    const noticeText = subscribed && !emailValue ? translate("button.thankYou") : "";

    return (
      <div className="signup-button">
        <EmailButtonComponent
          {...this.props}
          emailValue={emailValue}
          onButtonClick={this.onButtonClick}
          onEmailChange={this.onEmailChange}
          isLoading={isButtonLoading}
          borderColor="gray"
          noticeText={noticeText}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SubscribeButton.defaultProps = DefaultProps;
export default withGenericConfig(withRouter(SubscribeButton));
