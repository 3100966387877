import { componentStyles } from "./views-tab-component.scss";
import { viewsGridItems } from "./views-tab-consts.js";
import LiveBoardTabComponent from "../live-board-tab-component";
import { withGenericConfig } from "/contexts/generic-config-context";

const ViewsTabContentComponent = (props) => {
  const { translate, onCardClick } = props;
  return (
    <div className="views-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.viewsTab.description")}
        gridItems={viewsGridItems}
        onCardClick={onCardClick}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(ViewsTabContentComponent);
