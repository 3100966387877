import { componentStyles } from "./integrations-tab-component.scss";
import { integrationsGridItems } from "./integrations-tab-consts.js";
import { withGenericConfig } from "/contexts/generic-config-context";
import LiveBoardTabComponent from "../live-board-tab-component";

const IntegrationsTabContentComponent = (props) => {
  const { translate, onCardClick } = props;

  return (
    <div className="integrations-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.integrationsTab.description")}
        gridItems={integrationsGridItems}
        hideCardParagraph={true}
        onCardClick={onCardClick}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(IntegrationsTabContentComponent);
