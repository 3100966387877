import { useEffect } from "react";
import SignupPopupScreenComponent from "components/core/signups/signup-popup/signup-popup-screen-component";
import ReactModal from "react-modal";
import { componentStyles } from "./signup-popup-modal.scss";
import { withAbTests } from "contexts/ab-tests-context";
import { shouldRenderSignupPopupWithoutAssetAbTest } from "services/ab-tests/acquisition-ab-test-service";

const SignupPopupModal = (props) => {
  const { showSignupPopupModal, closeSignupPopup, translate, beforeSubmitCallback, abTests, signupWithsolutionAppReferenceId } = props;

  useEffect(() => {
    if (document?.getElementsByClassName("template-content-wrapper")?.length > 0) {
      ReactModal?.setAppElement(".template-content-wrapper");
    }
  });

  const withoutAsset = shouldRenderSignupPopupWithoutAssetAbTest(abTests) ;

  return (
    <ReactModal
      className="signup-popup-modal"
      overlayClassName="signup-popup-modal-overlay"
      isOpen={showSignupPopupModal}
    >
      <SignupPopupScreenComponent
        translate={translate}
        closePopupAction={closeSignupPopup}
        beforeSubmitCallback={beforeSubmitCallback}
        isMainPopUp={true}
        withoutAsset={withoutAsset}
        signupWithsolutionAppReferenceId={signupWithsolutionAppReferenceId}
      />
      <style jsx>{componentStyles}</style>
    </ReactModal>
  );
};

export default withAbTests(SignupPopupModal);
