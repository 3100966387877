import { GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER } from "/constants/marketing-keys";

const GoogleTagManagerComponent = ({ loadBodyScript = false, gtmId = GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER }) => {
  const getHeaderScript = () => {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`;
  };

  const getBodyNoScript = () => {
    return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  };

  return (
    <>
      {!loadBodyScript && (
        <script
          dangerouslySetInnerHTML={{
            __html: getHeaderScript()
          }}
        />
      )}
      {loadBodyScript && (
        <noscript
          dangerouslySetInnerHTML={{
            __html: getBodyNoScript()
          }}
        />
      )}
    </>
  );
};

export default GoogleTagManagerComponent;
