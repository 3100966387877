import { PREFETCH_TYPES } from "constants/prefetch-constants";
import { usePrefetchedLiveBoardAssets } from "client/services/live-board/prefetch-liveboard-service";

const LiveBoardPrefetchedAssetsLinksComponent = () => {
  const allPrefetchedLiveBoardAssets = usePrefetchedLiveBoardAssets();

  const dnsPrefetchedDomains = allPrefetchedLiveBoardAssets.filter(
    (asset) => asset.type === PREFETCH_TYPES.DNS_PREFETCH
  );
  const preConnectedDomains = allPrefetchedLiveBoardAssets.filter((asset) => asset.type === PREFETCH_TYPES.PRECONNECT);

  return [
    dnsPrefetchedDomains?.map((prefetchedDomain) => <link rel={prefetchedDomain.type} href={prefetchedDomain.href} />),
    preConnectedDomains?.map((preConnectedDomain) => (
      <link rel={preConnectedDomain.type} href={preConnectedDomain.href} />
    ))
  ];
};

export default LiveBoardPrefetchedAssetsLinksComponent;
