import css from "styled-jsx/css";

export const componentStyles = css.global`
  .flexible-cards-grid-mobile-component {
    .carousel-wrapper {
      margin-top: 30px;
      &.no-margin {
        margin-top: 0px;
      }

      width: auto;
      .carousel {
        .slick-slider {
          .slick-dots {
            bottom: 0px;
          }
        }

        .grid-paragraph-with-image-component-wrapper {
          overflow: hidden;
          border-radius: 8px;
          padding: 25px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .grid-paragraph-with-image {
            .grid-paragraph-wrapper {
              padding-right: 20px;
              padding-left: 20px;
              .full-paragraph {
                .full-paragraph-topic {
                  font-size: 0.6875rem;
                  margin-bottom: 8px;
                }
                .title-wrapper {
                  font-size: 0.875rem;
                  margin-bottom: 10px;
                }
                .paragraph-body-wrapper {
                  .paragraph-body {
                    font-size: 0.8125rem;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
