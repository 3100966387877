import { useEffect } from "react";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import CloseIcon from "static/svg/close.svg";
import { trackEvent } from "/services/bigbrain-service";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { MD, SM } from "constants/sizes";
import { SIGNUP_POPUP_OPENED } from "constants/bigbrain-event-types.js";
import PictureComponent from "/components/core/picture/picture-component";
import ImagesLogosComponent from "segments/desktop/logos/layouts/images-logos/images-logos-component";
import classnames from "classnames";
import { componentStyles } from "./signup-popup-screen-component.scss";

export const POPUP_IMAGE =
  "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/signup-popup-test/sign_up_asset.png";

const SignupPopupScreenComponent = (props) => {
  const { translate, closePopupAction, beforeSubmitCallback, isMainPopUp, withoutAsset } = props;
  useEffect(() => {
    trackEvent(SIGNUP_POPUP_OPENED);
  }, []);

  const closeModalAction = () => {
    closePopupAction && closePopupAction({ placement: "clicked_on_close_icon" });
  };

  const renderLogo = () => {
    return (
      <div className="monday-logo">
        <PictureComponent src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto,d_908/remote_mondaycom_static/img/monday-logo-x2.png" />
      </div>
    );
  };

  const renderBottomLogos = () => {
    return (
      <div className="bottom-part">
        <div className="logos-title-seperator">
          <span className="seperator-line" />
          <span className="separator-text">{translate("mainSignupPopup.logosTitle")} </span>
          <span className="seperator-line" />
        </div>
        <div className="signup-popup-logos">
          <ImagesLogosComponent logos={{ grayscale: "true" }} />
        </div>
      </div>
    );
  };

  const onKeyDown = generateOnKeyDownHandler(closeModalAction);
  const buttonSize = isMainPopUp ? MD : SM;
  const title = isMainPopUp ? translate("signup.ctaWelcomeToMonday") : translate("signup.ctaSignupForFree");
  const subtitle = isMainPopUp ? translate("mainSignupPopup.subtitle") : translate("signupPopup.subtitle");

  const renderPopUpInput = () => {
    return (
      <div className="popup-email-signup-wrapper">
        <SignupButton
          forceDefaultForm={true}
          useCtaText={true}
          ctaText={translate("signup.continueCta")}
          withButtonArrow={false}
          forceVerticalGoogleConnect={true}
          buttonSize={buttonSize}
          googleOverrideCtaKey="signup.ctaSignupWith"
          focusOnLoad={true}
          isLocatedInPopup={true}
          beforeSubmitCallback={beforeSubmitCallback}
        />
      </div>
    );
  };

  const renderCloseIcon = () => {
    return (
      <div
        className="signup-popup-close"
        onClick={closeModalAction}
        onKeyDown={onKeyDown}
        tabIndex={0}
        role="button"
        aria-label={translate("signupPopup.close")}
      >
        <CloseIcon className="close-icon" />
      </div>
    );
  };

  const renderLeftImage = () => {
    return (
      <div className="image-wrapper">
        <PictureComponent className="popup-image" src={POPUP_IMAGE} />
      </div>
    );
  };

  const renderMainPopUpWithAsset = () => {
    return (
      <div className={classnames("signup-popup-screen", { mainPopUp: true })}>
        {renderCloseIcon()}
        {renderLeftImage()}
        <div className="signup-popup-content-wrapper">
          <div className="signup-popup-content">
            <div className="screen-top-padding-div" />
            <div className="signup-popup-first-part">
              <div className="signup-popup-title">{title}</div>
              <div className="signup-popup-subtitle">{subtitle}</div>
              {renderPopUpInput()}
            </div>
            <div className="screen-bottom-padding-div" />
          </div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  const renderMainPopUpWithoutAsset = () => {
    return(
        <div className={classnames("signup-popup-screen", { mainPopUp: true })}>
          {renderCloseIcon()}
          <div className="signup-popup-content-wrapper">
            <div className="signup-popup-content">
              <div className="screen-top-padding-div" />
              <div className="signup-popup-first-part">
              {renderLogo()}
                <div className="signup-popup-title">{title}</div>
                <div className="signup-popup-subtitle">{subtitle}</div>
                {renderPopUpInput()}
              </div>
              {renderBottomLogos()}
              <div className="screen-bottom-padding-div" />
            </div>
          </div>
          <style jsx>{componentStyles}</style>
        </div>
      );
  }

  const renderHeaderPopUp = () => {
    return (
      <div className="signup-popup-screen">
        {renderCloseIcon()}
        <div className="signup-popup-content-wrapper">
          <div className="signup-popup-content">
            <div className="signup-popup-first-part">
              <div className="signup-popup-title">{title}</div>
              <div className="signup-popup-subtitle">{subtitle}</div>
              {renderPopUpInput()}
            </div>
          </div>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  };

   

  const renderPopUp = () => {
    if(isMainPopUp){
      if (withoutAsset) return renderMainPopUpWithoutAsset();
      return renderMainPopUpWithAsset();
    }else{
      return renderHeaderPopUp()
    }
  };

  return renderPopUp();
};

export default SignupPopupScreenComponent;
