import { PureComponent } from "react";
import YoutubeVideoModalComponent from "components/core/youtube-video-modal/youtube-video-modal-component";
import { YOUTUBE_MODAL_CLOSE_SECONDARY, YOUTUBE_MODAL_OPEN_SECONDARY } from "/constants/bigbrain-event-types";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import VideoPlayCoreButton from "/static/svg/videos/video-play-core-button-v4.svg";
import colors from "/styles/colors";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { REGULAR_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import ModalWithSignupDefaultProps from "components/core/modal-with-signup/default-props.js";
import { VIDEO_OPTS } from "constants/youtube-modal";
import { componentStyles } from "./youtube-popup-button.scss";
import DefaultProps from "./default-props";
import { withGenericConfig } from "contexts/generic-config-context";

const MIN_HEIGHT = 546;
const WINDOW_INITIAL_WIDTH = 780;
const WINDOW_INITIAL_HEIGHT = 928;

class YouTubePopUpButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { openModal: false, windowWidth: WINDOW_INITIAL_WIDTH, windowHeight: WINDOW_INITIAL_HEIGHT };

    this.onPlayButtonClicked = this.onPlayButtonClicked.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getVideoIconRenderer = this.getVideoIconRenderer.bind(this);
    this.onKeyDown = generateOnKeyDownHandler(this.onPlayButtonClicked);
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
  }

  onPlayButtonClicked() {
    const { videoId } = this.props;
    BigBrain("track", YOUTUBE_MODAL_OPEN_SECONDARY, { kind: videoId });
    this.setState({ windowHeight: window.innerHeight - 100, windowWidth: window.innerWidth - 100 }, () =>
      this.setState({ openModal: true })
    );
  }

  closeModal() {
    const { videoId } = this.props;
    BigBrain("track", YOUTUBE_MODAL_CLOSE_SECONDARY, { kind: videoId });
    this.setState({ openModal: false });
  }

  getVideoIconRenderer() {
    const { iconColor } = this.props;
    return (
      <span className="play-wrapper" style={{ color: colors[iconColor] }}>
        <VideoPlayCoreButton />
      </span>
    );
  }

  renderButtonComponent() {
    const { buttonDisplayType } = this.props;
    const { buttonSize, buttonText, color, isWithArrow, isWithIcon, isWithIconInsteadOfArrow } = this.props;

    const buttonProps = {
      buttonSize,
      buttonText,
      isWithArrow,
      color,
      iconRenderer: isWithIcon && this.getVideoIconRenderer,
      onClick: this.onPlayButtonClicked,
      isWithIconInsteadOfArrow
    };

    const ButtonComponent = buttonDisplayType === REGULAR_BUTTON_TYPE ? RegularButton : SecondaryButton;

    return <ButtonComponent {...buttonProps} />;
  }

  getVideoOpts = () => {
    const { windowHeight, windowWidth } = this.state;
    const { isMobileOrTabletBrowser } = this.props;

    let videoOpts = {
      ...VIDEO_OPTS,
      height: windowHeight
    };

    if (isMobileOrTabletBrowser) {
      videoOpts = { ...videoOpts, width: windowWidth };
    }
    return videoOpts;
  };

  getModalStyle = () => {
    const { isMobileOrTabletBrowser } = this.props;
    const { style } = ModalWithSignupDefaultProps;
    const minWidthProp = isMobileOrTabletBrowser ? { minWidth: "unset", minHeight: "unset" } : { minWidth: "960px" };

    return {
      ...style,
      content: {
        ...style.content,
        overflow: "visible",
        ...minWidthProp
      }
    };
  };

  render() {
    const { openModal } = this.state;
    const { videoId } = this.props;

    const videoOpts = this.getVideoOpts();
    const modalStyle = this.getModalStyle();

    return (
      <div>
        <div
          className="secondary-button-wrapper"
          tabIndex={0} // make focusable
          onKeyDown={this.onKeyDown}
          role="button"
        >
          {this.renderButtonComponent()}
        </div>
        <YoutubeVideoModalComponent
          videoId={videoId}
          minHeight={MIN_HEIGHT}
          isOpen={openModal}
          opts={videoOpts}
          onCloseModal={this.closeModal}
          withSignup={false}
          style={modalStyle}
        />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

YouTubePopUpButton.defaultProps = DefaultProps;

export default withGenericConfig(YouTubePopUpButton);
