export default {
  icons: [
    {
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/phone.png",
        alt: "Telephone icon"
      },
      title: "24/7",
      subtitle: "support anytime,<br>anywhere"
    },
    {
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/heart.png",
        alt: "Heart icon"
      },
      title: "Voted #1",
      subtitle: "Most Loved by<br>customers on G2"
    },
    {
      image: {
        src:
          "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/fast.png",
        alt: "Fast clock icon"
      },
      title: "2 hour",
      subtitle: "average<br>response time"
    }
  ]
};
