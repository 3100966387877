import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css`
  .tabs-component {
    display: flex;
    flex-direction: column;

    &.left {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    &.right {
      align-items: flex-end;
    }

    .tabs {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }

    .content {
      padding-top: 48px;
    }
  }
`;
