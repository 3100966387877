import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import { MD, XS} from "constants/sizes";

const getGridImageWithParagraphProps = (imageSrc, fullParagraphTitle, fullParagraphBody) => {
  const fullParagraph = {
    ...GridImageWithParagraph.defaultProps.fullParagraph,
    title: fullParagraphTitle,
    body: fullParagraphBody
  };

  return {
    ...GridImageWithParagraph.defaultProps,
    src: imageSrc,
    mobileImage: {src:imageSrc}, 
    fullParagraph,
    cardNumOfColumnsWidth: 1
  };
};

export default {
  shadowCards: true,
  removeShadowOnHover: false,
  gridAlignment: "center",
  gridItems: [
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png",
      "Cross-functional collab blog",
      "Look at your data differently with multiple View options from Calendar to Map."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png",
      "Relevant story/video",
      "Automate repetitive tasks and have more time for the work that motivates you."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png",
      "Productivity blog or marketing blog",
      "Get a high-level overview at a glance and make smarter decisions, faster."
    ),
    getGridImageWithParagraphProps(
      "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Grid/small-placeholder-image.png",
      "Productivity blog or marketing blog",
      "Get a high-level overview at a glance and make smarter decisions, faster."
    )
  ],
  paddingTop: "64px",
  paddingBottom: "64px",
  titleSize: XS,
  textSize: MD
};
