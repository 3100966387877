import { PureComponent } from "react";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import classnames from "classnames";
import DefaultProps from "./default-props";
import { componentStyles } from "./signup-combo-buttons-button.scss";

export default class SignupComboButtonsButton extends PureComponent {
  render() {
    const { secondaryButtonConfig, signupButtonConfig, swapButtonsOrder } = this.props;

    return (
      <div className={classnames("signup-combo-buttons-button", swapButtonsOrder && "swap-buttons-order")}>
        <div className="signup-button-wrapper">
          <SignupButton {...signupButtonConfig} />
        </div>
        <div className="secondary-button-wrapper">
          <SecondaryButton {...secondaryButtonConfig} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SignupComboButtonsButton.defaultProps = DefaultProps;
