import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";

export const getGridImageWithParagraphProps = ({
  imageSrc = null,
  fullParagraphTitle = null,
  fullParagraphBody = null,
  fullParagraphTopic = null
}) => {
  const fullParagraph = {
    ...GridImageWithParagraph.defaultProps.fullParagraph,
    topic: { text: fullParagraphTopic },
    title: fullParagraphTitle,
    body: fullParagraphBody
  };

  return {
    ...GridImageWithParagraph.defaultProps,
    src: imageSrc,
    fullParagraph
  };
};
