import { getDecodedParamFromQuery } from "utils/url";
import {
  NUMBER_OF_SEATS_PARAM,
  SALES_ENRICHMENT_PARAM_LIST,
  SALES_ENRICHMENT_PARAM_TYPES
} from "client/services/hubspot-service/hubspot-constants";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";

export const getSingleEnrichmentEntry = (paramName, hubspotApiName) => {
  const paramValue = JSON.parse(localStorage?.getItem(paramName) || "null");
  if (paramValue) {
    const { value } = paramValue;
    return { name: hubspotApiName, value };
  }
};

export const getEnrichmentEntriesForSales = () => {
  try {
    const enrichmentEntries = [];
    for (const item of SALES_ENRICHMENT_PARAM_LIST) {
      const {paramName, hubspotApiName} = item;
      const singleEnrichmentEntry = getSingleEnrichmentEntry(paramName, hubspotApiName);
      if (singleEnrichmentEntry) {
        enrichmentEntries.push(singleEnrichmentEntry);
      }
    }

    return enrichmentEntries;
  } catch (e) {
    handleClientException({ e });
    return [];
  }
};

const storeEnrichmentParamInLocalStorage = (paramName, paramValue) => {
  localStorage?.setItem(paramName, JSON.stringify({ value: paramValue }));
};

const storeUtmDataFromUrl = () => {
  const salesEnrichementUrlParamList = SALES_ENRICHMENT_PARAM_LIST.filter(
      (item) => item.type === SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM
  );
  for (const item of salesEnrichementUrlParamList) {
    const paramName = item.paramName;
    const paramValue = getDecodedParamFromQuery(window.location, paramName);
    if (paramValue) {
      storeEnrichmentParamInLocalStorage(paramName, paramValue);
    }
  }
};

export const storeNumberOfSeats = (numberOfSeats) => {
  storeEnrichmentParamInLocalStorage(NUMBER_OF_SEATS_PARAM, numberOfSeats);
};

export const StoreUTMDataForEnrichment = () => {
  try {
    storeUtmDataFromUrl();
  } catch (e) {
    handleClientException({ e });
  }
};
