const WELCOME_PAGE_PATH = "/welcome-back";
const WELCOME_PAGE_SOURCE_PARAM = "from";
const WELCOME_PAGE_SOURCE_HOMEPAGE_VALUE = "homepage";

const PLATFORM_VISITOR_AB_TEST_NAME_PARAM_NAME = "visitor_ab_test_name";
const PLATFORM_VISITOR_AB_TEST_VARIANT_PARAM_NAME = "visitor_ab_test_variant";

const WELCOME_PAGE_FULL_PATH = `/welcome-back?${WELCOME_PAGE_SOURCE_PARAM}=${WELCOME_PAGE_SOURCE_HOMEPAGE_VALUE}`;

module.exports = {
  WELCOME_PAGE_PATH,
  WELCOME_PAGE_FULL_PATH,
  WELCOME_PAGE_SOURCE_PARAM,
  WELCOME_PAGE_SOURCE_HOMEPAGE_VALUE,
  PLATFORM_VISITOR_AB_TEST_NAME_PARAM_NAME,
  PLATFORM_VISITOR_AB_TEST_VARIANT_PARAM_NAME
};
