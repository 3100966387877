import { getGridImageWithParagraphProps } from "../live-board-tabs-service";

const firstRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/notifications/Notification_1.png",
      fullParagraphTitle: "notifications - row: 1"
    }),
    cardNumOfColumnsWidth: 4
  }
];

const secondRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/notifications/Notification_2.png",
      fullParagraphTitle: "notifications - row: 2"
    }),
    cardNumOfColumnsWidth: 4
  }
];

const thirdRow = [
  {
    ...getGridImageWithParagraphProps({
      imageSrc:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/notifications/Notification_3.png",
      fullParagraphTitle: "notifications - row: 3"
    }),
    cardNumOfColumnsWidth: 4
  }
];

export const notificationsGridItems = [firstRow, secondRow, thirdRow];
