import { FLEXIBLE_GRID_CARD_CLICKED } from "/constants/bigbrain-event-types";
import GridImage from "segments/desktop/core-components/grid-image/grid-image";
import classnames from "classnames";
import Colors from "/styles/colors";
import FullParagraph from "segments/desktop/core-components/full-paragraph/full-paragraph";
import { trackEvent } from "/services/bigbrain-service";
import { componentStyles } from "./grid-paragraph-with-image.scss";
import DefaultProps from "./default-props";

const GridParagraphWithImage = (props) => {
  const {
    showParagraph,
    fullParagraph,
    titleSize,
    textSize,
    cardTagText,
    contentVerticalAlignment,
    paragraphVerticalColoredLineColor,
    padding,
    backgroundImage,
    cardColor,
    cardWidthForCss
  } = props;
  const { url, isOpenLinkInNewTab, buttonText } = fullParagraph?.buttonConfig || {};

  const handleClick = () => {
    trackEvent(FLEXIBLE_GRID_CARD_CLICKED, { kind: buttonText, info1: fullParagraph?.title, info2: url });
  };

  const getParagraphStyle = () => {
    return {
      padding
    };
  };

  const getStyle = () => {
    const color = Colors[cardColor];

    let style = { backgroundColor: color, maxWidth: cardWidthForCss };

    if (backgroundImage?.src) {
      style = {
        ...style,
        backgroundImage: `url(${backgroundImage?.src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: backgroundImage?.backgroundPosition || "center"
      };
    }
    return style;
  };

  const renderParagraph = () => {
    return (
      <>
        {showParagraph && (
          <div className={classnames("grid-paragraph-wrapper")} style={getParagraphStyle()}>
            <FullParagraph
              titleSize={titleSize}
              textSize={textSize}
              {...fullParagraph}
              paragraphVerticalColoredLineColor={paragraphVerticalColoredLineColor}
            />
            <style jsx>{componentStyles}</style>
          </div>
        )}
      </>
    );
  };

  const renderComponent = () => {
    const { shadowCards, removeShadowOnHover, src, cardTagColor, ...restImageProps } = props;
    const tagColor = Colors[cardTagColor];
    return (
      <div
        className={classnames(
          "grid-paragraph-with-image",
          {
            shadow: shadowCards,
            "without-shadow-hover": removeShadowOnHover
          },
          contentVerticalAlignment
        )}
        style={getStyle()}
      >
        {cardTagText && (
          <div className="tag" style={{ "border-color": tagColor, color: tagColor }}>
            {cardTagText}
          </div>
        )}
        {renderParagraph()}
        {src && (
          <div className="grid-image-wrapper">
            <GridImage src={src} {...restImageProps} />
          </div>
        )}

        <style jsx>{componentStyles}</style>
      </div>
    );
  };

  return (
    <a
      className={classnames("card-grid-link", { "without-link": !url })}
      href={url}
      onClick={handleClick}
      target={isOpenLinkInNewTab ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {renderComponent()}
      <style jsx>{componentStyles}</style>
    </a>
  );
};
export default GridParagraphWithImage;

GridParagraphWithImage.defaultProps = DefaultProps;
