import ReactModal from "react-modal";
import classNames from "classnames";
import { useEffect } from "react";
import { componentStyles } from "./live-board-modal.scss";
import LiveBoardScreenComponent from "./live-board-screen-component";
import { withGenericConfig } from "/contexts/generic-config-context";

const liveBoardPopupModal = (props) => {
  const {
    showLiveBoard,
    closeLiveBoard,
    translate,
    isPreview,
    embeddedBoardLink,
    boardBannerText,
    embeddedBoardTitle,
    addSecondLiveBoardSignUpButton
  } = props;

  useEffect(() => {
    document.body.classList.toggle("fullscreen-board", showLiveBoard);
  }, [showLiveBoard]);

  return (
    <ReactModal
      className={classNames("live-board-modal", {
        "fullscreen-board": showLiveBoard
      })}
      overlayClassName={classNames("live-board-modal-overlay", {
        "fullscreen-board": showLiveBoard
      })}
      bodyOpenClassName="modal-open"
      isOpen={isPreview ? false : true}
    >
      <LiveBoardScreenComponent
        translate={translate}
        closeLiveBoard={closeLiveBoard}
        showLiveBoard={showLiveBoard}
        embeddedBoardLink={embeddedBoardLink}
        boardBannerText={boardBannerText}
        embeddedBoardTitle={embeddedBoardTitle}
        addSecondLiveBoardSignUpButton={addSecondLiveBoardSignUpButton}
      />
      <style jsx>{componentStyles}</style>
    </ReactModal>
  );
};

export default withGenericConfig(liveBoardPopupModal);
