export const MOBILE_GRID_IMAGE_SIZE = 300;

export const getImageSize = (numberOfImages, isMobileOrTablet = false) => {
  if (isMobileOrTablet) return { width: MOBILE_GRID_IMAGE_SIZE, height: "auto" };

  switch (numberOfImages) {
    case 1:
      return { width: 607, height: 346 };
    case 2:
      return { width: 607, height: 346 };
    case 3:
      return { width: 400, height: 346 };
    case 4:
      return { width: 300, height: 300 };
    default:
      return { width: 300, height: 300 };
  }
};
