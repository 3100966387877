import { PureComponent } from "react";
import { ALWAYS_FS, BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
import {
  cookieValueByNameInlineScript,
  setCookieInlineScript,
  shouldRecordFromDrawInlineScript
} from "/utils/inline-scripts";
import { getExactEventNameAbTestVariant } from "services/ab-tests/lp-ab-tests-service";
import { withAbTests } from "/contexts/ab-tests-context";
import { ENGLISH_LOCALE_ID } from "/constants/localization";

const FULLSTORY_RECORDING_FOR_VISITOR = "fullstory_recording_for_visitor";
const ALWAYS_RECORD_COOKIE_EXPIRY_DAYS = 3;
const DEFAULT_RAND_RECORD = 100;

class FullstoryScript extends PureComponent {
  getFullstoryBaseCode() {
    return `
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = 'WSWD';
        window['_fs_namespace'] = 'FS';
        (function(m,n,e,t,l,o,g,y){
            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
            g.anonymize=function(){g.identify(!!0)};
            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
            g.log = function(a,b){g("log",[a,b])};
            g.consent=function(a){g("consent",!arguments.length||a)};
            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
            g.clearUserCookie=function(){};
            g.setVars=function(n, p){g('setVars',[n,p]);};
            g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
            if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
            g._v="1.3.0";
        })(window,document,window['_fs_namespace'],'script','user');`;
  }

  // Do not add dynamic attributes - runtime variables (cookies, dynamic ab test, etc...)
  // Only use params that we are cache upon
  prepareAbTestParams() {
    const { abTests } = this.props;
    const abTestsParams = {};
    abTests.forEach((abTest) => (abTestsParams[`homepage_ab_test_${abTest.name}_str`] = abTest.variant));
    return abTestsParams;
  }

  // If you need to send dynamic attributes use FS.event when needed
  createAdditionalAttributes() {
    const { abTestExactEventName, clusterId, subClusterId, hasFreeTier, localeId, abTests } = this.props;
    const abTestVariant = getExactEventNameAbTestVariant(abTestExactEventName, abTests);
    const abTestsParams = this.prepareAbTestParams();
    return {
      lp_ab_test_str: abTestVariant,
      clusterId_str: clusterId,
      subClusterId_str: subClusterId,
      hasFreeTier_bool: hasFreeTier,
      localeId_str: localeId || ENGLISH_LOCALE_ID,
      ...abTestsParams
    };
  }

  fullstoryScriptGenerator() {
    const { fullstoryRecordingFrequency, clusterId } = this.props;
    const attributes = this.createAdditionalAttributes();

    const recordEveryNth = fullstoryRecordingFrequency ? parseInt(fullstoryRecordingFrequency) : DEFAULT_RAND_RECORD;

    return `
    	try {
				if (!window.fs_appended) {
					window.fs_appended = true;

					${cookieValueByNameInlineScript}
					${setCookieInlineScript}
					${shouldRecordFromDrawInlineScript}

					const shouldAlwaysRecord = cookieValueByName('${ALWAYS_FS}');

					if (shouldAlwaysRecord || shouldRecordFromDraw(${recordEveryNth})) {
					// localstorage for testing on production

						if (!shouldAlwaysRecord) {
							//	no need to set twice
							setCookie('${ALWAYS_FS}', true, ${ALWAYS_RECORD_COOKIE_EXPIRY_DAYS});
						}

						${this.getFullstoryBaseCode()}

						visitorId = cookieValueByName('${BIG_BRAIN_VISITOR_ID}');
						FS.setUserVars({"visitorId": visitorId});
						FS.setUserVars(${JSON.stringify(attributes)});
                        const sessionUrl = FS.getCurrentSessionURL();
                        window.FSSessionUrl = sessionUrl;
                        window.FSSessionStartedAt = Date.now();
						window.BigBrain("track","${FULLSTORY_RECORDING_FOR_VISITOR}", { kind: "${
      clusterId || ""
    }", info1: "${recordEveryNth}", data: '${JSON.stringify(attributes)}' });
					}
        }
			}
			catch(e) {
			}
    `;
  }

  render() {
    const script = this.fullstoryScriptGenerator();

    return (
      <script
        async
        defer
        dangerouslySetInnerHTML={{
          __html: script
        }}
      />
    );
  }
}

export default withAbTests(FullstoryScript);
