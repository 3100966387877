import { PureComponent, Fragment } from "react";
import { commonInputStyles } from "../common-inputs.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";

class TextInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { inputData, errorState, onInputChange, onInputBlur, invalid, errorLabelId, id } = this.props;
    const { inputName, type, isRequired, placeholder } = inputData;
    const { isValid } = errorState;

    const showRequiredError = !isValid && isRequired;
    const requiredClass = {
      "input-required": showRequiredError
    };

    return (
      <Fragment>
        <input
          id={id}
          onChange={onInputChange}
          onBlur={onInputBlur}
          className={classnames("hp-input hp-text-input", requiredClass)}
          name={inputName}
          type={type}
          placeholder={placeholder}
          aria-required={isRequired}
          aria-invalid={invalid}
          aria-describedby={errorLabelId}
        ></input>
        <style jsx>{commonInputStyles}</style>
      </Fragment>
    );
  }
}

TextInput.defaultProps = {
  invalid: false,
  errorLabelId: null
};

export default withGenericConfig(TextInput);
