import { useEffect } from "react";
import Head from "next/head";
import { withGenericConfig } from "/contexts/generic-config-context";

import { withAbTests } from "contexts/ab-tests-context";
import { addGTMVariablesToWindow, updateDataLayerVariables } from "client/services/gtm-service/gtm-service";
import GoogleStructuredData from "components/core/head/google-structured-data/google-structured-data";
import { getHomepageLocalizedRoutes } from "constants/localization";
import { ROOT_LINK } from "constants/links";
import { getMetaTags } from "./meta-tags";
import { getAppIconsLinks } from "./app-icons";
import { getFacebookMetaTags } from "./social-meta-tags/facebook-meta";
import { getTwitterMetaTags } from "./social-meta-tags/twitter-meta";

import FontsComponent from "./fonts-component";
import GoogleTagManagerComponent from "/components/core/google-tag-manager/google-tag-manager-component";

import RedirectToPlatformScript from "./redirect-to-platform-script/redirect-to-platform-script";

import { normalizeUrlForCanonical, truncateQueryParams, normalizeUrl } from "/utils/url";
import SignupPrefetchedAssetsLinksComponent from "./signup-prefetched-assets-links-component";
import buildSeoFAQ from "./faq-seo/faq-seo";
import TypeformPrefetchedAssetsLinksComponent from "./typeform-prefetched-assets-links-component";
import LiveBoardPrefetchedAssetsLinksComponent from "./liveboard-prefetched-assets-links-component";

const DEFAULT_TITLE_KEY = "website.meta.title.default";
const DEFAULT_DESCRIPTION_KEY = "website.meta.description.default";

const isProduction = process.env.NODE_ENV === "production";

const HPHead = (props) => {
  const {
    clusterId,
    hasFreeTier,
    cloudfrontViewerCountry,
    disableGTM,
    description,
    template,
    localeId,
    noIndex,
    noFollow,
    gtmContainerId,
    isMobileOrTabletBrowser,
    pagePath,
    facebookMetaOverride,
    twitterMetaOverride,
    translate,
    localizedPages,
    abTests,
    translateWithParam,
    title,
    canonicalUrl,
    shouldGetSignupPrefetchedAssets,
    seoFAQ,
    shouldGetTypeformPrefetchedAssets,
    persistantCookies,
    addLiveBoardExperience
  } = props;
  const shouldAddGTM = isProduction && !disableGTM;

  useEffect(() => {
    if (shouldAddGTM) {
      addGTMVariablesToWindow({ localeId, clusterId, hasFreeTier });
      updateDataLayerVariables({ country: cloudfrontViewerCountry });
    }
  }, []);

  const getCanonicalUrl = () => {
    return normalizeUrlForCanonical(canonicalUrl || pagePath);
  };

  const getTitle = () => {
    if (typeof title === "object" && title.key && title.param) {
      return translateWithParam(title.key, title.param);
    }

    return translate(title);
  };

  const isHomepage = () => {
    try {
      if (!pagePath) return false;

      const allHomepageNormalizedRoutes = [ROOT_LINK, ...getHomepageLocalizedRoutes()];

      const normalizedPath = normalizeUrl(truncateQueryParams(pagePath));
      return allHomepageNormalizedRoutes.includes(normalizedPath);
    } catch (e) {
      // TODO: talk with Barak - sentry is a pretty big import
      return false;
    }
  };

  const translatedTitle = getTitle();
  const translatedDescription = translate(description);

  return (
    <>
      <Head>
        {!isMobileOrTabletBrowser && isHomepage() && RedirectToPlatformScript({ abTests, persistantCookies })}
        <title>{translatedTitle}</title>
        <meta name="description" content={translatedDescription} />
        <link rel="canonical" href={getCanonicalUrl()} />
        {FontsComponent()}
        {getMetaTags({ template, localeId, noIndex, noFollow })}
        {getAppIconsLinks()}
        {getFacebookMetaTags({
          title: translatedTitle,
          description: translatedDescription,
          pagePath,
          ...facebookMetaOverride
        })}
        {buildSeoFAQ({ seoFAQ, translate })}
        {getTwitterMetaTags({ title: translatedTitle, description: translatedDescription, ...twitterMetaOverride })}
        {GoogleStructuredData()}
        {shouldAddGTM && GoogleTagManagerComponent({ gtmId: gtmContainerId })}
        {localizedPages && localizedPages.map((tp) => <link rel="alternate" hrefLang={tp.locale} href={tp.path} />)}
        {shouldGetSignupPrefetchedAssets && SignupPrefetchedAssetsLinksComponent()}
        {shouldGetTypeformPrefetchedAssets && TypeformPrefetchedAssetsLinksComponent()}
        {addLiveBoardExperience && LiveBoardPrefetchedAssetsLinksComponent()}
      </Head>
    </>
  );
};

HPHead.defaultProps = {
  title: DEFAULT_TITLE_KEY,
  description: DEFAULT_DESCRIPTION_KEY,
  canonicalUrl: "",
  withIntercom: false,
  fontLazyLoad: false,
  disableGTM: false,
  facebookMetaOverride: {},
  twitterMetaOverride: {},
  localizedPages: []
};

export default withAbTests(withGenericConfig(HPHead));
