export default {
  infinite: true,
  arrows: false,
  autoplay: false,
  defaultIndex: 2,
  slidesToShow: 3,
  easing: "cubic",
  itemScale: 1,
  autoplaySpeed: 3000,
  centerMode: true,
  centerPadding: "10%",
  swipeToSlide: true,
  showFocusEffect: true,
  pauseOnFocus: true,
  lazyLoad: false,
  dots: false,
  reducedVerticalPadding: false // TODO: barben i don't know if the current 48px you added in the css is a must, but it's much more then i need
};
