import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import HeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import { MD_DESKTOP_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .signup-popup-screen {
    width: ${HeaderConstants.SIGNUP_POPUP_SCREEN_WIDTH} + "px";
    box-shadow: 0px 4px 40px rgba(29, 140, 242, 0.16);
    border-radius: 8px;
    background: ${Colors[WHITE_COLOR_NAME]};
    position: relative;

    .signup-popup-close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;

      .close-icon {
        width: 10px;
        path {
          stroke: ${Colors.asphalt};
        }
      }
    }
    .signup-popup-content-wrapper {
      .signup-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 42px 32px;
        .signup-popup-title {
          font-size: 1.375rem;
          text-align: center;
          color: ${Colors["mud"]};
          font-weight: 400;
        }

        .signup-popup-subtitle {
          margin-top: 16px;
          font-size: 0.875rem;
          line-height: 18px;
          text-align: center;
          color: ${Colors["mud"]};
        }

        .popup-email-signup-wrapper {
          margin-top: 24px;
          .error-handling-component{
            .shake{
              justify-content: center;
          }
        } 

          .signup-form-core-component-wrapper {
            min-width: 0;

            .signup-input {
              margin-right: 0;
              color: black;

              &:hover::placeholder,
              &:focus::placeholder {
                color: ${Colors["grey"]};
              }
            }
            .signup-button-wrapper {
              width: 100%;
              margin-top: 10px;

              .signup-button {
                padding-right: 0;
                padding-left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.mainPopUp {
      display: flex;
      height: 100vh;
      align-items: strech;
      .close-icon {
        margin-right: 12px;
        margin-top: 8px;
        width: 25px;
        height: 20px;
      }
      width: 100%;
      box-shadow: unset;
      .image-wrapper {
        .popup-image {
          width: 52vh;
          height: 100%;
        }
      }
      .signup-popup-content-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .signup-popup-content {
          display: flex;
          justify-content: space-between;
          .monday-logo {
            margin-bottom: 30px;
            .picture-component {
              :global(img) {
                height: 35px;
              }
            }
          }
          .signup-popup-first-part {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 13vh;
            .signup-popup-title {
              font-size: 2.7rem;
              margin-bottom: 20px;
            }
            .signup-popup-subtitle {
              font-size: 1.375rem;
              margin-bottom: 40px;
            }
            .signup-input {
              width: 300px;
              max-width: unset;
            }
          }
          .bottom-part {
            .logos-title-seperator {
              display: flex;
              align-items: center;
              .seperator-line {
                flex-grow: 1;
                border-color: gray;
                border-top-width: 0.5px;
                border-bottom-width: 0.5px;
                border-style: solid;
                opacity: 0.2;
                margin-top: 5px;
              }
              .separator-text {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 1rem;
                line-height: 24px;
                color: gray;
              }
            }
            .signup-popup-logos {
              max-width: 1200px;
              .image-gallery {
                width: 80px;
                :global(img) {
                  max-width: 60px;
                }
              }
              @media (max-width: ${MD_DESKTOP_WIDTH}) {
                .image-gallery {
                  width: 70px;
                  :global(img) {
                    max-width: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
