import { PureComponent } from "react";
import { componentStyles } from "./images-logos-component.scss";
import Logos from "segments/desktop/core-components/logos/logos";

export default class ImagesLogosComponent extends PureComponent {
  render() {
    const { logos } = this.props;

    return (
      <div className="images-logos-component">
        <Logos {...logos} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ImagesLogosComponent.defaultProps = {
  logos: Logos.defaultProps,
  paddingTop: "68px",
  paddingBottom: "68px",
  mobilePaddingTop: "24px",
  mobilePaddingBottom: "24px"
};
