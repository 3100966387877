const BIG_COMPANY_SIZE = 100;
const SMB_MIN_COMPANY_SIZE = 20;
const SMB_MAX_COMPANY_SIZE = 500;

export const isBigCompany = (companyData) => {
  return companyData?.success && companyData?.data?.company?.metrics?.employees >= BIG_COMPANY_SIZE;
};

export const isSMBCompany = (companyData) => {
  return (
    companyData?.success &&
    companyData?.data?.company?.metrics?.employees >= SMB_MIN_COMPANY_SIZE &&
    companyData?.data?.company?.metrics?.employees <= SMB_MAX_COMPANY_SIZE
  );
};

export const isSMBOrBigCompany = (companyData) => {
  return companyData?.success && companyData?.data?.company?.metrics?.employees >= SMB_MIN_COMPANY_SIZE;
};
