import { PureComponent } from "react";
import classnames from "classnames";
import PictureComponent from "/components/core/picture/picture-component";
import ParagraphComponent from "/components/core/paragraph/paragraph-component";
import { componentStyles } from "./image-gallery-component.scss";

export default class ImageGalleryComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.moveToPage = this.moveToPage.bind(this);
  }
  moveToPage() {
    const { href } = this.props;
    window.location.href = href;
  }

  getSpecificImageStyle() {
    const { grayscale } = this.props;
    let style = grayscale == "true" ? { filter: "grayscale(100%)" } : {};
    return style;
  }

  render() {
    const { url, alt, paragraph, shadow, href, screenRatios, shouldTrackMouseEvents } = this.props;
    const specifyStyle = this.getSpecificImageStyle();
    return (
      <div
        className={classnames("image-gallery", { shadow, clickable: href })}
        onClick={href ? this.moveToPage : undefined}
      >
        <PictureComponent
          specificImageStyle={specifyStyle}
          src={url}
          alt={alt}
          pictureClass="social-proof-gallery-picture"
          screenRatios={screenRatios}
          shouldTrackMouseEvents={shouldTrackMouseEvents}
        />
        {paragraph && <ParagraphComponent {...paragraph} />}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ImageGalleryComponent.defaultProps = {
  url: "",
  alt: "",
  grayscale: false
};
