import { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import {
  BRAND_BLACK_BACKGROUND_COLOR,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  WHITE_COLOR_NAME
} from "styles/color-consts";
import colors from "styles/colors";
import { CLUSTER_TYPE, USE_CASE_TYPE } from "constants/clusters/types";
import { updateUserClustersListWithMultipleClusters } from "services/clusters-service";
import { HP_TAGS_SELECTION } from "constants/clusters";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import { getHoverColor } from "styles/colors-utils";
import { fireHotjarEvents } from "client/services/hotjar-service/hotjar-service";
import { getUrlParamValueFromCurrentLocation } from "utils/url";
import { BRAND_DARK_COLOR_THEME_ID, SPACE_BLUE_GRADIANT_THEME_ID } from "segments/segments-service";
import SquareTagComponent from "./square-tag/square-tag-component";
import DefaultProps from "./default-props";
import { componentStyles } from "./square-tags.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import { isEmpty } from "services/utils-service";

const DARK_THEME_BUTTON_OPACITY = "cc";
const LIGHT_THEME_BUTTON_OPACITY = "ee";

const SIGNUP_BUTTON_ANIMATION_START_TIME = 100;
const SIGNUP_BUTTON_ANIMATION_END_TIME = 400;

export const PRESELECTED_TAG_PARAM_NAME = "selectedTag";

const getSelectedTagsInitialState = (clusters) => {
  const initialState = {};
  clusters.forEach((sc) => (initialState[sc.text] = false));
  return initialState;
};

const SquareTags = (props) => {
  const {
    buttonConfig,
    clusters,
    theme,
    secondaryButtonConfig,
    showGoogleConnectOnDesktop,
    tagsWidth,
    marginTop,
    palette
  } = props;
  const [selectedTags, setSelectedTags] = useState(getSelectedTagsInitialState(clusters));
  const [wasClusterSelected, setWasClusterSelected] = useState(false);

  const getSelectedTagFromUrl = () => {
    const selectedTagId = getUrlParamValueFromCurrentLocation(PRESELECTED_TAG_PARAM_NAME);
    if (!selectedTagId) return;

    const selectedTag = clusters.find(
      (cluster) => cluster.clusterId === selectedTagId || cluster.useCaseId === selectedTagId
    );

    return selectedTag;
  };

  const getSelectedTagsMarkedWithInitiallySelected = () => {
    const selectedClusters = clusters.reduce((acc, obj) => {
      if (obj.initiallySelected) {
        acc[obj.text] = true;
      }
      return acc;
    }, {});
    return selectedClusters;
  };

  useEffect(() => {
    const selectedTagFromUrl = getSelectedTagFromUrl() || {};
    setSelectedTags({
      ...selectedTags,
      ...getSelectedTagsMarkedWithInitiallySelected(),
      [selectedTagFromUrl.text]: true
    });
  }, []);

  const onTagComponentClick = (star) => {
    setSelectedTags({
      ...selectedTags,
      [star.text]: !selectedTags[star.text]
    });
    setTimeout(() => setWasClusterSelected(true), SIGNUP_BUTTON_ANIMATION_START_TIME);
    setTimeout(() => setWasClusterSelected(false), SIGNUP_BUTTON_ANIMATION_END_TIME);
  };

  const renderSquareTags = () => {
    return clusters.map((cluster) => {
      return (
        <div className="star-wrapper" key={cluster.text} style={{ width: tagsWidth }}>
          <SquareTagComponent
            starText={cluster.text}
            starImage={cluster.image}
            tagScheme={palette?.tagScheme}
            checkboxScheme={palette?.checkboxScheme}
            textScheme={palette?.textScheme}
            starColorName={cluster.colorName}
            onStarClick={() => onTagComponentClick(cluster)}
            isSelected={selectedTags[cluster?.text]}
            isSelectableMode={true}
            isDarkTheme={isDarkTheme}
          />
        </div>
      );
    });
  };
  const getSelectedClusters = () => {
    return clusters.filter((c) => selectedTags[c.text]);
  };

  const isAtLeastOneClusterSelected = () => {
    return Object.values(selectedTags)
      .map((star) => star)
      .reduce((acc, s) => acc || s, false);
  };

  const getButtonColorBySelectedClusters = (isDarkTheme, isHover) => {
    const { buttonColor = GRADIANT_WORKOS_IRIS_COLOR_NAME, removeGradient } = buttonConfig;

    if (removeGradient) return colors[buttonColor];

    const OPACITY_VALUE = isDarkTheme ? DARK_THEME_BUTTON_OPACITY : LIGHT_THEME_BUTTON_OPACITY;

    const selectedClusters = getSelectedClusters();

    if (!isAtLeastOneClusterSelected || selectedClusters.length === 0) {
      return isHover ? getHoverColor(colors[buttonColor]) : colors[buttonColor];
    }

    // if only 1 cluster was selected, fill the button with a gradient built of the same color
    if (selectedClusters.length === 1) {
      const colorName = selectedClusters[0].colorName;
      const color = isHover ? getHoverColor(colors[colorName]) : colors[colorName];
      return `linear-gradient(90deg, ${color}${OPACITY_VALUE} 0%, ${color}${OPACITY_VALUE} 100%)`;
    }

    let colorsString = ``;
    const chunkSize = 100 / (selectedClusters.length - 1);

    // building the linear gradient string
    // for example, if 3 clusters are selected, the linear gradient stop locations should be 0%, 50% and 100%
    selectedClusters.forEach((selectedStarsItem, index) => {
      // add separator in every chunk instead of last chunk
      const separator = index === selectedClusters.length - 1 ? "" : ", ";
      const starItemColor = isHover
        ? getHoverColor(colors[selectedStarsItem.colorName])
        : colors[selectedStarsItem.colorName];
      colorsString += `${starItemColor}${OPACITY_VALUE} ${chunkSize * index}%${separator}`;
    });

    return `linear-gradient(90deg, ${colorsString})`;
  };

  const getButtonTextColor = () => {
    const { buttonColor, removeGradient } = buttonConfig;

    if (removeGradient) {
      return buttonColor === WHITE_COLOR_NAME ? colors[BRAND_BLACK_BACKGROUND_COLOR] : colors[WHITE_COLOR_NAME];
    }

    const selectedClusters = getSelectedClusters();
    if (buttonColor === WHITE_COLOR_NAME && selectedClusters.length === 0) {
      return colors[SPACE_BLUE_COLOR_NAME];
    }

    return colors[WHITE_COLOR_NAME];
  };

  const saveClustersOnSignup = () => {
    const selectedClusters = getSelectedClusters();
    const clustersToUpdate = selectedClusters.map((cluster) => {
      const { clusterId, useCaseId } = cluster;
      const res = [];
      if (clusterId) {
        res.push({ clusterId, clusterType: CLUSTER_TYPE });
      }
      if (useCaseId) {
        const extraData = clusterId ? { parent_cluster_id: clusterId } : {};
        res.push({ clusterId: useCaseId, clusterType: USE_CASE_TYPE, extraData });
      }
      return res;
    });
    const flattenedArray = clustersToUpdate.flat();
    if (flattenedArray.length > 0) {
      // fire each cluster id as an event, so filtering can be fully customized on Hotjar side
      fireHotjarEvents(flattenedArray.map((cluster) => `tag_selected_${cluster.clusterType}_${cluster.clusterId}`));
      updateUserClustersListWithMultipleClusters(flattenedArray, HP_TAGS_SELECTION);
    }
  };

  const isDarkTheme = useMemo(
    () => [SPACE_BLUE_GRADIANT_THEME_ID, BRAND_DARK_COLOR_THEME_ID].includes(theme) || !isEmpty(palette),
    [palette, theme]
  );

  const additionalSignUpButtonConfig = {
    beforeSubmitCallback: saveClustersOnSignup,
    displayedOnDarkTheme: isDarkTheme,
    showGoogleConnectOnDesktop
  };

  return (
    <div
      className="square-tags"
      style={{
        "--background": getButtonColorBySelectedClusters(isDarkTheme),
        "--background-hover": getButtonColorBySelectedClusters(isDarkTheme, true),
        "--text-color": getButtonTextColor()
      }}
    >
      <div className="clusters-stars" role="group" style={{ marginTop }}>
        {renderSquareTags()}
      </div>
      <div className="buttons-wrapper">
        <div className={classnames("signup-button-wrapper", { "cluster-selected": wasClusterSelected })}>
          <SignupButton {...buttonConfig} {...additionalSignUpButtonConfig} />
        </div>
        {secondaryButtonConfig && <SecondaryButton {...secondaryButtonConfig} />}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

SquareTags.defaultProps = DefaultProps;

export default withGenericConfig(SquareTags);
