import { PureComponent } from "react";

const BIGBRAIN_SCRIPT_URL = process.env.BIGBRAIN_SCRIPT_URL;
const BIGBRAIN_URL = process.env.BIGBRAIN_ENDPOINT || "//data.bigbrain.me";

export default class BigBrainScript extends PureComponent {
  renderBigBrainTracker(
    template,
    isProduction,
    pageType,
    clusterId,
    subClusterId,
    preventPageView,
    createVisitor,
    crossDomain,
    emitVisitorId,
    emitTargetOrigin
  ) {
    const clusterIdToTrack = clusterId ? `${clusterId}${subClusterId ? `,${subClusterId}` : ""}` : "";

    return `
      var brand_name = 'monday.com';
      var template = '${template}';

      function add_general_event_props(args) {
        event_props = {};
        if(args.length == 3) {
          event_props = args[2];
        }

        event_props['timestamp'] = parseInt(new Date().getTime()/1000);
        event_props["source"] = event_props["source"] || "monday_homepage";

        if (args.length == 2) {
          args.push(event_props);
        }
        else {
          args[2] = event_props;
        }
        return args;
      }

      if (${isProduction}) {
        window.BigBrainQ = window.BigBrainQ || [];
        window.BigBrain = window.BigBrain || function(){
          args = Array.prototype.slice.call(arguments)
          if(args[0] == 'track'){
            args = add_general_event_props(args)
          }
          window.BigBrainQ.push(args);
        };

        (function(d,u,callback){
          if (window.BigBrainInit) return;
          window.BigBrainInit = true;
          var s = d.createElement('script');
          s.type = 'text/javascript'
          s.src = u;
          s.async = true;
          s.onreadystatechange = s.onload = function(){
            var st = s.readyState;
            if (!callback.done && (!st || /loaded|complete/.test(st))) {
              callback.done = true;
              callback();
            }
          };
          (d.body || d.head).appendChild(s);
        })(document, '${BIGBRAIN_SCRIPT_URL}', function() { 
            window.BigBrain = new BigBrainTracker({bigbrain_url: '${BIGBRAIN_URL}', send_immediately: true, create_visitor: ${createVisitor}, is_tracker_cross_domain: ${!!crossDomain}, emit_visitor_id: ${!!emitVisitorId}, emit_target_origin: "${emitTargetOrigin}"});
        });

      } else {
        window.BigBrain = function() {
          args = Array.prototype.slice.call(arguments)
          if(args[0] == 'track' || args[0] == 'set_utm_cluster_id'  ||  args[0] == 'set_utm_locale_id') {
            args = add_general_event_props(args)
            argsn = args.map(function(t) {
              if (typeof(t) === 'object')
                return JSON.stringify(t);
              else
                return t;
            });
            console.info(argsn);
          }
        }
      }

      if (${!preventPageView}) {
        var type = document.getElementsByTagName('body')[0].dataset.pageType || '${pageType}';
        if (type === undefined || (typeof (type) == 'string' && type.length == 0) || type == 'homepage'){
          type = 'homepage';
        } else {
          type = '${pageType}';
        }
        window.visitGuid = window.visitGuid || Math.ceil(Math.random() * 10000000);
        var options = {kind: location.pathname, source: type, info2: template || "", info3: location.href, direct_object: window.visitGuid, info1: "${clusterIdToTrack}"};
        BigBrain('track', 'page_view', options);
      }    
  `;
  }

  render() {
    const {
      template,
      isProduction,
      pageType,
      clusterId,
      subClusterId,
      preventPageView,
      createVisitor,
      crossDomain,
      emitVisitorId,
      emitTargetOrigin
    } = this.props;
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: this.renderBigBrainTracker(
            template,
            isProduction,
            pageType,
            clusterId,
            subClusterId,
            preventPageView,
            createVisitor,
            crossDomain,
            emitVisitorId,
            emitTargetOrigin
          )
        }}
      />
    );
  }
}

BigBrainScript.defaultProps = {
  pageType: "homepage",
  createVisitor: true
};
