import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import { MOBILE_GRID_IMAGE_SIZE } from "segments/desktop/grid/grid-service";

const MOBILE_GRID_IMAGE_SIZE_PX = `${MOBILE_GRID_IMAGE_SIZE}px`;

export const componentStyles = css.global`
  .grid-image {
    display: flex;

    .image {
      width: 100%;
    }
  }
`;
