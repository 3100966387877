import css from "styled-jsx/css";

export const componentStyles = css.global`
  .views-tab-content {
    .live-board-tab-wrapper {
      .responsive-hoc-component {
        .desktop-wrapper {
          .flexible-cards-grid-desktop-component {
            .flexible-cards-grid-desktop-component-inner {
              .grid-paragraph-with-image-component-wrapper {
                .top-layer {
                  .grid-paragraph-with-image {
                    .grid-paragraph-wrapper {
                      padding-bottom: 0px;
                      .full-paragraph {
                        .title-wrapper {
                          margin-bottom: 0px;
                          .core-title {
                            font-size: 1.2rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
