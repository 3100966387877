import css from "styled-jsx/css";

export const componentStyles = css.global`
  .hp-select-input {
    font-size: 0.8125rem;

    &.is-placholder {
      color: gray; //match default input placheolder color
    }
  }
`;
