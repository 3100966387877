import GridParagraphWithImage from "segments/desktop/core-components/grid-paragraph-with-image/grid-paragraph-with-image";
import classnames from "classnames";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
import { componentStyles } from "./flexible-cards-grid-desktop-component.scss";
import FlexibleCardsGridDefaultProps from "../default-props";
import { COLUMN_WIDTH, MARGIN_BETWEEN_CARDS } from "../../flexible-cards-consts";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { trackEvent } from "/services/bigbrain-service";
import { FLEXIBLE_GRID_CARD_HOVERED } from "/constants/bigbrain-event-types";

const IMAGE_HEIGHT = "100%";
const IMAGE_WIDTH = "100%";
const margin = `${MARGIN_BETWEEN_CARDS / 2}px`;

const FlexibleCardsGridDesktopComponent = (props) => {
  const renderGridItems = () => {
    const {
      shadowCards,
      removeShadowOnHover,
      gridItems,
      titleSize,
      textSize,
      topicBottomMargin,
      spaceFromParagraph,
      buttonTopMargin,
      innerPadding,
      defaultCardClick
    } = props;

    return gridItems.map((gridItem, index) => {
      const {
        textColor,
        cardColor,
        backgroundImage,
        cardNumOfColumnsWidth,
        fullParagraph,
        addBackLayerContent,
        cardBackLayerContent,
        cardTagText,
        cardTagColor,
        paragraphVerticalColoredLineColor,
        onCardClick,
        ...resImageProps
      } = gridItem;

      const flexibleCardHovered = () => {
        trackEvent(FLEXIBLE_GRID_CARD_HOVERED, { kind: fullParagraph?.title || fullParagraph?.body });
      };
      const columnWidthForCss = `${COLUMN_WIDTH}px`;
      let cardWidthForCss;
      if (cardNumOfColumnsWidth) {
        const cardWidth = cardNumOfColumnsWidth * COLUMN_WIDTH + (cardNumOfColumnsWidth - 1) * MARGIN_BETWEEN_CARDS;
        cardWidthForCss = `${cardWidth}px`;
      }
      const onClickCallBack = onCardClick || defaultCardClick;
      const isCardClickable = onClickCallBack;
      const onKeyDown = isCardClickable ? generateOnKeyDownHandler(onClickCallBack) : null;
      const cardAccesibilityProps = isCardClickable
        ? {
            onKeyDown,
            tabIndex: 0
          }
        : {};
      const layersSharedProps = { topicBottomMargin, spaceFromParagraph, buttonTopMargin };
      const fullParagraphFullProps = { ...layersSharedProps, ...fullParagraph };
      const backLayerparagraphProps = { ...layersSharedProps, ...cardBackLayerContent?.backLayerFullParagraph };
      return (
        <div
          key={index}
          className={classnames("grid-paragraph-with-image-component-wrapper", {
            "has-background-paragraph": addBackLayerContent
          })}
          style={{
            "--flexible-grid-card-width": cardWidthForCss || columnWidthForCss,
            "--cards-margin": margin
          }}
          onClick={onClickCallBack}
          {...cardAccesibilityProps}
          role={"listitem"}
          onMouseEnter={flexibleCardHovered}
        >
          <div className="top-layer">
            <GridParagraphWithImage
              {...resImageProps}
              fullParagraph={fullParagraphFullProps}
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
              titleSize={titleSize}
              textSize={textSize}
              cardColor={cardColor}
              textColor={textColor}
              shadowCards={shadowCards}
              removeShadowOnHover={removeShadowOnHover}
              cardWidthForCss={cardWidthForCss}
              backgroundImage={backgroundImage}
              cardTagText={cardTagText}
              cardTagColor={cardTagColor}
              paragraphVerticalColoredLineColor={paragraphVerticalColoredLineColor}
              padding={innerPadding}
            />
          </div>
          {addBackLayerContent && (
            <div className="back-layer">
              <GridParagraphWithImage
                fullParagraph={backLayerparagraphProps}
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                titleSize={titleSize}
                textSize={textSize}
                cardColor={cardColor}
                textColor={textColor}
                shadowCards={shadowCards}
                removeShadowOnHover={removeShadowOnHover}
                cardWidthForCss={cardWidthForCss}
                contentVerticalAlignment={cardBackLayerContent?.contentVerticalAlignment}
                padding={innerPadding}
              />
            </div>
          )}
        </div>
      );
    });
  };

  const { gridAlignment } = props;
  const componentWidthWithoutHeaderPadding = `calc(100% - ${
    Constants.HEADER_CONTENT_HORIZONTAL_PADDING * 2 - MARGIN_BETWEEN_CARDS
  }px)`;

  return (
    <div className="flexible-cards-grid-desktop-component" style={{ "--component-alignment": gridAlignment }}>
      <div
        className="flexible-cards-grid-desktop-component-inner"
        style={{ width: componentWidthWithoutHeaderPadding }}
        role={"list"}
      >
        {renderGridItems()}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

FlexibleCardsGridDesktopComponent.defaultProps = FlexibleCardsGridDefaultProps;
export default FlexibleCardsGridDesktopComponent;
