import { PureComponent } from "react";
import { componentStyles } from "./logos.scss";
import DefaultProps from "./default-props";
import ImagesGalleryComponent from "/components/core/images-gallery/images-gallery-component";
import { getCompaniesAssets, NUMBER_OF_COMPANIES } from "./logos-const";
import { MOBILE_QUERY } from "/constants/vp-sizes";
import { SUPPORTED_LOGOS_NUMBER_OPTIONS } from "segments/desktop/core-components/logos/logos-const";

import classNames from "classnames";

export default class Logos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { mobileView: false };
  }

  componentDidMount() {
    if (window.matchMedia(MOBILE_QUERY).matches) {
      this.setState({ mobileView: true });
    }
  }

  isNewLayout = () => {
    return this.getNumberOfCompaniesToDisplay() == NUMBER_OF_COMPANIES; //compare string and int (old props can be integers)
  };

  getNumberOfCompaniesToDisplay = () => {
    const { numberOfCompanies } = this.props;

    if (SUPPORTED_LOGOS_NUMBER_OPTIONS.includes(numberOfCompanies?.toString())) return numberOfCompanies;

    return NUMBER_OF_COMPANIES; //support old pages config which had companyNummber = 3 / 7 /8  + use this as the default prop when numberOfCompanies isn't defined
  };

  render() {
    const { grayscale, showAllLogos, companyField, country, slim } = this.props;
    const assets = getCompaniesAssets({ companyField, country });

    const numberOfCompaniesToDisplay = this.getNumberOfCompaniesToDisplay();
    const companiesAssetsSliced = showAllLogos ? assets : assets.slice(0, numberOfCompaniesToDisplay);

    return (
      <div className={classNames("logos", { "full-logo-list": showAllLogos, "new-layout": this.isNewLayout(), "slim": slim })}>
        <div className="images-logos-wrapper">
          <ImagesGalleryComponent grayscale={grayscale} assets={companiesAssetsSliced} shouldTrackMouseEvents={true} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

Logos.defaultProps = DefaultProps;
