import css from "styled-jsx/css";
import colors from "/styles/colors";

export default css`
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.9);

    .modal-footer-text {
      color: ${colors["white"]};
      outline: none;
      font-size: 1.125rem;
    }

    .modal-footer-signup-wrapper {
      width: 55%;
      margin-left: 16px;
    }
  }
`;
