import css from "styled-jsx/css";

export const componentStyles = css.global`
  .regular-button {
    &.full {
      width: 100%;
    }
    .additional-button-text {
      margin-top: 8px;
    }
  }
`;
