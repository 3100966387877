import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

export const componentStyles = css`
  .icons {
    display: flex;
    .icon-wrapper {
      display: flex;
      gap: 8px;
      width: 100%;
      :global(img) {
        max-height: 32px;
      }
      .icon-texts-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .title {
          :global(.monday-markup-language-component) {
            font-size: 1.463rem;
            line-height: 26px;
            font-weight: 500;
          }
        }
        .subtitle {
          :global(.monday-markup-language-component) {
            font-size: 1rem;
            line-height: 20px;
          }
        }
      }
    }
  }
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .icons {
      display: flex;
      .icon-wrapper {
        flex-direction: column;
        align-items: center;
        padding-bottom: 35px;
        .icon-texts-wrapper {
          align-items: center;
          text-align: center;
          .title {
            :global(.monday-markup-language-component) {
              font-size: 1.125rem;
            }
          }
          .subtitle {
            :global(.monday-markup-language-component) {
              font-size: 0.8125rem;
            }
          }
        }
      }
    }
  }
`;
