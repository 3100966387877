import { createContext, useContext, useEffect, useState } from "react";
import { PREFETCH_TYPES } from "constants/prefetch-constants";
import { getTypeformImagesPrefetchByCountry } from "constants/typeform-contact-sales";

const TYPEFORM_FONT_CSS_FILE = "https://font.typeform.com/dist/google/roboto/index.css";
const TYPEFORM_ROBOTO_400_FONT = "https://font.typeform.com/dist/google/roboto/files/roboto-latin-400-normal.woff2";
const TYPEFORM_ROBOTO_700_FONT = "https://font.typeform.com/dist/google/roboto/files/roboto-latin-700-normal.woff2";
const MONDAY_LOGO = "https://images.typeform.com/images/2GR8rmBChTYm";

const STATIC_ASSETS_HREFS = [TYPEFORM_FONT_CSS_FILE, TYPEFORM_ROBOTO_400_FONT, TYPEFORM_ROBOTO_700_FONT, MONDAY_LOGO];

const PREFETCHED_DOMAINS = ["https://form.typeform.com"];
const PRECONNECT_DOMAINS = PREFETCHED_DOMAINS;

const PrefetchedTypeformAssetsContext = createContext("PrefetchedTypeformAssets");

export const PrefetchTypeformAssetsProvider = (props) => {
  const { children, cloudfrontViewerCountry } = props;
  const [prefetchedTypeformAssetsLinks, setPrefetchedTypeformAssetsLinks] = useState([]);

  useEffect(() => {
    const setData = async () => {
      const typeformAssetsLinks = [];
      // dns prefetches
      PREFETCHED_DOMAINS.forEach((href) => {
        typeformAssetsLinks.push({ type: PREFETCH_TYPES.DNS_PREFETCH, href });
      });

      // pre-connects
      PRECONNECT_DOMAINS.forEach((href) => {
        typeformAssetsLinks.push({ type: PREFETCH_TYPES.PRECONNECT, href });
      });

      // static assets
      STATIC_ASSETS_HREFS.forEach((href) => {
        typeformAssetsLinks.push({ type: PREFETCH_TYPES.PREFETCH, href });
      });

      const dynamicAssets = getTypeformImagesPrefetchByCountry(cloudfrontViewerCountry);
      dynamicAssets &&
        dynamicAssets.forEach((href) => {
          typeformAssetsLinks.push({ type: PREFETCH_TYPES.PREFETCH, href });
        });

      setPrefetchedTypeformAssetsLinks(typeformAssetsLinks);
    };

    setData();
  }, [cloudfrontViewerCountry]);

  return (
    <PrefetchedTypeformAssetsContext.Provider value={prefetchedTypeformAssetsLinks}>
      {children}
    </PrefetchedTypeformAssetsContext.Provider>
  );
};

export const usePrefetchedTypeformAssets = () => {
  const prefetchedTypeformAssetsLinks = useContext(PrefetchedTypeformAssetsContext);

  return prefetchedTypeformAssetsLinks;
};
