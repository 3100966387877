import { PureComponent } from "react";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";

import componentStyles from "./modal-footer-component.scss";

export default class ModalFooterComponent extends PureComponent {
  render() {
    const { footerText } = this.props;
    return (
      <div className="modal-footer">
        <div className="modal-footer-text">{footerText}</div>
        <div className="modal-footer-signup-wrapper">
          <SignupFormComponent focusOnLoad={true} placement="modal_footer" />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
