export const positionTypes = {
  TOP_LEFT: "TOP_LEFT",
  TOP_CENTER: "TOP_CENTER",
  TOP_RIGHT: "TOP_RIGHT",
  CENTER_LEFT: "CENTER_LEFT",
  CENTER_CENTER: "CENTER_CENTER",
  CENTER_RIGHT: "CENTER_RIGHT",
  BOTTOM_LEFT: "BOTTOM_LEFT",
  BOTTOM_CENTER: "BOTTOM_CENTER",
  BOTTOM_RIGHT: "BOTTOM_RIGHT"
};

const imagePositionStylesMapping = {
  [positionTypes.TOP_LEFT]: { justifyContent: "flex-start", alignItems: "flex-start" },
  [positionTypes.TOP_CENTER]: { justifyContent: "center", alignItems: "flex-start" },
  [positionTypes.TOP_RIGHT]: { justifyContent: "flex-end", alignItems: "flex-start" },
  [positionTypes.CENTER_LEFT]: { justifyContent: "flex-start", alignItems: "center" },
  [positionTypes.CENTER_CENTER]: { justifyContent: "center", alignItems: "center" },
  [positionTypes.CENTER_RIGHT]: { justifyContent: "flex-end", alignItems: "center" },
  [positionTypes.BOTTOM_LEFT]: { justifyContent: "flex-start", alignItems: "flex-end" },
  [positionTypes.BOTTOM_CENTER]: { justifyContent: "center", alignItems: "flex-end" },
  [positionTypes.BOTTOM_RIGHT]: { justifyContent: "flex-end", alignItems: "flex-end" }
};

export const getImagePositionStyles = imagePosition => {
  return imagePositionStylesMapping[imagePosition];
};
