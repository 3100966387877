import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import classnames from "classnames";
import { encloseBoldTag } from "utils/strings";
import PictureComponent from "/components/core/picture/picture-component";
import colors from "styles/colors";
import { ConditionalWrapper } from "utils/react";
import DefaultProps from "./default-props";
import { componentStyles } from "./title.scss";

const Title = (props) => {
  const { title, titleSize, titleColor, isFirstSegment, titleIcon, titleClassName } = props;

  const getTitleStyle = () => {
    if (!titleColor) {
      return null;
    }

    const titleStyle = {
      color: colors[titleColor]
    };

    return titleStyle;
  };

  const renderIcon = () => {
    return (
      <div className={classnames("icon-wrapper", titleSize)}>
        <PictureComponent src={titleIcon} alt="" className="title-icon" pictureClass="picture-icon-wrapper" />
      </div>
    );
  };

  const titleAndIconWrapper = (children) => <span className="title-and-icon-wrapper">{children}</span>;

  return (
    <ConditionalWrapper condition={!!titleIcon} wrapper={titleAndIconWrapper}>
      {titleIcon && renderIcon()}
      <MondayMarkupLanguageComponent
        tagName={isFirstSegment ? "h1" : "h2"}
        className={classnames("core-title", titleSize, titleClassName)}
        text={encloseBoldTag(title)}
        style={getTitleStyle()}
      />
      <style jsx>{componentStyles}</style>
    </ConditionalWrapper>
  );
};

Title.defaultProps = DefaultProps;

export default Title;
