import { PureComponent } from "react";
import ModalFooterComponent from "../modal-footer/modal-footer-component";
import componentStyles from "./modal-content-component.scss";
import onClickOutside from "react-onclickoutside";

class ModalWithSignupContentComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.props.onCloseModal();
  }

  render() {
    const { children, footerText, onCloseModal, style, withSignup } = this.props;
    return (
      <div
        className="modal-content-wrapper"
        style={{ minHeight: style.content.minHeight, minWidth: style.content.minWidth }}
      >
        {children}
        {withSignup && (
          <div className="modal-footer-wrapper">
            <ModalFooterComponent footerText={footerText} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

ModalWithSignupContentComponent.defaultProps = {
  withSignup: true
};

export default onClickOutside(ModalWithSignupContentComponent);
