import css from "styled-jsx/css";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
import { LAYER_ONE } from "constants/z-index";

export const componentStyles = css.global`
  .carousel {
    .slick-slider {
      .slick-dots {
        z-index: ${LAYER_ONE};
        li {
          margin: 0px 4px;

          button {
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            border: ${`1px solid ${colors["asphalt"]}`};
            opacity: 1;
            background-color: transparent;

            &::before {
              display: none;
            }
          }
          &.slick-active {
            button {
              background-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
              border-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
            }
          }
          &:not(.slick-active):hover {
            button {
              background-color: #c5c7d0;
            }
          }
        }
      }
    }

    .slick-slider.focus {
      .slick-list {
        .slick-track {
          padding: 48px 0;

          .slick-slide {
            .carousel-item {
              outline: none;
              transition: opacity 0.5s ease, transform 0.5s ease;
              transform: scale(1);
              opacity: 0.3;
            }

            &.slick-active {
              &.slick-center {
                .carousel-item {
                  transform: scale(1.175);
                }
              }

              .carousel-item {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;
