import { useMemo, useState } from "react";
import ReactPlayer from "react-player";
import VisibilitySensor from "react-visibility-sensor";
import {
  generateCloudinaryImageUrl,
  generateCloudinaryVideoUrl,
  generateCloudinaryVideoThumbnail
} from "services/community/cloudinary-service";
import { videoComponentStyles } from "./video-component.scss";
import { isCloudinaryUrl } from "/services/community/cloudinary-service";
import classnames from "classnames";

const VideoComponent = (props) => {
  const [isVisible, setVisible] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hadFocusWithKeyboardEvent, setHadFocusWithKeyboardEvent] = useState(false);

  /* Video configuration */
  const shouldPlayVideo = () => {
    const { playWhenVisible, autoPlay } = props;
    if (!playWhenVisible) {
      return autoPlay;
    }

    return isVisible;
  };

  const getUrl = () => {
    let url;

    if (props.singleUrl) {
      url = props.singleUrl;
    } else {
      // Only set urls on init so player is not refreshed on every update
      const formats = props.formats.filter((urlFormat) => urlFormat); // clean undefined
      url = formats;
      if (formats.length > 0 && !isCloudinaryUrl(formats[0])) {
        url = generateCloudinaryVideoUrl(formats[0], { assetQuality: props.assetQuality });
      }
    }

    return url;
  };

  const getPoster = () => {
    const { poster } = props;
    let fixedPosterUrl;

    if (poster) {
      fixedPosterUrl = generateCloudinaryImageUrl(poster);
    } else {
      const { formats } = props;
      const videoUrl = formats[0];
      if (videoUrl) {
        fixedPosterUrl = generateCloudinaryVideoThumbnail(videoUrl);
      }
    }

    return fixedPosterUrl;
  };

  const getConfig = (poster) => {
    const { config, ariaLabel } = props;
    const newConfig = { ...config };

    newConfig.file = config.file || {};

    newConfig.file.attributes = {
      ...newConfig.file.attributes,
      poster,
      "aria-label": ariaLabel
    };

    return newConfig;
  };

  /* Events */

  const onClick = () => {
    props.onClick && props.onClick();
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onFocus = () => {
    if (!hovered) {
      setHadFocusWithKeyboardEvent(true);
    }
  };

  const onVisibilityChange = (isVisible) => {
    setTimeout(() => setVisible(isVisible), 200);
  };

  const showControls = () => {
    if (hideControls === undefined) {
      return !loop;
    } else if (hideControls) {
      return false;
    }
    return hovered || hadFocusWithKeyboardEvent;
  };

  const { style, playWhenVisible, loop, muted, volume, playsInline, hideControls } = props;

  const {
    singleUrl,
    formats,
    assetQuality,
    poster,
    config,
    ariaLabel,
    className,
    videoPlayerRef,
    onEnded,
    onDuration,
    hideBlackBars
  } = props;
  const memoizedUrl = useMemo(() => getUrl(), [singleUrl, formats, assetQuality]);
  const memoizedPoster = useMemo(() => getPoster(), [poster, formats]);
  const memoizedConfig = useMemo(() => getConfig(memoizedPoster), [memoizedPoster, config, ariaLabel]);

  const tabIndex = hideControls ? -1 : 0;

  return (
    <>
      <VisibilitySensor
        offset={{ bottom: 100, top: 100 }}
        partialVisibility={true}
        onChange={onVisibilityChange}
        active={playWhenVisible}
        scrollCheck={playWhenVisible}
      >
        <div
          className={classnames("video-component", { "hide-black-bars": hideBlackBars })}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          tabIndex={tabIndex}
        >
          <ReactPlayer
            ref={videoPlayerRef}
            className={classnames("react-player-video-component", className)}
            url={[memoizedUrl]} //Array to support screen readers which need a Source element inside video instead of src tag
            style={style}
            playsinline={playsInline}
            playing={shouldPlayVideo()}
            config={memoizedConfig}
            muted={muted}
            volume={volume}
            loop={loop}
            controls={showControls()}
            width="100%"
            height="100%"
            onEnded={onEnded}
            onDuration={onDuration}
          />
        </div>
      </VisibilitySensor>
      <style jsx>{videoComponentStyles}</style>
    </>
  );
};

VideoComponent.defaultProps = {
  formats: [],
  autoPlay: true,
  playWhenVisible: true,
  muted: true,
  volume: 0,
  config: {},
  playsInline: true,
  ariaLabel: null,
  singleUrl: null,
  videoPlayerRef: null
};

export default VideoComponent;
