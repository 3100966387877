export const PREFETCH_TYPES = {
  DNS_PREFETCH: "dns-prefetch",
  PREFETCH: "prefetch",
  PRECONNECT: "preconnect"
};

const isProduction = process.env.NODE_ENV === "production";
const PLATFORM_STAGING_CDN_URL = "https://multicdn.mondaystaging.com";
const PLATFORM_PROD_CDN_URL = "https://cdn.monday.com";
export const PLATFORM_MANIFEST_ASSETS_CDN_DOMAIN = "http://webpack.llama.fan:3444";
export const PLATFORM_CDN_URL = isProduction ? PLATFORM_PROD_CDN_URL : PLATFORM_STAGING_CDN_URL;
export const PLATFORM_MANIFEST_ENDPOINT = `${PLATFORM_CDN_URL}/build/manifest.json`;
export const MANIFEST_PREFETCHED_ASSET_KEYS = [
  "soft_signup.js",
  "soft_signup.css",
  "main.js",
  "main.css",
  "components.js",
  "vendors.js",
  "backbone_init.js",
  "externals.js"
];
