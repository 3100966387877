import PictureComponent from "components/core/picture/picture-component";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import { componentStyles } from "./icons.scss";
import DefaultProps from "./default-props";

const Icons = (props) => {
  const { icons } = props;
  return (
    <div className="icons">
      {icons.map((icon, index) => {
        return (
          <div className="icon-wrapper" key={index}>
            <PictureComponent {...icon.image} />
            <div className="icon-texts-wrapper">
              <div className="title">
                <MondayMarkupLanguageComponent text={icon.title} />
              </div>
              <div className="subtitle">
                <MondayMarkupLanguageComponent text={icon.subtitle} />
              </div>
            </div>
          </div>
        );
      })}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default Icons;

Icons.defaultProps = DefaultProps;
