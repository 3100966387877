export const leadSourceDefaultValue = "Website - Contact Sales";
export const leadSourceConfigList = [
  { value: "Website - Contact Sales", text: "Website - Contact Sales" },
  { value: "Website - Request a Demo", text: "Website - Request a Demo" },
  { value: "Linkedin - Contact Sales", text: "Linkedin - Contact Sales" },
  { value: "Linkedin - Request a Demo", text: "Linkedin - Request a Demo" },
  { value: "Content", text: "Content" },
  { value: "Contact Sales - Tech Partner", text: "Contact Sales - Tech Partner" },
  { value: "Webinar", text: "Webinar" },
  { value: "Mobile - Contact Sales", text: "Mobile - Contact Sales" },
  { value: "Xing", text: "Xing" }
];

export const HUBSPOT_API_FIRSTNAME = "firstname";
export const HUBSPOT_API_LASTNAME = "lastname";
export const HUBSPOT_API_EMAIL = "email";
export const HUBSPOT_API_JOB = "jobtitle";
export const HUBSPOT_API_PHONE = "phone";
export const HUBSPOT_API_COMPANY = "company";
export const HUBSPOT_API_COMPANY_SIZE = "company_size__c";
export const HUBSPOT_API_HOW_CAN_HELP = "how_can_our_consulting_team_help_you";
export const HUBSPOT_API_CLUSTER = "cluster_hs";
export const HUBSPOT_API_VISITOR = "visitor_id";
export const HUBSPOT_API_SOURCE = "leadsource";
export const HUBSPOT_API_FROM = "from";
export const HUBSPOT_API_PRIVACY_CONSENT = "seen_legal_disclaimer";
export const HUBSPOT_API_1_19_PHONE_SUPPORT_GROUP_TEST_FIELD = "1_19_phone_support_group_test";
const HUBSPOT_API_UTM_SOURCE = "utm_source";
const HUBSPOT_API_UTM_CAMPAIGN = "utm_campaign";
const HUBSPOT_API_UTM_BANNER = "utm_banner";
const HUBSPOT_API_UTM_CONTENT = "utm_content";
const HUBSPOT_API_UTM_ADGROUP = "utm_adgroup";
const HUBSPOT_API_UTM_MEDIUM = "utm_medium";
const HUBSPOT_API_UTM_KEYWORD = "utm_keyword";
const HUBSPOT_API_NUMBER_OF_SEATS = "number_of_seats_last_selected_pricing_page";

export const URL_PARAM_FROM = "from";

// The UTM consts are used as both url param names and keys in the local storage
const UTM_SOURCE = "utm_source";
const UTM_CAMPAIGN = "utm_campaign";
const UTM_BANNER = "utm_banner";
const UTM_CONTENT = "utm_content";
const UTM_ADGROUP = "utm_adgroup";
const UTM_MEDIUM = "utm_medium";
const UTM_KEYWORD = "utm_keyword";
export const NUMBER_OF_SEATS_PARAM = "number_of_seats_last_selected_pricing_page";

export const SALES_ENRICHMENT_PARAM_TYPES = {
  URL_PARAM: "url_param"
};

export const SALES_ENRICHMENT_PARAM_LIST = [
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_SOURCE,
    paramName: UTM_SOURCE
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_CAMPAIGN,
    paramName: UTM_CAMPAIGN
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_BANNER,
    paramName: UTM_BANNER
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_CONTENT,
    paramName: UTM_CONTENT
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_ADGROUP,
    paramName: UTM_ADGROUP
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_MEDIUM,
    paramName: UTM_MEDIUM
  },
  {
    type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
    hubspotApiName: HUBSPOT_API_UTM_KEYWORD,
    paramName: UTM_KEYWORD
  },
  {
    hubspotApiName: HUBSPOT_API_NUMBER_OF_SEATS,
    paramName: NUMBER_OF_SEATS_PARAM
  }
];
