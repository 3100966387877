import GridImageDefaultProps from "segments/desktop/core-components/grid-image/default-props";
import titleDefaultProps from "segments/desktop/core-components/title/default-props";
import paragraphDefaultProps from "segments/desktop/core-components/paragraph/default-props";
import { WHITE_COLOR_NAME } from "styles/color-consts";

export default {
  ...GridImageDefaultProps,
  showParagraph: true,
  fullParagraph: { ...titleDefaultProps, ...paragraphDefaultProps },
  cardColor: WHITE_COLOR_NAME
};
