import { PureComponent } from "react";
import ImagesGalleryRowComponent from "./images-gallery-row/images-gallery-row-component";

export default class ImagesGalleryComponent extends PureComponent {
  convertToMatrixIfNeeded(assets) {
    if (assets && !Array.isArray(assets[0])) {
      return [assets];
    }
    return assets;
  }

  render() {
    let { assets, shadow, screenRatios, grayscale, shouldTrackMouseEvents } = this.props;
    assets = this.convertToMatrixIfNeeded(assets);

    return (
      <div className="images-gallery">
        {assets.map((assetsRow, index) => (
          <ImagesGalleryRowComponent
            grayscale={grayscale}
            key={index}
            assets={assetsRow}
            shadow={shadow}
            screenRatios={screenRatios}
            shouldTrackMouseEvents={shouldTrackMouseEvents}
          />
        ))}
      </div>
    );
  }
}

ImagesGalleryComponent.defaultProps = {
  assets: [],
  grayscale: false
};
