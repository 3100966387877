import { PureComponent, Fragment } from "react";
import { componentStyles } from "./select-input.scss";
import { commonInputStyles } from "../common-inputs.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";

class SelectInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  renderOptions = (options, defaultValue) => {
    const { translate } = this.props;

    const optionsToRender = options.map((option) => (
      <option className="contact-form-row" key={option} value={option} name={option}>
        {translate(option)}
      </option>
    ));

    return (
      <Fragment>
        {defaultValue && (
          <option disabled value={defaultValue}>
            {translate(defaultValue)}
          </option>
        )}
        {optionsToRender}
      </Fragment>
    );
  };

  render() {
    const { inputData, errorState, onInputChange, onInputBlur, value, invalid, errorLabelId, id } = this.props;
    const { inputName, isRequired, options, defaultValue } = inputData;
    const { isValid } = errorState;

    const showRequiredError = !isValid && isRequired;
    const requiredClass = {
      "input-required": showRequiredError
    };

    return (
      <Fragment>
        <select
          id={id}
          onChange={onInputChange}
          onBlur={onInputBlur}
          className={classnames("hp-input hp-select-input", requiredClass, { "is-placholder": !value })}
          name={inputName}
          value={value || defaultValue}
          aria-required={isRequired}
          aria-invalid={invalid}
          aria-describedby={errorLabelId}
        >
          {this.renderOptions(options, defaultValue)}
        </select>
        <style jsx>{commonInputStyles}</style>
        <style jsx>{componentStyles}</style>
      </Fragment>
    );
  }
}

SelectInput.defaultProps = {
  invalid: false,
  errorLabelId: null
};

export default withGenericConfig(SelectInput);
