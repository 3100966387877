import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

const BULLET_SIZE = 16;
const BULLET_SIZE_STRING = `${BULLET_SIZE}px`;

export const componentStyles = css.global`
  .bullets {
    display: flex;
    flex-wrap: wrap;
    &.column {
      flex-direction: column;
    }
    .bullet-anchor-wrapper {
      text-decoration: none;
      color: unset;
      cursor: pointer;
    }
    .bullet {
      display: flex;
      align-items: flex-start;

      .bullet-icon {
        height: ${BULLET_SIZE_STRING};
        width: ${BULLET_SIZE_STRING};
        padding-top: 8px;
        svg {
          height: ${BULLET_SIZE_STRING};
          width: ${BULLET_SIZE_STRING};
        }
      }

      .bullet-text {
        margin-left: 8px;
        line-height: 31px;
        padding-right: 24px;
      }
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      .bullet {
        width: 100%;
      }
    }
  }
`;
