import css from "styled-jsx/css";
import Colors from "/styles/colors";

export const componentStyles = css.global`
  .hp-textarea-input {
    width: 100%;
    max-width: 100%;
    height: 106px;
    resize: none;
  }
`;
