import { PureComponent } from "react";
import PictureComponent from "components/core/picture/picture-component";
import { PICTURE_WITH_LINK_CLICKED } from "/constants/bigbrain-event-types";

export default class PictureWithLinkComponent extends PureComponent {
  onClick = (e) => {
    const { href, src } = this.props;
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    BigBrain("track", PICTURE_WITH_LINK_CLICKED, {
      kind: href,
      info1: isKeyboardEvent ? "keyboard" : "mouse",
      info2: src
    });
  };

  render() {
    const { href, target, ...otherProps } = this.props;

    if (href) {
      return (
        <a href={href} target={target} onClick={this.onClick}>
          <PictureComponent {...otherProps} />
        </a>
      );
    }

    return <PictureComponent {...otherProps} />;
  }
}

PictureWithLinkComponent.defaultProps = {
  target: "_self"
};
