import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .email-button {
    margin: 8px 0px;
    width: 100%;

    .email-button-inner {
      display: flex;
      justify-content: center;
      height: 50px;

      input {
        font-size: 0.875rem;
        line-height:8px;
        padding: 12px 24px;
        border-radius: 40px;
        font-size: 1rem;

        -webkit-appearance: none; //Remove shadow from input in mobile safari

        background-color: ${colors["light-grey"]};
        color: ${colors["black"]};

        border: 1px solid;
        border-color: ${colors["grey"]};
        outline: none;

        text-align: left;
        transition: border-color 0.1s ease-in-out;
        margin-right: 16px;

        &:hover::placeholder,
        &:focus::placeholder {
          color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
        }

        &:focus {
          border-color: ${colors[WORK_OS_IRIS_COLOR_NAME]};
          background-color: ${colors["white"]};
        }
      }

      &.error input,
      &.error input:focus {
        border-color: ${colors["error"]};
      }

      button.ladda-button.sm {
        font-size: 0.875rem;
      }

      .only-button {
        min-width: 300px;
      }

      .email-button-wrapper {
        .email-button-class {
          min-width: 130px;

          .email-button-arrow-wrapper {
            padding-left: 18px;
            svg {
              fill: currentColor;
              height: 12px;
            }
          }
        }
      }
    }

    .notice-text-wrapper {
      margin-top: 8px;
      padding-left: 24px;
      font-size: 0.8125rem;
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .email-button {
      &:not(.force-horizontal) {
        .email-button-inner {
          flex-direction: column;
          text-align: center;

          input {
            margin-bottom: 16px;
            font-size: 0.875rem;
            padding: 16px 8px;
            margin-right: 0;
            text-align: center;
            min-width: 264px;
          }

          .email-button-wrapper {
            width: 100%;
            button {
              font-size: 0.875rem;
              padding: 16px 48px;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
