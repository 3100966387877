import { componentStyles } from "./invite-tab-component.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import LiveBoardTabComponent from "../live-board-tab-component";
import { inviteGridItems } from "./invite-tab-consts";

const InviteTabContentComponent = (props) => {
  const { translate, onCardClick } = props;
  return (
    <div className="invite-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.inviteTab.description")}
        gridItems={inviteGridItems}
        hideCardParagraph={true}
        onCardClick={onCardClick}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(InviteTabContentComponent);
