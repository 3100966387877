import css from "styled-jsx/css";
import colors from "styles/colors";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .paragraph-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1 1 auto;

    &.center {
      align-items: center;
      text-align: center;

      .topic,
      .title,
      .body {
        max-width: 650px;
      }
    }

    &.right {
      align-items: flex-end;
    }

    &.left {
      align-items: flex-start;
    }

    .topic {
      font-size: 0.875rem;
      margin-bottom: 16px;
      font-weight: 500;
    }

    .title {
      .colorful-title,
      .colored {
        line-height: 1.3;
        font-size: 2.25rem;
        font-weight: 700;
      }
    }

    .signup-form-with-decorations-wrapper {
      padding-top: 16px;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .paragraph-component {
      .title {
        .colored,
        .colorful-title {
          font-size: 1.75rem;
        }
      }
    }
  }
`;

export const bodyStyles = css.global`
  .body {
    font-size: 0.875rem;
    padding-top: 16px;
    width: 100%;

    .inner-body {
      display: block;
      line-height: 27px;
    }
  }
`;

export const bottomLinkStyles = css.global`
  .bottom-link-wrapper {
    margin-top: 24px;
    a {
      color: ${colors["black"]};

      &:hover {
        color: ${colors["brand-purple"]};
      }
    }
  }
`;
