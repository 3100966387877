import { PureComponent } from "react";

export default class TrustpilotScript extends PureComponent {
  render() {
    return (
      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
        defer
      />
    );
  }
}
