import { PureComponent, Fragment } from "react";

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

export default class IntercomScript extends PureComponent {
  intercomSettingsScriptGenerator(userData, isProduction) {
    const appId = INTERCOM_APP_ID;
    const { userId, name, email, additionalData } = userData;
    const additionalDataStr = additionalData ? JSON.stringify(additionalData) : "{}";
    if (userId) {
      // Logged in users
      return `
        window['intercomSettings'] = Object.assign({}, {
          app_id: "${appId}",
          user_id: "${userId}",
          name: "${name}", // Full name
          email: "${email}" // Email address          
        }, ${additionalDataStr});
        
      `;
    } else {
      return `
        window['intercomSettings'] = {
          app_id: "${appId}"
        };
      `;
    }
  }
  intercomScriptGenerator() {
    return `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pqqwlkj5';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
    `;
  }

  render() {
    const { userData, isProduction } = this.props;
    return (
      <Fragment>
        <script
          async
          defer
          dangerouslySetInnerHTML={{
            __html: this.intercomSettingsScriptGenerator(userData, isProduction)
          }}
        />
        <script
          async
          defer
          dangerouslySetInnerHTML={{
            __html: this.intercomScriptGenerator()
          }}
        />
      </Fragment>
    );
  }
}
