import css from "styled-jsx/css";

export const videoComponentStyles = css.global`
  .video-component {
    height: 100%;
    outline: none;
    &.hide-black-bars {
      height: 47vw;
      .react-player-video-component video {
        object-fit: cover;
      }
    }
  }
`;
