import union from "lodash/union";

// in cloudinarry, upload both to staging and production (so you'll be able to test first)
export const SUPPORTED_LOGOS_NUMBER_OPTIONS = ["5", "9", "10", "12"];
export const SUPPORTED_LOGOS_NUMBER_FOR_NARROW_DISPLAY_OPTIONS = ["5", "9", "10", "12"];

export const LOGO_COMPANY_FIELDS = {
  GENERAL: "General",
  GENERAL_OLD: "General_Old",
  HEALTHCARE: "Healthcare",
  ENTERTAINMENT: "Entertainment",
  MARKETING: "Marketing",
  ENTERPRISE: "Enterprise",
  SOFTWARE: "Software",
  CONSTRUCTION: "Construction",
  RETAIL: "Retail"
};

export const LOGO_COMPANY_COUNTRIES = {
  MEXICO: "Mexico",
  BRAZIL: "Brazil",
  FRANCE: "France",
  GERMANY: "Germany",
  ITALY: "Italy",
  JAPAN: "Japan"
};

const Uber = {
  url: "/static/img/customers/logos-v2/uber.png",
  name: "Uber"
};
// const Adobe = {
//   url: "/static/img/customers/logos-v2/adobe.png",
//   name: "Adobe"
// };
const UniversalMusicGroup = {
  url: "/static/img/customers/logos-v2/universal.png",
  name: "Universal Music Group"
};

const Hulu = {
  url: "/static/img/customers/logos-v2/hulu.png",
  name: "Hulu"
};
// const Unilever = {
//   url: "/static/img/customers/logos-v2/unilever.png",
//   name: "Unilever"
// };
// const Abbott = {
//   url: "/static/img/customers/logos-v2/abbott.png",
//   name: "Abbott"
// };
const Genpact = { url: "/static/img/customers/logos-v2/genpact.png", name: "Genpact" };
// const Humana = {
//   url: "/static/img/customers/logos-v2/humana.png",
//   name: "Humana"
// };
const FrontierDevelopments = {
  url: "/static/img/customers/logos-v2/frontier-developments-seeklogo.png",
  name: "Frontier Developments"
};
const Zippo = { url: "/static/img/customers/logos-v2/zippo.png", name: "Zippo" };
const Alexion = { url: "/static/img/customers/logos-v2/alexion.png", name: "Alexion" };
const Anadarko = { url: "/static/img/customers/logos-v2/anadarko.png", name: "Anadarko" };
// const Gameloft = {
//   url: "/static/img/customers/logos-v2/gameloft.png",
//   name: "Gameloft"
// };
const Spinmaster = { url: "/static/img/customers/logos-v2/spinmaster.png", name: "Spinmaster" };
const InteriorLogic = {
  url: "/static/img/customers/logos-v2/interior_logic.png",
  name: "Interior Logic"
};
const AmericanFamilyInsurance = {
  url: "/static/img/customers/logos-v2/american_family_insurance.png",
  name: "American Family Insurance"
};
const Deezer = {
  url: "/static/img/customers/logos-v2/deezer.png",
  name: "Deezer"
};
const Barvado = { url: "/static/img/customers/logos-v2/barvado.png", name: "Barvado" };
const Briggs = {
  url: "/static/img/customers/logos-v2/briggs_stratton_corporation.png",
  name: "Briggs"
};
const CarndoChemrisk = {
  url: "/static/img/customers/logos-v2/cardno_chemrisk.png",

  name: "Carndo Chemrisk"
};
// const ReiCom = { url: "/static/img/customers/logos-v2/rei.com.png", name: "Reicom" };
const AeroMexico = {
  url: "/static/img/customers/logos-v2/aeromexico.png",

  name: "Aero Mexico"
};
const ProcoreTechnology = {
  url: "/static/img/customers/logos-v2/procore_technology.png",

  name: "Procore Technology"
};
// const FoxNews = { url: "/static/img/customers/logos-v2/fox_news.png", name: "Fox News" };
// const AllState = { url: "/static/img/customers/logos-v2/allstate.png", name: "Allstate" };
const Outbrain = { url: "/static/img/customers/logos-v2/outbrain.png", name: "Outbrain" };
// const Delta = { url: "/static/img/customers/logos-v2/delta.png",  name: "Delta" };
const Emerson = { url: "/static/img/customers/logos-v2/emerson.png", name: "Emerson" };
const CardinalHealth = {
  url: "/static/img/customers/logos-v2/cardinal_health.png",

  name: "Cardinal Health"
};
// const IBM = { url: "/static/img/customers/logos-v2/ibm.png",  name: "IBOM" };
// const WeWork = { url: "/static/img/customers/logos-v2/wework.png",  name: "WeWork" };
// const MITFederalCredit = {
//   url: "/static/img/customers/logos-v2/mit_federal_credit_union.png",
//   name: "MIT Federal Credit"
// };
const Marriott = { url: "/static/img/customers/logos-v2/marriott.png", name: "Marriott" };
const Mckesson = { url: "/static/img/customers/logos-v2/mckesson.png", name: "Mckesson" };
// const Corning = { url: "/static/img/customers/logos-v2/corning.png", name: "Corning" };
// const Tesla = { url: "/static/img/customers/logos-v2/tesla.png", name: "Tesla" };
// const Naic = { url: "/static/img/customers/logos-v2/naic.png", name: "Naic" };
const Paypal = {
  url: "/static/img/customers/logos-v2/paypal.png",

  name: "Paypal"
};
const Glossier = { url: "/static/img/customers/logos-v2/glossier.png", name: "Glossier" };
const Kroger = { url: "/static/img/customers/logos-v2/kroger.png", name: "Kroger" };
const Walmart = {
  url: "/static/img/customers/logos-v2/walmart.png",

  name: "Walmart"
};
const NipponOil = {
  url: "/static/img/customers/logos-v2/nippon_oil_corporation.png",
  name: "NipponOil"
};
// const Visa = {
//   url: "/static/img/customers/logos-v2/visa.png",

//   name: "Visa"
// };
// const CultureTrip = {
//   url: "/static/img/customers/logos-v2/culture_trip.png",
//   name: "Culture Trip"
// };
// const ClasenChocolate = {
//   url: "/static/img/customers/logos-v2/clasen_of_chocolate.png",
//   name: "Clasen Chocolate"
// };
// const Aecom = { url: "/static/img/customers/logos-v2/aecom.png", name: "Aecom" };
const ADP = { url: "/static/img/customers/logos-v2/adp.png", name: "ADP" };
const BD = {
  url: "/static/img/customers/logos-v2/bd.png",
  name: "BD"
};
const HCAHealthcare = {
  url: "/static/img/customers/logos-v2/hca_healthcare.png",
  name: "HCA Healthcare"
};
const Peloton = { url: "/static/img/customers/logos-v2/peloton.png", name: "Peloton" };
const TennisAustralia = {
  url: "/static/img/customers/logos-v2/tennis_australia.png",
  name: "Tennis Australia"
};
// const CBRE = { url: "/static/img/customers/logos-v2/cbre.png", name: "CBRE" };
const Exelon = { url: "/static/img/customers/logos-v2/exelon.png", name: "Exelon" };
const HenrySchein = {
  url: "/static/img/customers/logos-v2/henry_schein.png",
  name: "Henry Schein"
};
const Davita = {
  url: "/static/img/customers/logos-v2/davita.png",
  name: "Davita"
};
// const Oneok = { url: "/static/img/customers/logos-v2/oneok.png", name: "Oneok" };
// const Parker = { url: "/static/img/customers/logos-v2/parker.png", name: "Parker" };
const PVH = { url: "/static/img/customers/logos-v2/pvh.png", name: "PVH" };
// const Sempra = { url: "/static/img/customers/logos-v2/sempra.png", name: "Sampra" };
const Stryker = {
  url: "/static/img/customers/logos-v2/stryker.png",
  name: "Stryker"
};
const ThermoFisher = {
  url: "/static/img/customers/logos-v2/thermo_fisher.png",
  name: "Thermo Fisher"
};
const Univar = { url: "/static/img/customers/logos-v2/univar.png", name: "Univar" };
const UsFoods = { url: "/static/img/customers/logos-v2/us_foods.png", name: "UsFoods" };
const WestRock = { url: "/static/img/customers/logos-v2/westrock.png", name: "WestRock" };
// const YUM = { url: "/static/img/customers/logos-v2/yum.png", name: "YUM" };
const Caseys = { url: "/static/img/customers/logos-v2/caseys.png", name: "Caseys" };
// const Ascential = { url: "/static/img/customers/logos-v2/ascential.png", name: "Ascential" };
const CrossRiverBank = {
  url: "/static/img/customers/logos-v2/cross_river_bank.png",
  name: "Cross River Bank"
};
const PrimonialHolding = {
  url: "/static/img/customers/logos-v2/primonial_holding.png",
  name: "Primonial Holding"
};
const Prohibition_vapes = {
  url: "/static/img/customers/logos-v2/prohibition_vapes.png",
  name: "Prohibition Vapes"
};
const AlbericiGroup = {
  url: "/static/img/customers/logos-v2/alberici_group.png",
  name: "Alberic Group"
};
const UniversityOfLouisville = {
  url: "/static/img/customers/logos-v2/university_of_louisville.png",
  name: "University Of Louisville"
};
const GamingLabs = { url: "/static/img/customers/logos-v2/gaming_labs.png", name: "Gaming Labs" };
const Terracon = { url: "/static/img/customers/logos-v2/terracon.png", name: "Terracon" };
const VeriskAnalytics = {
  url: "/static/img/customers/logos-v2/verisk_analytics.png",
  name: "Verisk Analytics"
};
// const GuidWellSource = {
//   url: "/static/img/customers/logos-v2/guidewell_source.png",
//   name: "GuideWell Source"
// };
const CushmanAndWakefield = {
  url: "/static/img/customers/logos-v2/cushman_and_wakefield.png",
  name: "Cushman & Wakefield"
};
const AmericanDentalAssociation = {
  url: "/static/img/customers/logos-v2/american_dental_association.png",
  name: "American Dental Association"
};
const Transport = {
  url: "/static/img/customers/logos-v2/transport-for-greater-manchester.png",
  name: "Transport"
};
// const Web = { url: "/static/img/customers/logos-v2/web.com.png", name: "Web" };
const HamiltonLaneAdvisors = {
  url: "/static/img/customers/logos-v2/hamilton_lane_advisors.png",
  name: "Hamilton Land Advisors"
};
// const DineInFreshDbaPlated = {
//   url: "/static/img/customers/logos-v2/dineinfresh_dba_plated.png",
//   name: "DineInFresh"
// };
const SouthTexasCollege = {
  url: "/static/img/customers/logos-v2/south_texas_college.png",
  name: "South Texas College"
};
const LMBopco = { url: "/static/img/customers/logos-v2/lmb_opco.png", name: "Lmb Opco" };
const BBC = {
  url: "/static/img/customers/logos-v2/bbc.png",
  name: "BBC"
};
// const FrankeKitchenSystems = {
//   url: "/static/img/customers/logos-v2/franke_kitchen_systems.png",
//   name: "Franke Kitchen Systems"
// };
const InsightVenturePartners = {
  url: "/static/img/customers/logos-v2/insight_venture_partners.png",
  name: "Insights Venture Partners"
};
// const Kumon = { url: "/static/img/customers/logos-v2/kumon.png", name: "Kumon" };
const LiebermanResearch = {
  url: "/static/img/customers/logos-v2/lieberman_research.png",
  name: "Lieberman Research"
};
const Novocure = { url: "/static/img/customers/logos-v2/novocure.png", name: "Novocure" };
// const Tarkett = { url: "/static/img/customers/logos-v2/tarkett.png", name: "Tarkett" };
// const IronSource = { url: "/static/img/customers/logos-v2/ironsource.png",  name: "IronSource" };

// new country logos
const BnpParibas = {
  url: "/static/img/customers/logos-v2/bnpparibas.png",
  name: "BNP Paribas"
};
// const Bosch = {
//   url: "/static/img/customers/logos-v2/bosch.png",
//   name: "Bosch"
// };
const CocaCola = {
  url: "/static/img/customers/logos-v2/coca_cola.png",
  name: "Coca Cola"
};
const Globoplay = {
  url: "/static/img/customers/logos-v2/globoplay.png",
  name: "Globoplay"
};
const Loreal = {
  url: "/static/img/customers/logos-v2/loreal.png",
  name: "Loreal"
};
const RaiaDrogasil = {
  url: "/static/img/customers/logos-v2/raia_drogasil.png",
  name: "Raia Drogasil"
};

const Omelete = {
  url: "/static/img/customers/logos-v2/omelete.png",
  name: "Omelete"
};

const NBC = {
  url: "/static/img/customers/logos-v2/nbc-logo.png",
  name: "NBC"
};

const DiscoveryChannel = {
  url: "/static/img/customers/logos-v2/discovery_channel_logo.png",
  name: "Discovery Channel"
};

const SkyBrasil = {
  url: "/static/img/customers/logos-v2/sky-brasil.jpg",
  name: "Sky Brasil"
};

const Carrefour = {
  url: "/static/img/customers/logos-v2/carrefour.png",
  name: "Carrefour"
};

const Sanofi = {
  url: "/static/img/customers/logos-v2/sanofi.png",
  name: "Sanofi"
};

const Accor = {
  url: "/static/img/customers/logos-v2/accor.png",
  name: "Accor"
};

const Bayer = {
  url: "/static/img/customers/logos-v2/bayer.png",
  name: "Bayer"
};

const Quintoandar = {
  url: "/static/img/customers/logos-v2/quintoandar.png",
  name: "Quintoandar"
};

// const Wildlife = {
//   url: "/static/img/customers/logos-v2/wildlife.png",
//   name: "Wildlife"
// };

// const Magalu = {
//   url: "/static/img/customers/logos-v2/magalu.png",
//   name: "Magalu"
// };

// const Nubank = {
//   url: "/static/img/customers/logos-v2/nubank.png",
//   name: "Nubank"
// };
const BtgPactual = {
  url: "/static/img/customers/logos-v2/btg-pactual.png",
  name: "BtgPactual"
};

const Ifood = {
  url: "/static/img/customers/logos-v2/ifood.png",
  name: "Ifood"
};

const Wix = {
  url: "/static/img/customers/logos-v2/wix.png",
  name: "Wix"
};

const Eni = {
  url: "/static/img/customers/logos-v2/eni.png",
  name: "Eni"
};

// const Moncler = {
//   url: "/static/img/customers/logos-v2/moncler.png",
//   name: "Moncler"
// };

// const Prometeia = {
//   url: "/static/img/customers/logos-v2/prometeia.png",
//   name: "Prometeia"
// };

const AsRoma = {
  url: "/static/img/customers/logos-v2/as_roma.png",
  name: "AS Roma"
};

const RomaMobilita = {
  url: "/static/img/customers/logos-v2/mobilita.png",
  name: "Roma Mobilita"
};

const SoftwareAG = {
  url: "/static/img/customers/logos-v2/software_ag.png",
  name: "Software AG"
};

const BauerMediaGroup = {
  url: "/static/img/customers/logos-v2/bauer-media-group.png",
  name: "Bauer Media Group"
};

// const NHL = {
//   url: "/static/img/customers/logos-v2/nhl.png",
//   name: "NHL"
// };

// const Hubspot = {
//   url: "/static/img/customers/logos-v2/hubspot.png",
//   name: "Hubspot"
// };

const MarsWrigley = {
  url: "/static/img/customers/logos-v2/mars_wrigley.png",
  name: "Mars Wrigley"
};

const McCan = {
  url: "/static/img/customers/logos-v2/maccan.png",
  name: "McCan"
};

const Canva = {
  url: "/static/img/customers/logos-v2/canva.png",
  name: "Canva"
};

// const ElectronicArts = {
//   url: "/static/img/customers/logos-v2/Electronic_Arts.png",
//   name: "Electronic Arts"
// };

// const NBCUniversal = {
//   url: "/static/img/customers/logos-v2/NBCUniversal.png",
//   name: "NBC Universal"
// };

const Ebanx = {
  url: "/static/img/customers/logos-v2/ebanx.png",
  name: "Ebanx"
};

const Movile = {
  url: "/static/img/customers/logos-v2/movile.png",
  name: "Movile"
};

const Riachuelo = {
  url: "/static/img/customers/logos-v2/riachuelo.png",
  name: "Riachuelo"
};

const Even = {
  url: "/static/img/customers/logos-v2/even.png",
  name: "Even"
};

const Playtech = {
  url: "/static/img/customers/logos-v2/playtech.png",
  name: "Playtech"
};

const Datacom = {
  url: "/static/img/customers/logos-v2/datacom.png",
  name: "Datacom"
};

const Lightspeed = {
  url: "/static/img/customers/logos-v2/lightspeed.png",
  name: "Lightspeed"
};

// const Nautica = {
//   url: "/static/img/customers/logos-v2/nautica.png",
//   name: "Nautica"
// };

const Eisai = {
  url: "/static/img/customers/logos-v2/Eisai.png",
  name: "Eisai"
};
const Wella = {
  url: "/static/img/customers/logos-v2/Wella.png",
  name: "Wella"
};
const NipponGases = {
  url: "/static/img/customers/logos-v2/nippon.png",
  name: "NipponGases"
};
const HoltCat = {
  url: "/static/img/customers/logos-v2/HoltCat.png",
  name: "HoltCat"
};

const BMW = {
  url: "/static/img/customers/logos-v2/BMW.png",
  name: "BMW"
};

const Falk = {
  url: "/static/img/customers/logos-v2/Falk.png",
  name: "Falk"
};

// const Motorola = {
//   url: "/static/img/customers/logos-v2/motorola.png",
//   name: "Motorola"
// };

const Aesop = {
  url: "/static/img/customers/Aesop.png",
  name: "Aesop"
};

const Levis = {
  url: "/static/img/customers/levis.png",
  name: "Levis"
};

const Carlsberg = {
  url: "/static/img/customers/carlsberg.png",
  name: "Carlsberg"
};

const Lionsgate = {
  url: "/static/img/customers/logos-v2/lionsgate.png",
  name: "Lionsgate"
};

const Herdez = {
  url: "/static/img/customers/logos-v2/herdez.png",
  name: "Herdez"
};

const CruzRuja = {
  url: "/static/img/customers/logos-v2/cruz_ruja.png",
  name: "Cruz Ruja"
};

// const Prosegur = {
//   url: "/static/img/customers/logos-v2/prosegur.png",
//   name: "Prosegur"
// };

const Coppel = {
  url: "/static/img/customers/logos-v2/coppel.png",
  name: "Coppel"
};

const Leboncoin = {
  url: "/static/img/customers/logos-v2/leboncoin.png",
  name: "Leboncoin"
};

const Axa = {
  url: "/static/img/customers/logos-v2/axa.png",
  name: "Axa"
};

const Farfetch = {
  url: "/static/img/customers/logos-v2/farfetch.png",
  name: "Farfetch"
};

const PetLove = {
  url: "/static/img/customers/logos-v2/petlove.png",
  name: "PetLove"
};

const ArytonSenna = {
  url: "/static/img/customers/logos-v2/instituto_ayrton_senna.png",
  name: "Aryton Senna"
};

const Telefonica = {
  url: "/static/img/customers/logos-v2/o2_Telefonica.png",
  name: "Telefonica"
};

const COMPANIES_ASSETS = [
  Uber,
  Hulu,
  Genpact,
  FrontierDevelopments,
  Zippo,
  Alexion,
  Anadarko,
  Spinmaster,
  InteriorLogic,
  AmericanFamilyInsurance,
  Deezer,
  Barvado,
  Briggs,
  CarndoChemrisk,
  Canva,
  Glossier,
  Lionsgate,
  AeroMexico,
  ProcoreTechnology,
  Outbrain,
  Emerson,
  CardinalHealth,
  Marriott,
  Mckesson,
  Paypal,
  Kroger,
  Walmart,
  NipponOil,
  ADP,
  BD,
  HCAHealthcare,
  Peloton,
  TennisAustralia,
  Exelon,
  HenrySchein,
  Davita,
  PVH,
  Stryker,
  ThermoFisher,
  Univar,
  UsFoods,
  WestRock,
  Caseys,
  CrossRiverBank,
  PrimonialHolding,
  Prohibition_vapes,
  AlbericiGroup,
  UniversityOfLouisville,
  GamingLabs,
  Terracon,
  VeriskAnalytics,
  CushmanAndWakefield,
  AmericanDentalAssociation,
  Transport,
  HamiltonLaneAdvisors,
  SouthTexasCollege,
  LMBopco,
  BBC,
  InsightVenturePartners,
  LiebermanResearch,
  Novocure,
  BnpParibas,
  CocaCola,
  Globoplay,
  RaiaDrogasil,
  Omelete,
  NBC,
  DiscoveryChannel,
  SkyBrasil,
  Carrefour,
  Sanofi,
  Accor,
  Bayer,
  Quintoandar,
  BtgPactual,
  Ifood,
  UniversalMusicGroup,
  Wix
];

const LOGOS_BY_COUNTRIES = {
  [LOGO_COMPANY_COUNTRIES.BRAZIL]: [
    Farfetch,
    Ifood,
    BtgPactual,
    Quintoandar,
    Paypal,
    RaiaDrogasil,
    PetLove,
    Movile,
    ArytonSenna,
    Movile
  ],
  [LOGO_COMPANY_COUNTRIES.FRANCE]: [Uber, Paypal, Carrefour, Sanofi, Accor, Axa, Leboncoin, BnpParibas, CocaCola],
  [LOGO_COMPANY_COUNTRIES.MEXICO]: [Herdez, UniversalMusicGroup, CruzRuja, AeroMexico, Uber, CocaCola, BMW, Coppel],
  [LOGO_COMPANY_COUNTRIES.GERMANY]: [SoftwareAG, BauerMediaGroup, Paypal, HoltCat, Uber, CocaCola, Bayer, Telefonica, BBC],
  [LOGO_COMPANY_COUNTRIES.ITALY]: [Eni, AsRoma, RomaMobilita, CocaCola],
  [LOGO_COMPANY_COUNTRIES.JAPAN]: [
    Wella,
    UniversalMusicGroup,
    Canva,
    NipponGases,
    Zippo,
    CocaCola,
    Hulu,
    Eisai,
    HoltCat
  ]
};

const LOGOS_BY_CLUSTER = {
  [LOGO_COMPANY_FIELDS.HEALTHCARE]: [CardinalHealth, BD, HCAHealthcare, HenrySchein, Stryker],
  [LOGO_COMPANY_FIELDS.ENTERTAINMENT]: [Hulu, FrontierDevelopments, Deezer],
  [LOGO_COMPANY_FIELDS.MARKETING]: [
    CocaCola,
    McCan,
    UniversalMusicGroup,
    Hulu,
    Wix,
    MarsWrigley,
    Glossier,
    Loreal,
    HoltCat
  ],
  [LOGO_COMPANY_FIELDS.ENTERPRISE]: [
    Wix,
    Genpact,
    MarsWrigley,
    Canva,
    CocaCola,
    Lionsgate,
    Glossier,
    BBC,
    HoltCat,
    BD
  ],
  [LOGO_COMPANY_FIELDS.SOFTWARE]: [Wix, Outbrain, Playtech, Datacom, Lightspeed, Canva],
  [LOGO_COMPANY_FIELDS.CONSTRUCTION]: [HoltCat, Falk, InteriorLogic, AlbericiGroup, BMW],
  [LOGO_COMPANY_FIELDS.RETAIL]: [Lionsgate, Aesop, BMW, Carrefour, Deezer, Levis, Loreal, Hulu, Carlsberg],
  [LOGO_COMPANY_FIELDS.GENERAL]: [
    Genpact,
    HoltCat,
    Canva,
    CocaCola,
    Lionsgate,
    Hulu,
    BD,
    Glossier,
    UniversalMusicGroup
  ],
  [LOGO_COMPANY_FIELDS.GENERAL_OLD]: [Uber, Hulu, Genpact, FrontierDevelopments, Zippo, Alexion, Anadarko]
};

export const getCompaniesAssets = (options = {}) => {
  let firstAssets = [];
  if (options.country && LOGOS_BY_COUNTRIES[options.country]) {
    firstAssets = LOGOS_BY_COUNTRIES[options.country];
  } else if (options.companyField && LOGOS_BY_CLUSTER[options.companyField]) {
    firstAssets = LOGOS_BY_CLUSTER[options.companyField];
  }

  return union([...firstAssets, ...COMPANIES_ASSETS]);
};

export const NUMBER_OF_COMPANIES = "9";
