import { Children, cloneElement } from "react";
import classnames from "classnames";
import PictureWithLinkComponent from "/components/core/picture-with-link/picture-with-link-component";
import VideoComponent from "components/core/video/video-component";
import { getFileExtension } from "server/services/files-service/files-service";

import { withRouter } from "next/router";
import { withGenericConfig } from "/contexts/generic-config-context";
import { getDynamicAssetConfigForPath } from "/client/services/cluster-config-service/cluster-config-service";
import { getImageUrl } from "/client/services/dynamic-asset-param-service/dynamic-asset-param-service";
import WindowMask from "segments/desktop/core-components/window-mask/window-mask";
import { ConditionalWrapper } from "utils/react";
import { componentStyles } from "./asset-component.scss";

const MP4 = "mp4";
const GIF = "gif";

const AssetComponent = (props) => {
  const getAssetsConfig = () => {
    const { image, video, useDynamicAsset, clusterConfig, dynamicAssetParamPath, dynamicAssetParam, activePagePath } =
      props;

    if (dynamicAssetParamPath && dynamicAssetParam) {
      return {
        image: {
          ...image,
          src: getImageUrl(dynamicAssetParamPath, dynamicAssetParam)
        }
      };
    }

    const dynamicAssetConfig = useDynamicAsset && getDynamicAssetConfigForPath(clusterConfig, activePagePath);
    if (!dynamicAssetConfig) {
      return { image, video };
    }

    if (getFileExtension(dynamicAssetConfig.src) === MP4) {
      return { video: { ...video, src: { formats: [dynamicAssetConfig.src] } } };
    }
    return { image: { ...image, ...dynamicAssetConfig } };
  };

  const renderAsset = () => {
    const { width, convertGifToVideo, imageClassName, assetQuality, hideControls, onEnded, onDuration, hideBlackBars } =
      props;
    const { image, video } = getAssetsConfig();

    if (video?.src) {
      const { formats } = video.src;
      return (
        <VideoComponent
          {...video}
          {...video.src}
          formats={formats}
          assetQuality={assetQuality}
          hideControls={hideControls}
          poster={video?.poster}
          onEnded={onEnded}
          onDuration={onDuration}
          hideBlackBars={hideBlackBars}
        />
      );
    }

    if (image?.src) {
      if (getFileExtension(image.src) === GIF && convertGifToVideo) {
        return (
          <VideoComponent
            loop={true}
            autoPlay={true}
            formats={[image.src]}
            assetQuality={assetQuality}
            ariaLabel={image?.alt}
            hideControls={hideControls}
          />
        );
      }

      return (
        <PictureWithLinkComponent
          className={classnames("main-image", imageClassName)}
          {...image}
          specificImageStyle={{ width }}
          assetQuality={assetQuality}
        />
      );
    }

    return null;
  };

  const { width, className, style, useWindowMask } = props;

  const childrenWithWindowMaskClass = (children) => (
    <WindowMask>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          className: "with-window-mask"
        });
      })}
    </WindowMask>
  );

  return (
    <div className={classnames("asset-inner", className)} style={{ ...style, width }}>
      <ConditionalWrapper condition={useWindowMask} wrapper={childrenWithWindowMaskClass}>
        {renderAsset()}
      </ConditionalWrapper>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

AssetComponent.defaultProps = {
  convertGifToVideo: false,
  useWindowMask: false
};

export default withGenericConfig(withRouter(AssetComponent));
