import {
  HUBSPOT_API_FIRSTNAME,
  HUBSPOT_API_LASTNAME,
  HUBSPOT_API_EMAIL,
  HUBSPOT_API_JOB,
  HUBSPOT_API_PHONE,
  HUBSPOT_API_COMPANY,
  HUBSPOT_API_COMPANY_SIZE,
  HUBSPOT_API_HOW_CAN_HELP
} from "./hubspot-constants";

import {
  inputTypes,
  MAXIMUM_LONG_INPUT_LENGTH,
  FIRSTNAME_INPUT,
  LASTNAME_INPUT,
  PHONE_INPUT,
  EMAIL_INPUT,
  COMPANY_SIZE_INPUT
} from "client/services/form-service/form-inputs";
import { SMALLEST_COMPANY_SIZE } from "client/services/form-service/form-constants";

const validateHowCanWeHelpFieldRequiredByCompanySize = (currentValue, allInputsValues) => {
  const companySize = allInputsValues[HUBSPOT_API_COMPANY_SIZE];
  if (companySize === SMALLEST_COMPANY_SIZE) {
    return !!currentValue; //this can't be empty because CS need this value
  }
  return true;
};

export const HUBSPOT_FIRSTNAME_INPUT = {
  ...FIRSTNAME_INPUT,
  inputName: HUBSPOT_API_FIRSTNAME,
  isRequired: true
};

export const HUBSPOT_LASTNAME_INPUT = {
  ...LASTNAME_INPUT,
  inputName: HUBSPOT_API_LASTNAME,
  isRequired: true
};

export const HUBSPOT_EMAIL_INPUT = {
  ...EMAIL_INPUT,
  inputName: HUBSPOT_API_EMAIL,
  isRequired: true
};

export const HUBSPOT_JOBTITLE_INPUT = {
  id: "job_title",
  inputName: HUBSPOT_API_JOB,
  type: inputTypes.TEXT,
  isRequired: false,
  title: "contactSales.hubspotForm.form.fieldTitle.jobTitle",
  validate: (val) => !val || val.length < MAXIMUM_LONG_INPUT_LENGTH,
  errorMessage: "contactSales.hubspotForm.form.fieldErrorMsg.jobTitle"
};

export const HUBSPOT_PHONE_INPUT = {
  ...PHONE_INPUT,
  inputName: HUBSPOT_API_PHONE,
  isRequired: true
};

export const HUBSPOT_COMPANY_INPUT = {
  id: "company_name",
  inputName: HUBSPOT_API_COMPANY,
  type: inputTypes.TEXT,
  isRequired: true,
  title: "contactSales.hubspotForm.form.fieldTitle.companyName"
};

export const HUBSPOT_COMPANYSIZE_INPUT = {
  ...COMPANY_SIZE_INPUT,
  inputName: HUBSPOT_API_COMPANY_SIZE,
  isRequired: true
};

export const getHubspotHowCanHelpInput = (overrideParams = {}) => {
  return {
    id: "how_can_help",
    inputName: HUBSPOT_API_HOW_CAN_HELP,
    type: inputTypes.TEXT_AREA,
    isRequired: false,
    placeholder: overrideParams?.placeholderKey || "contactSales.hubspotForm.form.fieldPlaceholder.howCanHelp",
    title: overrideParams?.titleKey || "contactSales.hubspotForm.form.fieldTitle.howCanHelp",
    validateOnFormSubmission: validateHowCanWeHelpFieldRequiredByCompanySize,
    errorMessage: "forms.detailsField.requiredSoWeCanHelpError"
  };
};

export const HUBSPOT_HOWPLANUSE_INPUT = {
  id: "how_plan_use",
  inputName: HUBSPOT_API_HOW_CAN_HELP, //same placeholder and API field name as how can help - just different question title
  type: inputTypes.TEXT_AREA,
  isRequired: false,
  placeholder: "contactSales.hubspotForm.form.fieldPlaceholder.howCanHelp",
  title: "contactSales.hubspotForm.form.fieldTitle.howPlanToUse",
  validateOnFormSubmission: validateHowCanWeHelpFieldRequiredByCompanySize,
  errorMessage: "forms.detailsField.requiredSoWeCanHelpError"
};
