import { PureComponent } from "react";
import { componentStyles } from "./signup-and-youtube-combo-button.scss";
import DefaultProps from "./default-props";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import YouTubePopUpButton from "segments/desktop/core-components/button/youtube-popup-button/youtube-popup-button";

export default class SignupAndYoutubeComboButton extends PureComponent {
  render() {
    const { youtubePopupButtonConfig, signupButtonConfig } = this.props;

    return (
      <div className="signup-and-youtube-combo-button">
        <div className="regular-button-wrapper">
          <SignupButton {...signupButtonConfig}></SignupButton>
        </div>
        <div className="secondary-button-wrapper">
          <YouTubePopUpButton {...youtubePopupButtonConfig}></YouTubePopUpButton>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
SignupAndYoutubeComboButton.defaultProps = DefaultProps;
