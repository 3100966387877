import {
  shouldGetContactSalesTypeformEmeaTest,
  shouldGetContactSalesTypeformAnzTest
} from "services/ab-tests/acquisition-ab-test-service";
import {
  EMEA_GEO_REGION,
  AUSTRALIA_NEW_ZEALAND_GEO_REGION,
  getContactSalesRegionByCountry
} from "constants/typeform-contact-sales";

const regionToTestMap = {
  [EMEA_GEO_REGION]: shouldGetContactSalesTypeformEmeaTest,
  [AUSTRALIA_NEW_ZEALAND_GEO_REGION]: shouldGetContactSalesTypeformAnzTest
};

export const getTestIsNewVariantByRegion = ({ region, abTests, sendBigBrainEvent }) => {
  const testByRegion = regionToTestMap[region] || (() => false);
  return testByRegion(abTests, sendBigBrainEvent);
};

export const isEligibleToGetTypeform = (region) => {
  return region === EMEA_GEO_REGION || region === AUSTRALIA_NEW_ZEALAND_GEO_REGION;
};

export const shouldGetTypeformExperience = ({
  abTests,
  cloudfrontViewerCountry,
  testTypeformContactSales,
  forceTypeformContactSalesExperience,
  sendBigBrainEvent = false
}) => {
  if (forceTypeformContactSalesExperience) return true;

  const region = getContactSalesRegionByCountry(cloudfrontViewerCountry);
  return (
    isEligibleToGetTypeform(region) &&
    (testTypeformContactSales || getTestIsNewVariantByRegion({ region, abTests, sendBigBrainEvent }))
  );
};
