import { componentStyles } from "./notifications-tab-component.scss";
import LiveBoardTabComponent from "../live-board-tab-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import { notificationsGridItems } from "./notifications-tab-consts.js";

const NotificationsTabContentComponent = (props) => {
  const { translate, onCardClick } = props;
  return (
    <div className="notifications-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.notificationsTab.description")}
        gridItems={notificationsGridItems}
        onCardClick={onCardClick}
        hideCardParagraph={true}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(NotificationsTabContentComponent);
