import { PureComponent } from "react";
import { componentStyles } from "./grid-image.scss";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import DefaultProps from "./default-props";
import { getImagePositionStyles } from "segments/desktop/core-components/images-grid/images-grid-service";
export default class GridImage extends PureComponent {
  renderPicture() {
    const { src, alt, isDecorative, link, imageStyle } = this.props;
    const { href, isNewTab } = link;
    const target = isNewTab ? "_blank" : "_self";

    return (
      <PictureWithLinkComponent
        src={src}
        alt={alt}
        isDecorative={isDecorative}
        className="image"
        target={target}
        href={href}
        specificImageStyle={imageStyle}
      />
    );
  }

  render() {
    const { height, width, imagePosition } = this.props;
    const imagePositionStyles = getImagePositionStyles(imagePosition);

    return (
      <div className="grid-image" style={{ height, width, ...imagePositionStyles }}>
        {this.renderPicture()}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

GridImage.defaultProps = DefaultProps;
