import { PAGE_USAGE_DURATION } from "../constants/bigbrain-event-types";
import { sleep } from "./utils-service";
import { getScrollPercent } from "./dom-utils-service";

const MAX_SECONDS = 60;
const TIMEOUT = 5 * 1000;
const TRACK_PERCENTAGE = 10;

const track = (timeSinceStarted, scrollPercent) => {
  BigBrain("track", PAGE_USAGE_DURATION, {
    kind: window.visitGuid,
    info1: timeSinceStarted,
    info2: scrollPercent,
    info3: location.href
  });
};

const iterateTracker = async (startedAt) => {
  const scrollPercent = await getScrollPercent();
  const secondsSinceStarted = Math.floor((new Date().getTime() - startedAt) / 1000);
  track(secondsSinceStarted, scrollPercent);
  if (MAX_SECONDS > secondsSinceStarted) {
    await sleep(TIMEOUT);
    iterateTracker(startedAt);
  }
};

const shouldTrack = () => {
  return TRACK_PERCENTAGE > 100 * Math.random();
};

export const initPageUsageDurationTracking = (startedAt) => {
  if (!shouldTrack()) return;
  iterateTracker(startedAt);
};
