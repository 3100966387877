import * as Sentry from "@sentry/nextjs";
import { trackEvent } from "services/bigbrain-service";
import { CLIENT_EXCEPTION } from "constants/bigbrain-event-types";

export const handleClientException = async ({ e, bbEventParams = {} }) => {
  // 1. capture exception in Sentry
  Sentry.captureException(e);
  await Sentry.flush(2000);

  // 2. track client exception on BigBrain
  const { name = CLIENT_EXCEPTION, ...params } = bbEventParams;
  trackEvent(name, {
    ...params,
    placement: window && window.location.href,
    info1: e && e.message
  });
};

export const handleRuntimeError = async (errorMessage) => {
  // 1. capture message in Sentry
  Sentry.captureMessage(errorMessage);
};
