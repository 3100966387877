import { fireEventsOnServerRedirectTests } from "/services/new-ab-tests/new-ab-tests-service";
import { shouldRenderSignupPopupAbTest } from "services/ab-tests/acquisition-ab-test-service";
import { POPUP_IMAGE } from "components/core/signups/signup-popup/signup-popup-screen-component";

export const initBodyScripts = function ({ abTests }) {
  fireEventsOnServerRedirectTests(abTests);
  if (shouldRenderSignupPopupAbTest(abTests, false)) {
    const img = new Image();
    img.src = POPUP_IMAGE;
  }
};
