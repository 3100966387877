import { componentStyles } from "./window-mask.scss";
import DefaultProps from "./default-props";

const WindowMask = (props) => {
  const { children } = props;
  return (
    <div className="window-mask">
      <div className="window-header">
        <div className="circle-wrapper">
          <span className="circle" />
          <span className="circle" />
          <span className="circle" />
        </div>
      </div>
      <div className="content-wrapper">
        {children}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default WindowMask;

WindowMask.defaultProps = DefaultProps;
