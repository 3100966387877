import { TEMPLATE_NAMES } from "constants/templates-names";

const NO_INDEX_STRING = "noindex";
const INDEX_STRING = "index";
const NO_FOLLOW_STRING = "nofollow";
const FOLLOW_STRING = "follow";

const NO_INDEX_LP_TEMPLATES = [
  TEMPLATE_NAMES.OnePagerAssetTemplate,
  TEMPLATE_NAMES.OnePagerWithImageTemplate,
  TEMPLATE_NAMES.MobileOnePagerWithImageTemplate,
  TEMPLATE_NAMES.OnePagerWithVideoTemplate,
  TEMPLATE_NAMES.CompetitorTemplate
];

const isProduction = process.env.NODE_ENV === "production";

export const getMetaTags = ({ template, noIndex, noFollow }) => {
  const shouldNoIndexOrNoFollow = () => {
    if (noIndex || noFollow) return true;
    return NO_INDEX_LP_TEMPLATES.includes(template);
  };

  const getRobotsContentText = () => {
    const indexStr = noIndex ? NO_INDEX_STRING : INDEX_STRING;
    const followStr = noFollow ? NO_FOLLOW_STRING : FOLLOW_STRING;

    return `${indexStr}, ${followStr}`;
  };

  return [
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />,
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />,
    <meta name="theme-color" content="#333333" />,
    <meta name="msapplication-navbutton-color" content="#333333" />,
    <meta name="coverage" content="Worldwide" />,
    <meta name="distribution" content="Global" />,
    <meta name="rating" content="General" />,
    isProduction && <meta name="facebook-domain-verification" content="fp63743zc2gqopx7ds8uyh9aplxwa3" />,
    shouldNoIndexOrNoFollow() && <meta name="robots" content={getRobotsContentText()} />,
    <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />,
    <meta name="p:domain_verify" content="b23b9536c7fb460593afc0a4efb90c3f" />,
    <meta name="google-site-verification" content="_um4GXuRDHUpvGCATPKd3z0pZFISWfjidYVj47c0bhA" />
  ];
};
