import CloseIcon from "static/svg/close.svg";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { componentStyles } from "./live-board-screen-component.scss";
import BoardViewsTab from "segments/desktop/core-components/board-views-tab/board-views-tab";
import TabComponent from "/components/core/tabs/tab/tab-component";
import Title from "segments/desktop/core-components/title/title";
import { tabs, MAIN_TABLE_TAB_ID } from "./live-board-consts";
import Banner from "segments/desktop/core-components/banner/banner";
import { BRAND_WORK_MANAGEMENT_PRIMARY_THEME_ID } from "segments/segments-service";
import Shake from "/components/enhancements/shake/shake-component";
import { useState } from "react";
import classnames from "classnames";
import SignupButton from "segments/desktop/core-components/button/signup-button/signup-button";
import { withGenericConfig } from "/contexts/generic-config-context";
import DefaultProps from "./default-props";

const liveBoardScreenComponent = (props) => {
  const {
    translate,
    closeLiveBoard,
    showLiveBoard,
    embeddedBoardLink,
    boardBannerText,
    embeddedBoardTitle,
    addSecondLiveBoardSignUpButton
  } = props;
  const [shakerIsActive, setIsActive] = useState(false);
  const [currentTab, setCurrentTab] = useState(MAIN_TABLE_TAB_ID);
  const LIVE_BOARD_BANNER_PLACEMENT = "live_board_banner_placement";
  const LIVE_BOARD_TOP_RIGHT_PLACEMENT = "live_board_top_right_placement";
  const LIVE_BOARD_TAB_LOCATION = "live_board_tab_placement";

  const startShaking = (callback) => {
    setIsActive(true);
    callback();
  };

  const shakeBanner = () => {
    startShaking(resetShaker);
  };

  const resetShaker = () => {
    setTimeout(() => setIsActive(false), 600);
  };

  const onTabClick = (index) => {
    setCurrentTab(tabs[index]?.tabID);
  };

  const onKeyDown = generateOnKeyDownHandler(closeLiveBoard);
  const renderCloseIcon = () => {
    return (
      <div
        className="live-board-close"
        onClick={closeLiveBoard}
        onKeyDown={onKeyDown}
        tabIndex={0}
        role="button"
        aria-label={translate("liveBoard.close")}
      >
        <CloseIcon className="close-icon" />
      </div>
    );
  };

  const tabsRenderer = () => {
    const { theme } = props;

    return tabs?.map((tab, index) => {
      const {
        tabLabel,
        hideLabel,
        image,
        liveBoardTabComponent: LiveBoardTabComponent,
        scrollVisible,
        componentProps
      } = tab;
      return (
        <TabComponent
          label={tabLabel}
          color={theme}
          useXSMLabels={true}
          tabIcon={image}
          tabsWithIcons={true}
          key={index}
          hideLabel={hideLabel}
          extraData={{ location: LIVE_BOARD_TAB_LOCATION }}
        >
          <div className={classnames("tab-component", { "scroll-visible": scrollVisible })}>
            <LiveBoardTabComponent onCardClick={shakeBanner} {...componentProps} sharableLink={embeddedBoardLink} />
          </div>
        </TabComponent>
      );
    });
  };

  return (
    <div
      className={classnames("live-board-screen", {
        "board-fully-visible": showLiveBoard
      })}
    >
      {showLiveBoard && renderCloseIcon()}
      <div className="live-board-content-wrapper">
        <div className="right-section">
          <div className="banner-wrapper">
            <Shake isActive={shakerIsActive}>
              <Banner
                buttonConfig={{ buttonType: "Signup" }}
                bannerCta={translate("signup.cta")}
                bannerText={boardBannerText}
                withCloseIcon={false}
                theme={BRAND_WORK_MANAGEMENT_PRIMARY_THEME_ID}
                hideBanner={false}
                placement={LIVE_BOARD_BANNER_PLACEMENT}
                extraData={{ live_board_tab_type: currentTab }}
              />
            </Shake>
          </div>
          <div className="board-title-wrapper">
            <div className="title-content">
              <Title title={embeddedBoardTitle} />
              <div className="tag">{translate("liveBoard.demoEnvironment")}</div>
            </div>
            {addSecondLiveBoardSignUpButton && (
              <div className="sign-up-button-wrapper">
                <SignupButton extraData={{ placement: LIVE_BOARD_TOP_RIGHT_PLACEMENT }} />
              </div>
            )}
          </div>
          <BoardViewsTab fullSizeContent={true} tabsRenderer={tabsRenderer} onTabClick={onTabClick} />
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

liveBoardScreenComponent.defaultProps = DefaultProps;

export default withGenericConfig(liveBoardScreenComponent);
