import classnames from "classnames";
import { ConditionalWrapper } from "utils/react";
import { componentStyles } from "./bullets.scss";
import DefaultProps from "./default-props";
import BulletCheck from "/static/svg/bullet_checked_green.svg";

const Bullets = (props) => {
  const { bullets, bulletsDirection, bulletTextSize } = props;

  const renderBullet = (bullet, bulletIndex) => {
    const { href, openInNewTab } = bullet;

    const anchorWrapper = (children) => {
      const target = openInNewTab ? "_blank" : undefined;
      return (
        <a className="bullet-anchor-wrapper" href={href} target={target}>
          {children}
        </a>
      );
    };

    return (
      <ConditionalWrapper condition={href} wrapper={anchorWrapper}>
        <div className="bullet" key={bulletIndex}>
          <div className="bullet-icon">
            <BulletCheck />
          </div>
          <div className="bullet-text" style={{ fontSize: bulletTextSize }}>
            {bullet.bulletText}
          </div>
        </div>
      </ConditionalWrapper>
    );
  };

  if (!bullets) return null;

  return (
    <div className={classnames("bullets", bulletsDirection)}>
      {bullets.map((bullet, bulletIndex) => {
        return renderBullet(bullet, bulletIndex);
      })}

      <style jsx>{componentStyles}</style>
    </div>
  );
};

Bullets.defaultProps = DefaultProps;

export default Bullets;
