import { PureComponent } from "react";
import { componentStyles } from "./responsive-hoc-component.scss";

export default function withResponsiveHOC(DesktopComponent, MobileComponent) {
  return class ResponsiveComponent extends PureComponent {
    render() {
      return (
        <div className="responsive-hoc-component">
          <div className="desktop-wrapper">
            <DesktopComponent {...this.props} />
          </div>

          <div className="mobile-wrapper">
            <MobileComponent {...this.props} />
          </div>

          <style jsx>{componentStyles}</style>
        </div>
      );
    }
  };
}
