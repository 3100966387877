import { generateCloudinaryImageUrl } from "services/community/cloudinary-service";
import { MONDAY_LOGO_SRC } from "constants/links";

const GoogleStructuredData = () => {
  const renderGoogleStructuredData = () => {
    return `
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "legalName": "monday.com Ltd",
        "description": "monday.com Work OS is an open platform where anyone can create the tools they need to run every aspect of their work.",
        "logo": "${generateCloudinaryImageUrl(MONDAY_LOGO_SRC)}",
        "name": "monday.com",
        "sameAs": [
          "https://twitter.com/mondaydotcom",
          "https://www.facebook.com/mondaydotcom/",
          "https://www.linkedin.com/company/monday.com/",
          "https://www.youtube.com/channel/UCA9UvBiKHly15rN8u_Km3BQ"
        ],
        "url": "https://monday.com"
      }
    `;
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: renderGoogleStructuredData()
      }}
    />
  );
};

export default GoogleStructuredData;
