import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { TOP_LAYER } from "constants/z-index";

export const componentStyles = css.global`
  .flexible-cards-grid-desktop-component {
    max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
    height: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--component-alignment);
    .flexible-cards-grid-desktop-component-inner {
      display: flex;
      justify-content: inherit;
      .grid-paragraph-with-image-component-wrapper {
        width: var(--flexible-grid-card-width);
        margin: var(--cards-margin);
        z-index: ${TOP_LAYER};
        position: relative;
        display: flex;
        .top-layer {
          width: 100%;
        }
        &.has-background-paragraph {
          .top-layer {
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
          }
          .back-layer {
            position: absolute;
            opacity: 0;
            height: 100%;
            width: 100%;
            transition: opacity 0.25s ease-in-out;
          }
          &:hover {
            .top-layer {
              opacity: 0;
            }
            .back-layer {
              opacity: 1;
            }
          }
        }
        :global(.grid-paragraph-wrapper) {
          display: flex;
          :global(.full-paragraph) {
            height: 100%;
          }
        }
      }
    }
  }
`;
