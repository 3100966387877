import * as Sentry from "@sentry/nextjs";

const buildSeoFAQ = ({ seoFAQ, translate }) => {
  let res = null;
  try {
    const fixString = (str) => {
      return str.replace(/"/g, "'");
    };

    const renderFaqSeo = () => {
      return `
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [${seoFAQ.map(({ answer, question }) => {
    return `
    {
      "@type": "Question",
      "name": "${fixString(translate(question))}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${fixString(translate(answer))}"
      }
    }`;
  })}]
}
      `;
    };

    if (seoFAQ) {
      res = (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: renderFaqSeo()
          }}
        />
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    Sentry.captureMessage("Failed to inject FAQ data to header");
  }
  return res;
};

export default buildSeoFAQ;
