import { componentStyles } from "./dashboards-tab-component.scss";
import { TOP_VIDEO_PROPS } from "./dashboards-tab-consts.js";
import { withGenericConfig } from "/contexts/generic-config-context";
import LiveBoardTabComponent from "../live-board-tab-component";

const DashboardsTabContentComponent = (props) => {
  const { translate, onCardClick } = props;
  return (
    <div className="dashboards-tab-content">
      <LiveBoardTabComponent
        description={translate("liveBoard.dashboardsTab.description")}
        topAssetProps={TOP_VIDEO_PROPS}
        hideVideoControls={true}
        onAssetClick={onCardClick}
      />
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(DashboardsTabContentComponent);
