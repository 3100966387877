const TWITTER_USER_ID = "912574397076123648";

export const getTwitterMetaTags = ({
  title,
  description,
  imagePath = "https://s3.amazonaws.com/general-assets/monday-800x418.png"
}) => {
  return [
    <meta name="twitter:card" content="summary" />,
    <meta name="twitter:site" content="@mondaydotcom" />,
    <meta name="twitter:title" content={title} />,
    <meta name="twitter:description" content={description} />,
    <meta name="twitter:image" content={imagePath} />,
    <meta property="twitter:account_id" content={TWITTER_USER_ID} />
  ];
};
