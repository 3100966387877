import { useRef, useEffect, useCallback } from "react";
import classnames from "classnames";
import Slider from "react-slick";
import throttle from "lodash.throttle";
import DefaultProps from "./default-props";
import { componentStyles } from "./carousel.scss";
import { slickStyle } from "./slick.scss";
import { slickThemeStyle } from "./slick-theme.scss";

const GO_TO_SPEED_MILLISECONDS = 500;
const GO_TO_ANIMATION_THROTTLE_DELAY = 100;
const PAUSE_AFTER_SWIPE_DELAY = 5000;

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const Carousel = (props) => {
  const slider = useRef(null);

  // Items props
  const { items, selectedIndex, itemRenderer, onSwipe, onChange, itemScale, lazyLoad } = props;
  // Carousel props
  const { autoplay, autoplaySpeed, infinite, slidesToShow, easing, arrows, nextArrow, prevArrow, dots, className } = props;
  // Advanced carousel props
  const {
    centerMode,
    centerPadding,
    defaultIndex,
    swipeToSlide,
    showFocusEffect,
    defaultImage,
    carouselClassName,
    onRefSet
  } = props;

  if (!items) return null;

  // Arrange items in right format and order
  const defaultIndexNumber = Math.min(parseInt(defaultIndex), items.length);
  const hasDefault = defaultImage && defaultImage.src;

  let slides = [...items];

  if (hasDefault) {
    slides = [...items.slice(0, defaultIndexNumber), defaultImage, ...items.slice(defaultIndexNumber)];
  }

  const handleSwipe = (direction) => {
    slider.current.slickPause();
    if (autoplay) {
      setTimeout(() => slider.current.slickPlay(), PAUSE_AFTER_SWIPE_DELAY);
    }

    if (onSwipe) {
      onSwipe(direction);
    }
  };

  const handleChange = (current) => {
    if (onChange) {
      onChange(current);
    }
  };

  const focusSlider = () => {
    if (slider?.current?.innerSlider?.list) {
      slider.current.innerSlider.list.setAttribute("tabindex", 0);
      slider.current.innerSlider.list.focus();
    }
  };

  const settings = {
    // Default settings
    className: classnames({
      center: centerMode,
      focus: showFocusEffect,
      [className]: className
    }),
    speed: GO_TO_SPEED_MILLISECONDS,
    dots,
    focusOnSelect: true,
    onSwipe: handleSwipe,
    afterChange: handleChange,
    // User props
    infinite,
    arrows,
    nextArrow,
    prevArrow,
    swipeToSlide,
    slidesToShow: Math.min(slidesToShow, slides.length),
    centerPadding,
    easing,
    centerMode,
    autoplay,
    autoplaySpeed: parseInt(autoplaySpeed),
    lazyLoad
  };

  const onIndexChanged = (selectedIndex) => {
    let newIndex;

    if (selectedIndex !== null && centerMode) {
      newIndex = selectedIndex >= defaultIndexNumber && hasDefault ? selectedIndex + 1 : selectedIndex;
    } else {
      newIndex = defaultIndexNumber;
    }

    slider.current.slickGoTo(newIndex);
  };

  // Throttle and give some delay (100ms) because when the index changes
  // `slickGoTo` does not work properly when changing fast
  const throttledOnIndexChanged = useCallback(
    throttle(onIndexChanged, GO_TO_SPEED_MILLISECONDS + GO_TO_ANIMATION_THROTTLE_DELAY, { trailing: true }),
    []
  );

  useEffect(() => {
    throttledOnIndexChanged(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (slider?.current) {
      if (onRefSet) {
        onRefSet(slider.current);
      }
    }
  }, [slider]);

  return (
    <div className={classnames("carousel", carouselClassName)} onTouchStart={focusSlider}>
      <Slider ref={slider} {...settings} initialSlide={defaultIndexNumber}>
        {slides.map((item, index) => (
          <div key={`slide_${index}`} className="carousel-item">
            <ConditionalWrapper
              condition={itemScale != 1}
              wrapper={(children) => <div style={{ transform: `scale(${itemScale})` }}>{children}</div>}
            >
              {itemRenderer(item)}
            </ConditionalWrapper>
          </div>
        ))}
      </Slider>

      <style jsx>{slickStyle}</style>
      <style jsx>{slickThemeStyle}</style>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

Carousel.defaultProps = DefaultProps;

export default Carousel;
