import { createContext, useContext, useEffect, useState } from "react";
import { PREFETCH_TYPES } from "constants/prefetch-constants";

const PREFETCHED_DOMAINS = ["https://views.monday.com"];
const PRECONNECT_DOMAINS = PREFETCHED_DOMAINS;

const PrefetchedLiveBoardAssetsContext = createContext("PrefetchedLiveBoardAssets");

export const PrefetchLiveBoardAssetsProvider = (props) => {
  const { children } = props;
  const [prefetchedLiveBoardAssetsLinks, setPrefetchedLiveBoardAssetsLinks] = useState([]);

  useEffect(() => {
    const setData = async () => {
      const LiveBoardAssetsLinks = [];
      // dns prefetches
      PREFETCHED_DOMAINS.forEach((href) => {
        LiveBoardAssetsLinks.push({ type: PREFETCH_TYPES.DNS_PREFETCH, href });
      });

      // pre-connects
      PRECONNECT_DOMAINS.forEach((href) => {
        LiveBoardAssetsLinks.push({ type: PREFETCH_TYPES.PRECONNECT, href });
      });

      setPrefetchedLiveBoardAssetsLinks(LiveBoardAssetsLinks);
    };

    setData();
  }, []);

  return (
    <PrefetchedLiveBoardAssetsContext.Provider value={prefetchedLiveBoardAssetsLinks}>
      {children}
    </PrefetchedLiveBoardAssetsContext.Provider>
  );
};

export const usePrefetchedLiveBoardAssets = () => {
  const prefetchedLiveBoardAssetsLinks = useContext(PrefetchedLiveBoardAssetsContext);

  return prefetchedLiveBoardAssetsLinks;
};
