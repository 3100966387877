import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";

const LINE_WIDTH = 4;
const LINE_WIDTH_STRING = `${LINE_WIDTH}px`;

export const componentStyles = css.global`
  .board-views-tab-component {
    padding-top: 60px;
    margin: auto;

    .tabs-component-wrapper {
      .tabs-component {
        .content {
          .asset-wrapper {
            min-height: 350px;
            opacity: 0;
            &.animation-active {
              opacity: 1;
              transition: opacity 1s ease;
            }
          }
        }
      }
      .tab-component {
        .tab-underline-placeholder {
          height: 0px;
        }
        &.active {
          background-color: unset;
          .tab-underline-placeholder {
            height: ${LINE_WIDTH_STRING};
          }
        }
      }
    }
    .tabs-component-wrapper {
      &.full-size-content {
        .tabs-component {
          .content {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;
