import { PureComponent } from "react";
import ModalWithSignupComponent from "/components/core/modal-with-signup/modal-with-signup-component";
import YouTubeVideoComponent from "components/core/youtube-video/youtube-video-component";

import componentStyles from "./youtube-video-modal-component.scss";

export default class YoutubeVideoModalComponent extends PureComponent {
  render() {
    const { isOpen, onCloseModal, footerText, style, withSignup } = this.props;
    return (
      <ModalWithSignupComponent
        style={style}
        footerText={footerText}
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        withSignup={withSignup}
      >
        <div className="youtube-video-wrapper">
          <YouTubeVideoComponent {...this.props} />
        </div>
        <style jsx>{componentStyles}</style>
      </ModalWithSignupComponent>
    );
  }
}

YoutubeVideoModalComponent.defaultProps = {
  withSignup: true
};
