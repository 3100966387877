const ALIGN_TYPES = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right"
};

const ALIGN_LABELS = {
  LEFT: "Left",
  CENTER: "Center",
  RIGHT: "Right"
};

module.exports = {
  ALIGN_TYPES,
  ALIGN_LABELS
};
