import classnames from "classnames";
import Check from "monday-ui-react-core/dist/icons/Check";
import { useMemo } from "react";
import Colors from "styles/colors";

const TagCheckbox = ({ checkColor, isSelected, selectedBorderColor, tagBorderColor, selectedBackgroundColor }) => {
  const checkboxColor = useMemo(() => checkColor && Colors[checkColor], [checkColor]);

  const checkboxBorderColor = useMemo(
    () => (isSelected && selectedBorderColor ? Colors[selectedBorderColor] : Colors[tagBorderColor]),
    [isSelected, selectedBorderColor, tagBorderColor]
  );

  const checkboxBackgroundColor = useMemo(
    () => isSelected && selectedBackgroundColor && Colors[selectedBackgroundColor],
    [selectedBackgroundColor, isSelected]
  );

  return (
    <div
      className={classnames("checkbox", { "custom-check-color": checkColor })}
      style={{ color: checkboxColor, borderColor: checkboxBorderColor, backgroundColor: checkboxBackgroundColor }}
    >
      <Check />
    </div>
  );
};

export default TagCheckbox;
