import { componentStyles } from "./live-board-tab-component.scss";
import Paragraph from "segments/desktop/core-components/paragraph/paragraph";
import FlexibleCardsGridComponent from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-component";
import { ALIGN_TYPES } from "constants/align-types";
import classnames from "classnames";
import AssetComponent from "/components/core/asset/asset-component";

const LiveBoardTabComponent = (props) => {
  const { description, topAssetProps, gridItems, hideCardParagraph, onCardClick, hideVideoControls, onAssetClick } =
    props;

  return (
    <div
      className={classnames("live-board-tab-wrapper", {
        "hide-paragraph": hideCardParagraph
      })}
    >
      <div className="tab-content">
        <div className="description-wrapper">
          <Paragraph body={description} />
        </div>
        {topAssetProps && (
          <div className="asset-wrapper" onClick={onAssetClick}>
            <AssetComponent {...topAssetProps} hideControls={hideVideoControls} />
          </div>
        )}
        {gridItems?.map((item) => (
          <div className="tab-cards-wrapper">
            <FlexibleCardsGridComponent
              gridItems={item}
              gridAlignment={ALIGN_TYPES.CENTER}
              defaultCardClick={onCardClick}
            />
          </div>
        ))}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default LiveBoardTabComponent;
