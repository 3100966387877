const Sentry = require("@sentry/nextjs");

export const getCompanyData = async (path, visitorId, experimentVisitorId) => {
  let errorInJson = "before_fetch";
  let status = 0;
  try {
    const url = `/enrichment/reveal?${new URLSearchParams({
      visitorId,
      experimentVisitorId,
      path
    })}`;
    const response = await fetch(url, { method: "GET" });
    status = response.status;
    errorInJson = "after_fetch";

    if (response.status !== 200) {
      const companyDataText = await response.text();
      Sentry.captureMessage(`getCompanyData Failed with status ${status} - Response text: ${companyDataText} `);
      return { success: false };
    }
    const companyData = await response.json();
    errorInJson = "after_parse";

    return { data: companyData, success: true };
  } catch (err) {
    Sentry.captureException(err);
    Sentry.captureMessage(
      `getCompanyData Failed with error ${err?.message} - debug data: ${errorInJson} and status: ${status}`
    );
    return { success: false };
  }
};
