// Header menus data =================================================================
import CanvasColoredIcon from "static/svg/header/canvas_colored.svg";
import WorkformsColoredIcon from "static/svg/header/workforms_colored.svg";
import MarketingColoredIcon from "static/svg/header/marketing_colored.svg";
import DevColoredIcon from "static/svg/header/dev_colored.svg";
import ITColoredIcon from "static/svg/header/it_colored_1.svg";
import OperationsColoredIcon from "static/svg/header/operations_colored.svg";
import PMOColoredIcon from "static/svg/header/pmo_colored.svg";
import SalesColoredIcon from "static/svg/header/sales_colored_1.svg";
import HRColoredIcon from "static/svg/header/hr_colored_1.svg";
import EnterpriseColoredIcon from "static/svg/header/enterprise_colored_1.svg";
import SmbColoredIcon from "static/svg/header/small_business_colored_1.svg";
import NonprofitColoredIcon from "static/svg/header/nonprofit_colored.svg";
// import BoardsColoredIcon from "static/svg/header/boards_colored.svg";
import DocsColoredIcon from "static/svg/header/docs_colored.svg";
import FilesColoredIcon from "static/svg/header/files_colored.svg";
import FormsColoredIcon from "static/svg/header/forms_icon_new.svg";
import GanttColoredIcon from "static/svg/header/gantt_colored.svg";
import KanbanColoredIcon from "static/svg/header/kanban_colored.svg";
import AutomationsColoredIcon from "static/svg/header/automations_colored.svg";
import IntegrationsColoredIcon from "static/svg/header/integrations_colored.svg";
import DashboardsColoredIcon from "static/svg/header/dashboards_colored.svg";
// import EnterpriseAgilityColoredIcon from "static/svg/header/enterprise_agility_colored.svg";
import agileOkrManagementColoredIcon from "static/svg/header/enterprise_agility_icon.svg";
import MoreWorkflowsColoredIcon from "static/svg/header/more_workflows_colored.svg";
import ProjectManagementColoredIcon from "static/svg/header/project_management_colored_2.svg";
import RemoteWorkColoredIcon from "static/svg/header/remote_work_colored_2.svg";
import InventoryTrackingColoredIcon from "static/svg/header/inventory_tracking_colored_2.svg";
import MondayWMSquareIcon from "static/svg/header/monday_work_management_square.svg";
import MondayCRMSquareIcon from "static/svg/header/monday_crm_square.svg";
import MondayMarketerSquareIcon from "static/svg/header/monday_marketer_square.svg";
import MondayProjectsSquareIcon from "static/svg/header/monday_projects_square.svg";
import MondayDevSquareIcon from "static/svg/header/monday_dev_square.svg";
import MondayWMLeftIcon from "static/svg/header/monday_work_management_left_1.svg";
import MondayCRMLeftIcon from "static/svg/header/monday_crm_left_1.svg";
import MondayDevLeftIcon from "static/svg/header/monday_dev_left_1.svg";
import WMIcon from "static/svg/header/mobile_wm_logo.svg";
import CRMIcon from "static/svg/header/mobile_crm_logo.svg";
import MarketerIcon from "static/svg/header/mobile_marketer_logo.svg";
import ProjectsIcon from "static/svg/header/mobile_projects_logo.svg";
import DevIcon from "static/svg/header/mobile_dev_logo.svg";
import TeamIcon from "static/svg/header/solutions/team.svg";
import CompanySizeIcon from "static/svg/header/solutions/company.svg";
import KeyFeaturesIcon from "static/svg/header/platform/keyFeatures.svg";
import IntegrationsIcon from "static/svg/header/platform/integrations_1.svg";
import AddOnsIcon from "static/svg/header/platform/addOns_1.svg";

// platform icons
import GitHubIcon from "static/svg/header/platform/github.svg";
import GitLabIcon from "static/svg/header/platform/gitlab_1.svg";
import GoogleLeadsIcon from "static/svg/header/platform/googleads_1.svg";
import GoogleDriveIcon from "static/svg/header/platform/drive.svg";
import HubSpotIcon from "static/svg/header/platform/hubspot.svg";
import JiraIcon from "static/svg/header/platform/jira_1.svg";
import MailchimpIcon from "static/svg/header/platform/mailchimp.svg";
import MicrosoftTeamsIcon from "static/svg/header/platform/mt_1.svg";
import OutlookIcon from "static/svg/header/platform/outlook.svg";
import PagerDutyIcon from "static/svg/header/platform/pagerduty_1.svg";
import SlackIcon from "static/svg/header/platform/slack_1.svg";
import SurveyMonkeyIcon from "static/svg/header/platform/surveymonkey.svg";
import TwilioIcon from "static/svg/header/platform/twilio_1.svg";
import ZendeskIcon from "static/svg/header/platform/zendesk.svg";
import ZoomIcon from "static/svg/header/platform/zoom_1.svg";
import GmailIcon from "static/svg/header/platform/gmail.svg";

import MiroIcon from "static/svg/header/platform/miro.svg";
import TracketIcon from "static/svg/header/platform/Tracket_1.svg";

import {
  BACKGROUND_GRAY_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME,
  CRM_DARK_TINT_01_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  GHOST_WHITE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME,
  WHITE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
} from "styles/color-consts";

import {
  ABOUT_US_PAGE_LINK,
  APPROVALS_APP_PAGE_LINK,
  APPS_DEVELOPER_LINK,
  APPS_MARKETPLACE_LINK,
  AUTOMATIONS_PAGE_LINK,
  BLOG_PAGE_LINK,
  BUILDERS_CERTIFICATIONS_PAGE_LINK,
  CANVAS_HOMEPAGE_LINK,
  CAREERS_PAGE_LINK,
  COMMUNITY_LINK,
  COMPARE_LINK,
  CRM_MINI_SITE_LINK,
  CUSTOMERS_PAGE_LINK,
  DASHBOARDS_PAGE_LINK,
  DIGITAL_LIFT_LINK,
  DOCU_GEN_APP_PAGE_LINK,
  EASYFORM_APP_PAGE_LINK,
  EFFICIENCY_IMPACT_PAGE_LINK,
  ENTERPRISE_AGILE_PAGE_LINK,
  ENTERPRISE_PAGE_LINK,
  FARFETCH_CUSTOMER_PAGE_LINK,
  FIGMA_APP_PAGE_LINK,
  FILES_PAGE_LINK,
  FORMS_PAGE_LINK,
  G2_WRITE_A_REVIEW_PAGE_LINK,
  GANTT_PAGE_LINK,
  GLOBAL_EVENTS_PAGE_LINK,
  HELP_CENTER_LINK,
  HR_USE_CASES_LINK,
  INTEGRATIONS_CRM_SECTION_2ND_ROW_HASH_NAME,
  INTEGRATIONS_CRM_SECTION_3RD_ROW_HASH_NAME,
  INTEGRATIONS_MARKETING_SECTION_1ST_ROW_HASH_NAME,
  INTEGRATIONS_MARKETING_SECTION_HASH_NAME,
  INTEGRATIONS_PAGE_LINK,
  INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME,
  INTEGRATIONS_TEAM_FAVORITES_SECTION_1ST_ROW_HASH_NAME,
  INTEGRATIONS_TEAM_FAVORITES_SECTION_2ND_ROW_HASH_NAME,
  INTEGRATIONS_TEAM_FAVORITES_SECTION_3RD_ROW_HASH_NAME,
  INVENTORY_WORKFLOW_LINK,
  IT_OPERATIONS_USE_CASES_LINK_V2,
  KANBAN_PAGE_LINK,
  MARKETING_MANAGEMENT_MINI_SITE_LINK,
  MIRO_APP_PAGE_LINK,
  NGO_PAGE_LINK,
  MONDAY_STORIES_PAGE,
  NISSAN_CUSTOMER_PAGE_LINK,
  ONE_VIEW_APP_PAGE_LINK,
  OPERATIONS_CLUSTER_LINK,
  PARTNERS_PAGE_LINK,
  PROJECT_MANAGEMENT_MINI_SITE_LINK,
  REMOTE_WORK_USE_CASES_LINK,
  SMB_PAGE_LINK,
  SOFTWARE_AG_CUSTOMER_PAGE_LINK,
  SOFTWARE_MINI_SITE_LINK,
  SUPPORT_PAGE_LINK,
  TEAM_VIEW_APP_PAGE_LINK,
  TEMPLATE_CENTER_PAGE_LINK,
  TRACKET_APP_PAGE_LINK,
  WEBINARS_PAGE_LINK,
  WHY_WORK_OS_LINK,
  WORK_MANAGEMENT_MINI_SITE_LINK,
  WORKDOCS_PAGE_LINK,
  WORKFORMS_HOMEPAGE_LINK,
  WORKLOAD_BLOG_PAGE_LINK,
  ZIPPO_CUSTOMER_PAGE_LINK
} from "constants/links";
import { hexToRgba } from "utils/general";
import colors from "styles/colors";
import { isMiniSiteConfigValid } from "client/services/cluster-config-service/cluster-config-service";
import { addHashToURL, addParamToURL } from "utils/url";
import { PRESELECTED_TAG_PARAM_NAME } from "segments/desktop/core-components/square-tags/square-tags";
import {
  BUGS_TRACKING,
  CLIENT_PROJECTS,
  CONTACT_MANAGEMENT,
  EMAIL_SYNC,
  FEATURE_REQUESTS,
  FEEDBACK_MANAGEMENT,
  GOALS_AND_OKRS,
  LEAD_MANAGEMENT,
  LEADS_CAPTURING,
  OPERATIONS,
  PERFORMANCE_TRACKING,
  PROJECT_AND_TASK_MANAGEMENT,
  RELEASE_PLAN,
  REQUEST_MANAGEMENT,
  ROADMAP_PLANNING,
  SALES_PIPELINE,
  SPRINT_MANAGEMENT,
  WORKLOAD_MANAGEMENT
} from "constants/clusters";
import { REGULAR_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { SM } from "constants/sizes";

export const PRODUCTS_MENU_ID = "products";
export const USE_CASES_MENU_ID = "use-cases";
export const FEATURES_MENU_ID = "features";
export const RESOURCES_MENU_ID = "resources";

// structured header
export const SOLUTIONS_MENU_ID = "solutions";
export const TEAMS_MENU_ID = "teams";
export const PLATFORM_MENU_ID = "platform";
export const CUSTOMERS_MENU_ID = "customers";

export const DESKTOP_HEADER_BASE_CLASS_NAME = "base-header-desktop-component";

export const PRODUCTS_PRODUCTS_SECTION_ID = "products";
export const PRODUCTS_MORE_SECTION_ID = "more";
export const BY_TEAM_SECTION_ID = "by-team";
export const BY_BUSINESS_SIZE_SECTION_ID = "by-business-size";
export const USE_CASES_BY_WORKFLOW_SECTION_ID = "by-workflow";
export const KEY_FEATURES_SECTION_ID = "key-features";
export const INTEGRATIONS_SECTION_ID = "integrations";
export const ADD_ONS_SECTION_ID = "add-ons";
export const RESOURCES_LEARN_SECTION_ID = "learn";
export const RESOURCES_CONNECT_SECTION_ID = "connect";
export const RESOURCES_EXPLORE_SECTION_ID = "explore";
export const RESOURCES_BUILD_SECTION_ID = "build";
export const TOP_STORIES_SECTION_ID = "top-stories";
export const GET_INVOLVED_SECTION_ID = "get-involved";
export const LEADING_COMPANIES_SECTION_ID = "leading-companies";
export const WORK_MANAGEMENT_SECTION_ID = "work-management";
export const CRM_SECTION_ID = "crm";
export const DEV_SECTION_ID = "dev";

export const ICON_TYPES = {
  fill: "fill",
  outline: "outline"
};

export const MENU_SECTION_CLASSNAMES = {
  products: "products",
  mondayProducts: "mondayProducts",
  moreProducts: "moreProducts",
  useCases: "use-cases",
  features: "features",
  resources: "resources",
  integrations: "integrations",
  addOns: "addOns"
};

export const MENU_SECTION_ITEM_TYPE = {
  base: "base",
  product: "product",
  secondaryProduct: "secondary-product",
  useCase: "use-case",
  useCaseByWorkflow: "use-case-by-workflow",
  feature: "feature",
  resource: "resource",
  story: "story",
  byTeam: "by-team",
  innerMenuBottomItem: "inner-menu-bottom-item",
  integration: "integration",
  addOn: "addOn"
};

export const mobileProductsSectionMenuItems = [
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    title: "header.products.section.withWm.text.title",
    subtitle: "header.products.section.withWm.text.subtitle",
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    noSeparator: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: WMIcon,
        icon: MondayWMSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayWorkManagement.title",
        subtitle: "header.tabs.products.mondayWorkManagement.subtitle1",
        url: WORK_MANAGEMENT_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[WORK_OS_IRIS_COLOR_NAME], 0.16)
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: CRMIcon,
        icon: MondayCRMSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondaySalesCRM.title",
        subtitle: "header.tabs.products.mondaySalesCRM.subtitle",
        url: CRM_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#025050",
          backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
          list: [
            "header.tabs.products.mondaySalesCRM.tags.contactManagement",
            "header.tabs.products.mondaySalesCRM.tags.salesPipeline",
            "header.tabs.products.mondaySalesCRM.tags.leadManagement"
          ]
        }
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: DevIcon,
        icon: MondayDevSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayDev.title",
        subtitle: "header.tabs.products.mondayDev.subtitle1",
        url: SOFTWARE_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#015727",
          backgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.08),
          list: [
            "header.tabs.products.mondayDev.tags.roadmapPlanning",
            "header.tabs.products.mondayDev.tags.bugTracking",
            "header.tabs.products.mondayDev.tags.scrumAndKanban"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_MORE_SECTION_ID,
    classname: MENU_SECTION_CLASSNAMES.moreProducts,
    title: "header.products.section.more",
    titleLine: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
];

export const TAG_TYPES = {
  DEFAULT: "default",
  BULLET: "bullet"
};

export const productsWithoutProjectsSectionMenuItems = [
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    noSeparator: true,
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        outline: true,
        mobileIcon: CRMIcon,
        icon: MondayCRMLeftIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        subtitle: "header.tabs.products.mondaySalesCRM.subtitle",
        url: CRM_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#025050",
          backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
          list: [
            "header.tabs.products.mondaySalesCRM.tags.contactManagement",
            "header.tabs.products.mondaySalesCRM.tags.salesPipeline",
            "header.tabs.products.mondaySalesCRM.tags.leadManagement",
            "header.tabs.products.mondaySalesCRM.tags.leadCapturing",
            "header.tabs.products.mondaySalesCRM.tags.performanceTracking",
            "header.tabs.products.mondaySalesCRM.tags.emailSync"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    noSeparator: true,
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        outline: true,
        mobileIcon: CRMIcon,
        icon: MondayWMLeftIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        subtitle: "header.tabs.products.mondayWorkManagement.subtitle1",
        url: WORK_MANAGEMENT_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[WORK_OS_IRIS_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: colors[DARK_WORK_OS_IRIS_COLOR_NAME],
          backgroundColor: hexToRgba("#babafd", 0.2),
          list: [
            "header.tabs.products.mondayWorkManagement.tags.projectManagement",
            "header.tabs.products.mondayWorkManagement.tags.marketingProjects",
            "header.tabs.products.mondayWorkManagement.tags.operations",
            "header.tabs.products.mondayWorkManagement.tags.designPlanning",
            "header.tabs.products.mondayWorkManagement.tags.financeRequests",
            "header.tabs.products.mondayWorkManagement.tags.buildYourOwn"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    noSeparator: true,
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        outline: true,
        mobileIcon: DevIcon,
        icon: MondayDevLeftIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        subtitle: "header.tabs.products.mondayDev.subtitle1",
        url: SOFTWARE_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#015727",
          backgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.08),
          list: [
            "header.tabs.products.mondayDev.tags.roadmapPlanning",
            "header.tabs.products.mondayDev.tags.featureBacklog",
            "header.tabs.products.mondayDev.tags.bugTracking",
            "header.tabs.products.mondayDev.tags.sprintPlanning",
            "header.tabs.products.mondayDev.tags.scrumAndKanban",
            "header.tabs.products.mondayDev.tags.FeedbackManagement"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_MORE_SECTION_ID,
    classname: MENU_SECTION_CLASSNAMES.moreProducts,
    title: "header.products.section.more",
    titleLine: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
];

const productsSectionMenuItems = [
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    title: "header.products.section.withWm.text.title",
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    noSeparator: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: CRMIcon,
        icon: MondayWMSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayWorkManagement.title",
        subtitle: "header.tabs.products.mondayWorkManagement.subtitle1",
        url: WORK_MANAGEMENT_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[WORK_OS_IRIS_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: colors[DARK_WORK_OS_IRIS_COLOR_NAME],
          backgroundColor: hexToRgba("#babafd", 0.2),
          list: [
            "header.tabs.products.mondayWorkManagement.tags.projectAndTaskManagement",
            "header.tabs.products.mondayWorkManagement.tags.operations",
            "header.tabs.products.mondayWorkManagement.tags.buildYourOwn"
          ]
        }
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: CRMIcon,
        icon: MondayCRMSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondaySalesCRM.title",
        subtitle: "header.tabs.products.mondaySalesCRM.subtitle",
        url: CRM_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#025050",
          backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
          list: [
            "header.tabs.products.mondaySalesCRM.tags.contactManagement",
            "header.tabs.products.mondaySalesCRM.tags.salesPipeline",
            "header.tabs.products.mondaySalesCRM.tags.leadManagement"
          ]
        }
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: DevIcon,
        icon: MondayDevSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayDev.title",
        subtitle: "header.tabs.products.mondayDev.subtitle1",
        url: SOFTWARE_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#015727",
          backgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.08),
          list: [
            "header.tabs.products.mondayDev.tags.roadmapPlanning",
            "header.tabs.products.mondayDev.tags.bugTracking",
            "header.tabs.products.mondayDev.tags.scrumAndKanban"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_PRODUCTS_SECTION_ID,
    mobileTitle: "header.products.section.products.mobileTitle",
    classname: MENU_SECTION_CLASSNAMES.mondayProducts,
    maxNumberOfItemsInColumn: 3,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: MarketerIcon,
        icon: MondayMarketerSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayMarketer.title",
        subtitle: "header.tabs.products.mondayMarketer.subtitle",
        url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[MARKETING_RED_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#9A0550",
          backgroundColor: hexToRgba(colors[MARKETING_RED_COLOR_NAME], 0.08),
          list: [
            "header.tabs.products.mondayMarketer.tags.contentCalendar",
            "header.tabs.products.mondayMarketer.tags.marketingStrategy",
            "header.tabs.products.mondayMarketer.tags.creativeRequests"
          ]
        }
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.product,
        mobileIcon: ProjectsIcon,
        icon: MondayProjectsSquareIcon,
        iconStrokeColor: colors.gainsboro,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.mondayProjects.title",
        subtitle: "header.tabs.products.mondayProjects.subtitle",
        url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
        hoverBackgroundColor: hexToRgba(colors[PROJECTS_ORANGE_COLOR_NAME], 0.16),
        tags: {
          title: "header.tabs.products.tags.title",
          color: "#BC5004",
          backgroundColor: hexToRgba(colors[PROJECTS_ORANGE_COLOR_NAME], 0.08),
          list: [
            "header.tabs.products.mondayProjects.tags.advancedProjects",
            "header.tabs.products.mondayProjects.tags.portfolioManagement",
            "header.tabs.products.mondayProjects.tags.criticalPath"
          ]
        }
      }
    ]
  },
  {
    id: PRODUCTS_MORE_SECTION_ID,
    classname: MENU_SECTION_CLASSNAMES.moreProducts,
    title: "header.products.section.more",
    titleLine: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
];

const useCasesSectionMenuItems = [
  {
    id: BY_TEAM_SECTION_ID,
    title: "header.useCases.section.byTeam",
    titleLine: true,
    classname: MENU_SECTION_CLASSNAMES.useCases,
    maxNumberOfItemsInColumn: 4,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: MarketingColoredIcon,
        iconColor: MARKETING_RED_COLOR_NAME,
        title: "header.tabs.workflows.byTeam.marketing",
        url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: DevColoredIcon,
        iconColor: DEV_GREEN_COLOR_NAME,
        title: "header.tabs.workflows.byTeam.developers",
        url: SOFTWARE_MINI_SITE_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: OperationsColoredIcon,
        title: "header.tabs.workflows.byTeam.operations",
        url: OPERATIONS_CLUSTER_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: HRColoredIcon,
        title: "header.tabs.workflows.byTeam.hr",
        url: HR_USE_CASES_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: SalesColoredIcon,
        iconColor: CRM_GREEN_COLOR_NAME,
        title: "header.tabs.workflows.byTeam.sales",
        url: CRM_MINI_SITE_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: PMOColoredIcon,
        iconColor: PROJECTS_ORANGE_COLOR_NAME,
        title: "header.tabs.workflows.byTeam.pmo",
        url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
        outline: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: ITColoredIcon,
        title: "header.tabs.workflows.byTeam.it",
        url: IT_OPERATIONS_USE_CASES_LINK_V2,
        outline: true
      }
    ]
  },
  {
    id: BY_BUSINESS_SIZE_SECTION_ID,
    title: "header.useCases.section.byBusinessSize",
    titleLine: true,
    classname: MENU_SECTION_CLASSNAMES.useCases,
    maxNumberOfItemsInColumn: 4,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: EnterpriseColoredIcon,
        title: "header.tabs.workflows.byBusinessSize.enterprise",
        url: ENTERPRISE_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: SmbColoredIcon,
        title: "header.tabs.workflows.byBusinessSize.smallBusiness",
        url: SMB_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCase,
        icon: NonprofitColoredIcon,
        title: "header.tabs.workflows.byBusinessSize.nonprofit",
        url: NGO_PAGE_LINK
      }
    ]
  },
  {
    id: USE_CASES_BY_WORKFLOW_SECTION_ID,
    title: "header.useCases.section.byWorkflow",
    titleLine: true,
    classname: MENU_SECTION_CLASSNAMES.useCases,
    maxNumberOfItemsInColumn: 3,
    menuSectionItems: [
      // {
      // itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
      //   icon: EnterpriseAgilityColoredIcon,
      //   title: "header.tabs.workflows.byWorkflow.enterpriseAgility.title",
      //   description: "header.tabs.workflows.byWorkflow.enterpriseAgility.description",
      //   isPopular: true,
      //   badgeText: "header.menuItem.comingSoon",
      //   badgeColorName: PEACH_COLOR_NAME
      // },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
        icon: ProjectManagementColoredIcon,
        title: "header.tabs.workflows.byWorkflow.projectManagement.title",
        description: "header.tabs.workflows.byWorkflow.projectManagement.description",
        url: PROJECT_MANAGEMENT_MINI_SITE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
        icon: RemoteWorkColoredIcon,
        title: "header.tabs.workflows.byWorkflow.remoteWork.title",
        description: "header.tabs.workflows.byWorkflow.remoteWork.description",
        url: REMOTE_WORK_USE_CASES_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
        icon: InventoryTrackingColoredIcon,
        title: "header.tabs.workflows.byWorkflow.inventoryTracking.title",
        description: "header.tabs.workflows.byWorkflow.inventoryTracking.description",
        url: INVENTORY_WORKFLOW_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
        icon: agileOkrManagementColoredIcon,
        title: "header.tabs.workflows.byWorkflow.okrAndAgileManagement.title",
        description: "header.tabs.workflows.byWorkflow.okrAndAgileManagement.description",
        url: ENTERPRISE_AGILE_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
        icon: MoreWorkflowsColoredIcon,
        title: "header.tabs.workflows.byWorkflow.moreWorkflows.title",
        description: "header.tabs.workflows.byWorkflow.moreWorkflows.description",
        url: TEMPLATE_CENTER_PAGE_LINK
      }
    ]
  }
];

const featuresSectionMenuItems = [
  {
    id: KEY_FEATURES_SECTION_ID,
    title: "header.features.section.keyFeatures",
    titleLine: true,
    classname: MENU_SECTION_CLASSNAMES.features,
    maxNumberOfItemsInColumn: 2,
    menuSectionItems: [
      // {
      //   itemType: MENU_SECTION_ITEM_TYPE.feature,
      //   icon: BoardsColoredIcon,
      //   title: "header.tabs.features.keyFeatures.boards.title",
      //   description: "header.tabs.features.keyFeatures.boards.description",
      //   outline: true,
      //   url: HR_USE_CASES_LINK,
      // },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: DashboardsColoredIcon,
        title: "header.tabs.features.keyFeatures.dashboards.title",
        description: "header.tabs.features.keyFeatures.dashboards.description",
        outline: true,
        url: DASHBOARDS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: IntegrationsColoredIcon,
        title: "header.tabs.features.keyFeatures.integrations.title",
        description: "header.tabs.features.keyFeatures.integrations.description",
        outline: true,
        url: INTEGRATIONS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: AutomationsColoredIcon,
        title: "header.tabs.features.keyFeatures.automations.title",
        description: "header.tabs.features.keyFeatures.automations.description",
        outline: true,
        url: AUTOMATIONS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: GanttColoredIcon,
        title: "header.tabs.features.keyFeatures.gantt.title",
        description: "header.tabs.features.keyFeatures.gantt.description",
        outline: true,
        url: GANTT_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: KanbanColoredIcon,
        title: "header.tabs.features.keyFeatures.kanban.title",
        description: "header.tabs.features.keyFeatures.kanban.description",
        outline: true,
        url: KANBAN_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: DocsColoredIcon,
        title: "header.tabs.features.keyFeatures.docs.title",
        description: "header.tabs.features.keyFeatures.docs.description",
        outline: true,
        url: WORKDOCS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: FilesColoredIcon,
        title: "header.tabs.features.keyFeatures.files.title",
        description: "header.tabs.features.keyFeatures.files.description",
        outline: true,
        url: FILES_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.feature,
        icon: FormsColoredIcon,
        title: "header.tabs.features.keyFeatures.forms.title",
        description: "header.tabs.features.keyFeatures.forms.description",
        outline: true,
        url: FORMS_PAGE_LINK
      }
    ]
  }
];

const resourcesSectionMenuItems = [
  {
    id: RESOURCES_LEARN_SECTION_ID,
    classname: MENU_SECTION_CLASSNAMES.resources,
    title: "header.tabs.resources.section.learn",
    titleLine: true,
    noSeparator: true,
    maxNumberOfItemsInColumn: 5,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.learn.knowledgeBase.title",
        description: "header.tabs.resources.learn.knowledgeBase.description",
        url: HELP_CENTER_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.learn.aboutUs.title",
        description: "header.tabs.resources.learn.aboutUs.description",
        url: ABOUT_US_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.learn.webinars.title",
        description: "header.tabs.resources.learn.webinars.description",
        url: WEBINARS_PAGE_LINK
      },
      // {
      //   itemType: MENU_SECTION_ITEM_TYPE.resource,
      //   title: "header.tabs.resources.learn.resourcesHub.title",
      //   description: "header.tabs.resources.learn.resourcesHub.description",
      // },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.learn.blog.title",
        description: "header.tabs.resources.learn.blog.description",
        url: BLOG_PAGE_LINK
      }
    ]
  },
  {
    id: RESOURCES_CONNECT_SECTION_ID,
    title: "header.tabs.resources.section.connect",
    titleLine: true,
    noSeparator: true,
    maxNumberOfItemsInColumn: 5,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.connect.support.title",
        description: "header.tabs.resources.connect.support.description",
        url: SUPPORT_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.connect.partners.title",
        description: "header.tabs.resources.connect.partners.description",
        url: PARTNERS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.connect.BuilderCertificationsText.title",
        description: "header.tabs.resources.connect.BuilderCertificationsText.description",
        url: BUILDERS_CERTIFICATIONS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.connect.globalEvents.title",
        description: "header.tabs.resources.connect.globalEvents.description",
        url: GLOBAL_EVENTS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.connect.digitalLift.title",
        description: "header.tabs.resources.connect.digitalLift.description",
        url: DIGITAL_LIFT_LINK
      }
    ]
  },
  {
    id: RESOURCES_EXPLORE_SECTION_ID,
    title: "header.tabs.resources.section.explore",
    titleLine: true,
    noSeparator: true,
    maxNumberOfItemsInColumn: 5,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.explore.templateCenter.title",
        description: "header.tabs.resources.explore.templateCenter.description",
        url: TEMPLATE_CENTER_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.explore.appsMarketplace.title",
        description: "header.tabs.resources.explore.appsMarketplace.description",
        url: APPS_MARKETPLACE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.explore.appsDeveloper.title",
        description: "header.tabs.resources.explore.appsDeveloper.description",
        url: APPS_DEVELOPER_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.explore.customerStories.title",
        description: "header.tabs.resources.explore.customerStories.description",
        url: CUSTOMERS_PAGE_LINK
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.resource,
        title: "header.tabs.resources.explore.careers.title",
        description: "header.tabs.resources.explore.careers.description",
        url: CAREERS_PAGE_LINK
      }
    ]
  },
  {
    id: TOP_STORIES_SECTION_ID,
    title: "header.tabs.resources.section.customersStories",
    titleLine: true,
    noSeparator: true,
    maxNumberOfItemsInColumn: 2,
    backgroundColor: BACKGROUND_GRAY_COLOR_NAME,
    hideOnMobile: true,
    menuSectionItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.story,
        hoverBackgroundColor: colors[WHITE_COLOR_NAME],
        imageSrc:
          "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/Header_asset.png",
        description: "header.tabs.resources.topStories.whyCustomersUse",
        url: MONDAY_STORIES_PAGE,
        button: true
      }
    ]
  }
];

/** * Structured Header sections definitions with header follow up button - Start ** */
export const solutionsMenuWithHeaderLearnMoreButton = {
  innerMenu: {
    items: [
      {
        id: "By product",
        title: "header.tabs.solutions.byProduct.title.byMonday",
        subItems: [
          {
            id: "Work management",
            title: "header.tabs.solutions.byProduct.workManagement",
            icon: WMIcon,
            content: {
              menuSections: [
                {
                  id: WORK_MANAGEMENT_SECTION_ID,
                  title: "header.tabs.products.mondayWorkManagement.subtitle1",
                  titleUrl: WORK_MANAGEMENT_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/work_management/bigger-desktop_close_monday_wm_logo.png",
                  titleCTA: {
                    title: "signup.learnMore",
                    buttonType: REGULAR_BUTTON_TYPE,
                    eventInfo: "learnMore",
                    color: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME,
                    outline: true,
                    buttonSize: SM,
                    url: WORK_MANAGEMENT_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  seeMoreItem: {
                    title: "header.seeAllUseCases",
                    eventInfo: "seeAll",
                    url: WORK_MANAGEMENT_MINI_SITE_LINK
                  },
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.projectAndTaskManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description:
                        "header.tabs.solutions.byProduct.workManagement.projectAndTaskManagement.description",
                      url: addParamToURL(
                        WORK_MANAGEMENT_MINI_SITE_LINK,
                        PRESELECTED_TAG_PARAM_NAME,
                        PROJECT_AND_TASK_MANAGEMENT
                      )
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.workloadManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.workloadManagement.description",
                      url: addParamToURL(
                        WORK_MANAGEMENT_MINI_SITE_LINK,
                        PRESELECTED_TAG_PARAM_NAME,
                        WORKLOAD_MANAGEMENT
                      )
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.operationsProcesses.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.operationsProcesses.description1",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, OPERATIONS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.clientProjects.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.clientProjects.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, CLIENT_PROJECTS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.goalsAndOKRs.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.goalsAndOKRs.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, GOALS_AND_OKRS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.requestManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.requestManagement.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, REQUEST_MANAGEMENT)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.workManagement.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/Wm_image.png",
                      description: "header.tabs.solutions.byProduct.workManagement.rightSection.card.description",
                      url: TEMPLATE_CENTER_PAGE_LINK,
                      buttonText:
                        "header.tabs.solutions.byProduct.workManagement.rightSection.card.buttonText.startWithAtemplate",
                      button: true
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Sales CRM",
            title: "header.tabs.solutions.byProduct.salesCRM",
            icon: CRMIcon,
            content: {
              menuSections: [
                {
                  id: CRM_SECTION_ID,
                  title: "header.tabs.products.mondaySalesCRM.subtitle",
                  titleUrl: CRM_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/crm/bigger-desktop_close_monday_crm_logo.png",
                  titleCTA: {
                    title: "signup.learnMore",
                    outline: true,
                    eventInfo: "learnMore",
                    color: CRM_DARK_TINT_01_COLOR_NAME,
                    buttonType: REGULAR_BUTTON_TYPE,
                    buttonSize: SM,
                    url: CRM_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  seeMoreItem: {
                    eventInfo: "seeAll",
                    title: "header.seeAllUseCases",
                    url: CRM_MINI_SITE_LINK
                  },
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.salesPipeline.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.salesPipeline.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, SALES_PIPELINE)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.contactManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.contactManagement.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, CONTACT_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.emailSync.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.emailSync.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, EMAIL_SYNC)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.leadCapturing.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.leadCapturing.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, LEADS_CAPTURING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.leadManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.leadManagement.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, LEAD_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.performanceTracking.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.performanceTracking.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, PERFORMANCE_TRACKING)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.salesCRM.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/v1669619701/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/crm_image.png",
                      description: "header.tabs.solutions.byProduct.salesCRM.rightSection.card.description",
                      url: CRM_MINI_SITE_LINK,
                      buttonText: "header.tabs.solutions.byProduct.salesCRM.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Dev",
            title: "header.tabs.solutions.byProduct.dev",
            icon: DevIcon,
            hasBetaTag: true,
            content: {
              menuSections: [
                {
                  id: DEV_SECTION_ID,
                  title: "header.tabs.products.mondayDev.subtitle1",
                  titleUrl: SOFTWARE_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/dev/bigger-desktop_close_monday_dev_logo.png",
                  titleCTA: {
                    title: "signup.learnMore",
                    buttonType: REGULAR_BUTTON_TYPE,
                    buttonSize: SM,
                    outline: true,
                    eventInfo: "learnMore",
                    color: BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
                    url: SOFTWARE_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  seeMoreItem: {
                    eventInfo: "seeAll",
                    title: "header.seeAllUseCases",
                    url: SOFTWARE_MINI_SITE_LINK
                  },
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.dev.sprintManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.sprintManagement.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, SPRINT_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.bugTracking.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.bugTracking.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, BUGS_TRACKING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.releasePlan.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.releasePlan.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, RELEASE_PLAN)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.roadmapPlanning.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.roadmapPlanning.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, ROADMAP_PLANNING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.featureRequests.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.featureRequests.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, FEATURE_REQUESTS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.feedbackManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.feedbackManagement.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, FEEDBACK_MANAGEMENT)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.dev.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/dev_image.png",
                      description: "header.tabs.solutions.byProduct.dev.rightSection.card.description",
                      url: SOFTWARE_MINI_SITE_LINK,
                      buttonText: "header.tabs.solutions.byProduct.dev.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  bottomPanel: {
    bottomItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
};

/** * Structured Header sections definitions - Start ** */
const solutionsMenu = {
  innerMenu: {
    items: [
      {
        id: "By product",
        title: "header.tabs.solutions.byProduct.title.byMonday",
        subItems: [
          {
            id: "Work management",
            title: "header.tabs.solutions.byProduct.workManagement",
            icon: WMIcon,
            content: {
              menuSections: [
                {
                  id: WORK_MANAGEMENT_SECTION_ID,
                  title: "header.tabs.products.mondayWorkManagement.subtitle1",
                  titleUrl: WORK_MANAGEMENT_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/work_management/bigger-desktop_close_monday_wm_logo.png",
                  titleCTA: {
                    title: "header.seeAllUseCases",
                    url: WORK_MANAGEMENT_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.projectAndTaskManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description:
                        "header.tabs.solutions.byProduct.workManagement.projectAndTaskManagement.description",
                      url: addParamToURL(
                        WORK_MANAGEMENT_MINI_SITE_LINK,
                        PRESELECTED_TAG_PARAM_NAME,
                        PROJECT_AND_TASK_MANAGEMENT
                      )
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.workloadManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.workloadManagement.description",
                      url: addParamToURL(
                        WORK_MANAGEMENT_MINI_SITE_LINK,
                        PRESELECTED_TAG_PARAM_NAME,
                        WORKLOAD_MANAGEMENT
                      )
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.operationsProcesses.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.operationsProcesses.description1",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, OPERATIONS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.clientProjects.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.clientProjects.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, CLIENT_PROJECTS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.goalsAndOKRs.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.goalsAndOKRs.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, GOALS_AND_OKRS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.workManagement.requestManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.workManagement.requestManagement.description",
                      url: addParamToURL(WORK_MANAGEMENT_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, REQUEST_MANAGEMENT)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.workManagement.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/Wm_image.png",
                      description: "header.tabs.solutions.byProduct.workManagement.rightSection.card.description",
                      url: TEMPLATE_CENTER_PAGE_LINK,
                      buttonText:
                        "header.tabs.solutions.byProduct.workManagement.rightSection.card.buttonText.startWithAtemplate",
                      button: true
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Sales CRM",
            title: "header.tabs.solutions.byProduct.salesCRM",
            icon: CRMIcon,
            content: {
              menuSections: [
                {
                  id: CRM_SECTION_ID,
                  title: "header.tabs.products.mondaySalesCRM.subtitle",
                  titleUrl: CRM_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/crm/bigger-desktop_close_monday_crm_logo.png",
                  titleCTA: {
                    title: "header.seeAllUseCases",
                    url: CRM_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.salesPipeline.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.salesPipeline.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, SALES_PIPELINE)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.contactManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.contactManagement.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, CONTACT_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.emailSync.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.emailSync.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, EMAIL_SYNC)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.leadCapturing.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.leadCapturing.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, LEADS_CAPTURING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.leadManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.leadManagement.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, LEAD_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.salesCRM.performanceTracking.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.salesCRM.performanceTracking.description",
                      url: addParamToURL(CRM_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, PERFORMANCE_TRACKING)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.salesCRM.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/v1669619701/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/crm_image.png",
                      description: "header.tabs.solutions.byProduct.salesCRM.rightSection.card.description",
                      url: CRM_MINI_SITE_LINK,
                      buttonText: "header.tabs.solutions.byProduct.salesCRM.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Dev",
            title: "header.tabs.solutions.byProduct.dev",
            icon: DevIcon,
            hasBetaTag: true,
            content: {
              menuSections: [
                {
                  id: DEV_SECTION_ID,
                  title: "header.tabs.products.mondayDev.subtitle1",
                  titleUrl: SOFTWARE_MINI_SITE_LINK,
                  titleImage:
                    "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/minisites/header%20logos/dev/bigger-desktop_close_monday_dev_logo.png",
                  titleCTA: {
                    title: "header.seeAllUseCases",
                    url: SOFTWARE_MINI_SITE_LINK
                  },
                  maxNumberOfItemsInColumn: 3,
                  menuSectionItems: [
                    {
                      title: "header.tabs.solutions.byProduct.dev.sprintManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.sprintManagement.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, SPRINT_MANAGEMENT)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.bugTracking.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.bugTracking.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, BUGS_TRACKING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.releasePlan.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.releasePlan.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, RELEASE_PLAN)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.roadmapPlanning.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.roadmapPlanning.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, ROADMAP_PLANNING)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.featureRequests.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.featureRequests.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, FEATURE_REQUESTS)
                    },
                    {
                      title: "header.tabs.solutions.byProduct.dev.feedbackManagement.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byProduct.dev.feedbackManagement.description",
                      url: addParamToURL(SOFTWARE_MINI_SITE_LINK, PRESELECTED_TAG_PARAM_NAME, FEEDBACK_MANAGEMENT)
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.solutions.byProduct.dev.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/dev_image.png",
                      description: "header.tabs.solutions.byProduct.dev.rightSection.card.description",
                      url: SOFTWARE_MINI_SITE_LINK,
                      buttonText: "header.tabs.solutions.byProduct.dev.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  bottomPanel: {
    bottomItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
};

const teamsMenu = {
  innerMenu: {
    items: [
      {
        id: "By team",
        title: "header.tabs.solutions.byTeam.title",
        subItems: [
          {
            id: "Team type",
            title: "header.tabs.solutions.byTeam.teamType",
            icon: TeamIcon,
            content: {
              menuSections: [
                {
                  id: BY_TEAM_SECTION_ID,
                  title: "header.tabs.solutions.byTeam.teamType.title",
                  maxNumberOfItemsInColumn: 4,
                  withTitleUnderline: true,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: MarketingColoredIcon,
                      iconColor: MARKETING_RED_COLOR_NAME,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byTeam.marketing",
                      description: "header.tabs.solutions.byTeam.marketing.description",
                      url: MARKETING_MANAGEMENT_MINI_SITE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: DevColoredIcon,
                      iconColor: DEV_GREEN_COLOR_NAME,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.solutions.byTeam.developers.title",
                      description: "header.tabs.solutions.byTeam.developers.description",
                      url: SOFTWARE_MINI_SITE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: OperationsColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byTeam.operations",
                      description: "header.tabs.solutions.byTeam.operations.description",
                      url: OPERATIONS_CLUSTER_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: HRColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byTeam.hr",
                      description: "header.tabs.solutions.byTeam.hr.description",
                      url: HR_USE_CASES_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: SalesColoredIcon,
                      iconColor: CRM_GREEN_COLOR_NAME,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byTeam.sales",
                      description: "header.tabs.solutions.byTeam.sales.description",
                      url: CRM_MINI_SITE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: PMOColoredIcon,
                      iconColor: PROJECTS_ORANGE_COLOR_NAME,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.solutions.byTeam.pmo.description",
                      title: "header.tabs.workflows.byTeam.pmo",
                      url: PROJECT_MANAGEMENT_MINI_SITE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: ITColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byTeam.it",
                      description: "header.tabs.solutions.byTeam.it.description",
                      url: IT_OPERATIONS_USE_CASES_LINK_V2
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.resources.section.featuredStory",
                  maxNumberOfItemsInColumn: 2,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/blog.png",
                      description: "header.tabs.solutions.byTeam.teamType.rightSection.card.description",
                      url: WORKLOAD_BLOG_PAGE_LINK,
                      openInNewTab: true,
                      buttonText: "header.tabs.solutions.byTeam.teamType.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Company size",
            title: "header.tabs.solutions.byTeam.companySize",
            icon: CompanySizeIcon,
            content: {
              menuSections: [
                {
                  id: BY_BUSINESS_SIZE_SECTION_ID,
                  title: "header.tabs.solutions.byTeam.companySize.title",
                  withTitleUnderline: true,
                  classname: MENU_SECTION_CLASSNAMES.useCases,
                  maxNumberOfItemsInColumn: 2,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: EnterpriseColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byBusinessSize.enterprise",
                      description: "header.tabs.solutions.byTeam.companySize.enterprise.description",
                      url: ENTERPRISE_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: SmbColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.solutions.byTeam.companySize.SMB.title",
                      description: "header.tabs.solutions.byTeam.companySize.SMB.description",
                      url: SMB_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.byTeam,
                      icon: NonprofitColoredIcon,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      title: "header.tabs.workflows.byBusinessSize.nonprofit",
                      description: "header.tabs.solutions.byTeam.companySize.nonProfit.description",
                      url: NGO_PAGE_LINK
                    }
                  ]
                },
                {
                  title: "header.tabs.solutions.byTeam.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      id: TOP_STORIES_SECTION_ID,
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/agile_enterprise.png",
                      description: "header.tabs.solutions.byTeam.rightSection.card.description",
                      url: ENTERPRISE_AGILE_PAGE_LINK,
                      buttonText: "header.tabs.customers.section.rightSection.card.buttonText",
                      button: true
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ]
  },
  bottomPanel: {
    bottomItems: [
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: WorkformsColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.workForms.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: WORKFORMS_HOMEPAGE_LINK,
        openInNewTab: true
      },
      {
        itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
        icon: CanvasColoredIcon,
        iconType: ICON_TYPES.fill,
        title: "header.tabs.products.more.canvas.title",
        hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
        url: CANVAS_HOMEPAGE_LINK,
        openInNewTab: true
      }
    ]
  }
};

const platformMenu = {
  innerMenu: {
    items: [
      {
        id: "The platform",
        title: "header.tabs.platform.thePlatform",
        subItems: [
          {
            id: "Key features",
            title: "header.tabs.platform.keyFeatures.title",
            icon: KeyFeaturesIcon,
            content: {
              menuSections: [
                {
                  id: KEY_FEATURES_SECTION_ID,
                  title: "header.tabs.platform.keyFeatures.create.title",
                  withTitleUnderline: true,
                  classname: MENU_SECTION_CLASSNAMES.features,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: DashboardsColoredIcon,
                      title: "header.tabs.features.keyFeatures.dashboards.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.dashboards.description",
                      url: DASHBOARDS_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: IntegrationsColoredIcon,
                      title: "header.tabs.features.keyFeatures.integrations.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.integrations.description",
                      url: INTEGRATIONS_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: AutomationsColoredIcon,
                      title: "header.tabs.features.keyFeatures.automations.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.automations.description",
                      url: AUTOMATIONS_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: GanttColoredIcon,
                      title: "header.tabs.features.keyFeatures.gantt.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.gantt.description",
                      url: GANTT_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: KanbanColoredIcon,
                      title: "header.tabs.features.keyFeatures.kanban.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.kanban.description",
                      url: KANBAN_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: DocsColoredIcon,
                      title: "header.tabs.features.keyFeatures.docs.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.docs.description",
                      url: WORKDOCS_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: FilesColoredIcon,
                      title: "header.tabs.features.keyFeatures.files.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.files.description",
                      url: FILES_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.feature,
                      icon: FormsColoredIcon,
                      title: "header.tabs.features.keyFeatures.forms.title",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      description: "header.tabs.features.keyFeatures.forms.description",
                      url: FORMS_PAGE_LINK
                    }
                  ]
                },
                {
                  id: TOP_STORIES_SECTION_ID,
                  title: "header.tabs.platform.keyFeatures.rightSection.title",
                  maxNumberOfItemsInColumn: 1,
                  backgroundColor: WHITE_COLOR_NAME,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.story,
                      backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/v1669716137/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/byateam_image.png",
                      url: COMPARE_LINK,
                      description: "header.tabs.platform.keyFeatures.rightSection.card.description",
                      button: true,
                      buttonText: "header.tabs.customers.section.rightSection.card.buttonText"
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Integrations",
            title: "Integrations",
            icon: IntegrationsIcon,
            content: {
              title: "header.tabs.platform.integrations.connect.title",
              menuSections: [
                {
                  id: INTEGRATIONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.integrations,
                  title: "header.tabs.platform.integrations.connect.forDevelopment.title",
                  maxNumberOfItemsInColumn: 4,
                  noSeparator: true,
                  seeMoreItem: {
                    title: "header.tabs.platform.integrations.connect.buttonText",
                    url: INTEGRATIONS_PAGE_LINK
                  },
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: GitHubIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forDevelopment.gitHub",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: JiraIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forDevelopment.jiraCloud",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: PagerDutyIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forDevelopment.pagerDuty",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: GitLabIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forDevelopment.gitLab",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME)
                    }
                  ]
                },
                {
                  id: INTEGRATIONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.integrations,
                  title: "header.tabs.platform.integrations.connect.forMarketing.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: MailchimpIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forMarketing.mailchimp",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_MARKETING_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: HubSpotIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forMarketing.hubSpot",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_MARKETING_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: SurveyMonkeyIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forMarketing.surveyMonkey",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_MARKETING_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: GoogleLeadsIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forMarketing.googleAds",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_MARKETING_SECTION_HASH_NAME)
                    }
                  ]
                },
                {
                  id: INTEGRATIONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.integrations,
                  title: "header.tabs.platform.integrations.connect.forCollaboration.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: ZoomIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCollaboration.zoom",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_2ND_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: GoogleDriveIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCollaboration.googleDrive",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_2ND_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: MicrosoftTeamsIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCollaboration.microsoftTeams",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: SlackIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCollaboration.slack",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_1ST_ROW_HASH_NAME)
                    }
                  ]
                },
                {
                  id: INTEGRATIONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.integrations,
                  title: "header.tabs.platform.integrations.connect.forCommunication.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      title: "header.tabs.platform.integrations.connect.forCommunication.outlook",
                      icon: OutlookIcon,
                      iconType: ICON_TYPES.fill,
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_1ST_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: GmailIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCommunication.gmail",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_TEAM_FAVORITES_SECTION_3RD_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: ZendeskIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCommunication.zendesk",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_CRM_SECTION_3RD_ROW_HASH_NAME)
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      icon: TwilioIcon,
                      iconType: ICON_TYPES.fill,
                      title: "header.tabs.platform.integrations.connect.forCommunication.twilio",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: addHashToURL(INTEGRATIONS_PAGE_LINK, INTEGRATIONS_CRM_SECTION_2ND_ROW_HASH_NAME)
                    }
                  ]
                }
              ]
            }
          },
          {
            id: "Add-ons",
            title: "Add-ons",
            icon: AddOnsIcon,
            content: {
              title: "header.tabs.platform.addOns.extend.title",
              menuSections: [
                {
                  id: ADD_ONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.addOns,
                  title: "header.tabs.platform.addOns.extend.popular.title",
                  maxNumberOfItemsInColumn: 4,
                  noSeparator: true,
                  seeMoreItem: {
                    title: "header.tabs.platform.addOns.extend.buttonText",
                    url: APPS_MARKETPLACE_LINK
                  },
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      icon: MiroIcon,
                      iconType: ICON_TYPES.fill,
                      iconStrokeColor: colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01],
                      title: "header.tabs.platform.addOns.extend.popular.miro",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: MIRO_APP_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/DocuGen.svg",
                      title: "header.tabs.platform.addOns.extend.popular.docuGen",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: DOCU_GEN_APP_PAGE_LINK
                    }
                  ]
                },
                {
                  id: ADD_ONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.addOns,
                  title: "header.tabs.platform.addOns.extend.forMarketing.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/Easyform.svg",
                      title: "header.tabs.platform.addOns.extend.forMarketing.easyform",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: EASYFORM_APP_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/Figma.svg",
                      title: "header.tabs.platform.addOns.extend.forMarketing.figma",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: FIGMA_APP_PAGE_LINK
                    }
                  ]
                },
                {
                  id: ADD_ONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.addOns,
                  title: "header.tabs.platform.addOns.extend.forTeamManagement.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      icon: TracketIcon,
                      iconType: ICON_TYPES.fill,
                      iconStrokeColor: colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01],
                      title: "header.tabs.platform.addOns.extend.forTeamManagement.tracket",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: TRACKET_APP_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/Team_View.svg",
                      title: "header.tabs.platform.addOns.extend.forTeamManagement.teamView",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: TEAM_VIEW_APP_PAGE_LINK
                    }
                  ]
                },
                {
                  id: ADD_ONS_SECTION_ID,
                  classname: MENU_SECTION_CLASSNAMES.addOns,
                  title: "header.tabs.platform.addOns.extend.forProductivity.title",
                  noSeparator: true,
                  maxNumberOfItemsInColumn: 4,
                  menuSectionItems: [
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.addOn,
                      title: "header.tabs.platform.addOns.extend.forProductivity.1View",
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/1View.svg",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: ONE_VIEW_APP_PAGE_LINK
                    },
                    {
                      itemType: MENU_SECTION_ITEM_TYPE.integration,
                      imageSrc:
                        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/svg/header/platform/Approvals.svg",
                      title: "header.tabs.platform.addOns.extend.forProductivity.approvals",
                      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                      url: APPROVALS_APP_PAGE_LINK
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ],
    bottomItem: {
      itemType: MENU_SECTION_ITEM_TYPE.innerMenuBottomItem,
      title: "header.tabs.platform.whyWorkOS.title",
      hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
      description: "header.tabs.platform.whyWorkOS.description",
      url: WHY_WORK_OS_LINK,
      openInNewTab: true
    }
  }
};

const customersMenu = {
  content: {
    menuSections: [
      {
        id: LEADING_COMPANIES_SECTION_ID,
        title: "header.tabs.customers.section.leadingCompanies",
        noSeparator: true,
        withTitleUnderline: true,
        maxNumberOfItemsInColumn: 2,
        seeMoreItem: {
          title: "header.tabs.customers.section.leadingCompanies.buttonText",
          url: CUSTOMERS_PAGE_LINK
        },
        menuSectionItems: [
          {
            title: "header.tabs.customers.section.leadingCompanies.zippo.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.leadingCompanies.zippo.description",
            url: ZIPPO_CUSTOMER_PAGE_LINK
          },
          {
            title: "header.tabs.customers.section.leadingCompanies.nissan.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.leadingCompanies.nissan.description",
            url: NISSAN_CUSTOMER_PAGE_LINK
          },
          {
            title: "header.tabs.customers.section.leadingCompanies.softwareAG.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.leadingCompanies.softwareAG.description",
            url: SOFTWARE_AG_CUSTOMER_PAGE_LINK
          },
          {
            title: "header.tabs.customers.section.leadingCompanies.farfetch.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.leadingCompanies.farfetch.description",
            url: FARFETCH_CUSTOMER_PAGE_LINK
          }
        ]
      },
      {
        id: GET_INVOLVED_SECTION_ID,
        title: "header.tabs.customers.section.getInvolved",
        withTitleUnderline: true,
        maxNumberOfItemsInColumn: 2,
        menuSectionItems: [
          {
            title: "header.tabs.customers.section.getInvolved.community.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.getInvolved.community.description",
            url: COMMUNITY_LINK,
            openInNewTab: true
          },
          {
            title: "header.tabs.customers.section.getInvolved.reviews.title",
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            description: "header.tabs.customers.section.getInvolved.reviews.description",
            url: G2_WRITE_A_REVIEW_PAGE_LINK,
            openInNewTab: true
          }
        ]
      },
      {
        id: TOP_STORIES_SECTION_ID,
        title: "header.tabs.customers.section.rightSection.title",
        maxNumberOfItemsInColumn: 1,
        backgroundColor: BACKGROUND_GRAY_COLOR_NAME,
        menuSectionItems: [
          {
            itemType: MENU_SECTION_ITEM_TYPE.story,
            backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
            hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
            imageSrc:
              "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/efficiency_image.png",
            description: "header.tabs.customers.section.rightSection.card.description",
            url: EFFICIENCY_IMPACT_PAGE_LINK,
            openInNewTab: true,
            button: true,
            buttonText: "header.tabs.customers.section.rightSection.card.buttonText"
          }
        ]
      }
    ]
  },
  bottomPanel: {
    isSocialProofDisplayed: true
  }
};

const resourcesMenu = {
  content: {
    menuSections: [
      {
        id: RESOURCES_LEARN_SECTION_ID,
        title: "header.tabs.resources.section.learn",
        noSeparator: true,
        withTitleUnderline: true,
        maxNumberOfItemsInColumn: 3,
        menuSectionItems: [
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.learn.knowledgeBase.title",
            description: "header.tabs.resources.learn.knowledgeBase.description",
            url: HELP_CENTER_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.learn.aboutUs.title",
            description: "header.tabs.resources.learn.aboutUs.description",
            url: ABOUT_US_PAGE_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.learn.blog.title",
            description: "header.tabs.resources.learn.blog.description",
            url: BLOG_PAGE_LINK
          }
        ]
      },
      {
        id: RESOURCES_CONNECT_SECTION_ID,
        title: "header.tabs.resources.section.connect",
        noSeparator: true,
        withTitleUnderline: true,
        maxNumberOfItemsInColumn: 3,
        menuSectionItems: [
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.connect.support.title",
            description: "header.tabs.resources.connect.support.description",
            url: SUPPORT_PAGE_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.connect.partners.title",
            description: "header.tabs.resources.connect.partners.description",
            url: PARTNERS_PAGE_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.connect.globalEvents.title",
            description: "header.tabs.resources.connect.globalEvents.description",
            url: GLOBAL_EVENTS_PAGE_LINK
          }
        ]
      },
      {
        id: RESOURCES_BUILD_SECTION_ID,
        title: "header.tabs.resources.section.build",
        withTitleUnderline: true,
        maxNumberOfItemsInColumn: 3,
        menuSectionItems: [
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.explore.appsDeveloper.title",
            description: "header.tabs.resources.explore.appsDeveloper.description",
            url: APPS_DEVELOPER_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.connect.BuilderCertificationsText.title",
            description: "header.tabs.resources.connect.BuilderCertificationsText.description",
            url: BUILDERS_CERTIFICATIONS_PAGE_LINK
          },
          {
            itemType: MENU_SECTION_ITEM_TYPE.resource,
            hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
            title: "header.tabs.resources.explore.templateCenter.title",
            description: "header.tabs.resources.explore.templateCenter.description",
            url: TEMPLATE_CENTER_PAGE_LINK
          }
        ]
      },
      {
        id: TOP_STORIES_SECTION_ID,
        title: "header.tabs.resources.section.customersStories",
        maxNumberOfItemsInColumn: 2,
        backgroundColor: WHITE_COLOR_NAME,
        menuSectionItems: [
          {
            itemType: MENU_SECTION_ITEM_TYPE.story,
            backgroundColor: colors[GHOST_WHITE_COLOR_NAME],
            hoverBackgroundColor: colors[GHOST_WHITE_COLOR_NAME],
            imageSrc:
              "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/Header_asset.png",
            description: "header.tabs.resources.topStories.whyCustomersUse",
            url: MONDAY_STORIES_PAGE,
            button: true,
            buttonText: "header.menuItem.button.exploreAllStories"
          }
        ]
      }
    ]
  }
};

/** * Structured Header sections definitions - End ** */

export const StructuredHeaderMenuComponentMapping = {
  [SOLUTIONS_MENU_ID]: "StructuredHeaderMenuComponent",
  [TEAMS_MENU_ID]: "StructuredHeaderMenuComponent",
  [PLATFORM_MENU_ID]: "StructuredHeaderMenuComponent",
  [CUSTOMERS_MENU_ID]: "StructuredHeaderMenuComponent",
  [RESOURCES_MENU_ID]: "StructuredHeaderMenuComponent"
};

export const SectionsHeadersMenuItemsMapping = {
  [PRODUCTS_MENU_ID]: productsSectionMenuItems,
  [USE_CASES_MENU_ID]: useCasesSectionMenuItems,
  [FEATURES_MENU_ID]: featuresSectionMenuItems,
  [RESOURCES_MENU_ID]: resourcesSectionMenuItems
};

export const StructuredHeaderMenuMapping = {
  [SOLUTIONS_MENU_ID]: solutionsMenu,
  [TEAMS_MENU_ID]: teamsMenu,
  [PLATFORM_MENU_ID]: platformMenu,
  [CUSTOMERS_MENU_ID]: customersMenu,
  [RESOURCES_MENU_ID]: resourcesMenu
};

// ===========================================================================================

// Header service functions

export const isDesktopMinisiteSecondaryMenuActive = () => {
  const headerElement = document.querySelector(`.${DESKTOP_HEADER_BASE_CLASS_NAME}`);
  if (!headerElement) return false;
  return headerElement.classList.contains("scroll-started") && !headerElement.classList.contains("scrolled-threshold");
};

export const shouldRenderSecondaryMenu = (isClusterMiniSitePage, pageClusterConfig, useProductMiniSiteConfig) => {
  return isClusterMiniSitePage && isMiniSiteConfigValid(pageClusterConfig, { useProductMiniSiteConfig });
};
