import { PureComponent } from "react";
import { componentStyles } from "./checkbox-input.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import classnames from "classnames";

class CheckboxInput extends PureComponent {
  constructor(props) {
    super(props);
  }

  isChecked() {
    return this._input?.checked;
  }

  render() {
    const { inputData, id, defaultValue, onInputChange, translate } = this.props;
    const { inputName, text } = inputData;

    return (
      <div className="consent-checkbox" id={id}>
        <label className={classnames("input-title")}>
          <input
            className="checkbox"
            type="checkbox"
            defaultChecked={defaultValue}
            name={inputName}
            onChange={() => onInputChange({ target: { name: inputName, value: this.isChecked() } })}
            ref={(input) => (this._input = input)}
          />
          {translate(text)}
        </label>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(CheckboxInput);
