import { PureComponent } from "react";
import { componentStyles } from "./two-link-buttons-button.scss";
import DefaultProps from "./default-props";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import classnames from "classnames";

export default class TwoLinkButtonsButton extends PureComponent {
  render() {
    const { secondaryButtonConfig, regularButtonConfig, swapButtonsOrder } = this.props;

    return (
      <div className={classnames("two-link-buttons-button", { "swap-buttons-order": swapButtonsOrder })}>
        <div className="secondary-button-wrapper">
          <SecondaryButton {...secondaryButtonConfig}></SecondaryButton>
        </div>
        <div className="regular-button-wrapper">
          <RegularButton {...regularButtonConfig}></RegularButton>
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
TwoLinkButtonsButton.defaultProps = DefaultProps;