import css from "styled-jsx/css";

export const componentStyles = css.global`
  .bypass-block-menu-component {
    .menu-item {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;

      &:focus {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;
