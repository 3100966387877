import EmbeddedBoard from "segments/desktop/core-components/embedded-board/embedded-board";
import ViewsTabContentComponent from "components/core/live-board-experience/tabs-content/views-tab-content/views-tab-component";
import IntegrationsTabContentComponent from "components/core/live-board-experience/tabs-content/integrations-tab-content/integrations-tab-component";
import AutomationsTabContentComponent from "components/core/live-board-experience/tabs-content/automations-tab-content/automations-tab-component";
import InviteTabContentComponent from "components/core/live-board-experience/tabs-content/invite-tab-content/invite-tab-component";
import NotificationsTabContentComponent from "components/core/live-board-experience/tabs-content/notifications-tab-content/notifications-tab-component";
import DashboardsTabContentComponent from "components/core/live-board-experience/tabs-content/dashboards-tab-content/dashboards-tab-component";

export const MAIN_TABLE_TAB_ID = "MIAN_TABLE";
export const tabs = [
  {
    tabLabel: "Main table",
    tabID: MAIN_TABLE_TAB_ID,
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_home.svg"
    },
    liveBoardTabComponent: EmbeddedBoard,
    scrollVisible: true,
    componentProps: {
      hideTopFrame: true,
      tooltipText: "Start by adding an item",
      isLiveBoard: true
    }
  },
  {
    tabLabel: "Dashboards",
    tabID: "DASHBOARDS",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/icon_dashboard.svg"
    },
    liveBoardTabComponent: DashboardsTabContentComponent
  },
  {
    tabLabel: "Views",
    tabID: "VIEWS",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_views.svg"
    },
    liveBoardTabComponent: ViewsTabContentComponent
  },
  {
    tabLabel: "Integrations",
    tabID: "INTEGRATIONS",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_integrations.svg"
    },
    liveBoardTabComponent: IntegrationsTabContentComponent
  },
  {
    tabLabel: "Automations",
    tabID: "AUTOMATIONS",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_automations.svg"
    },
    liveBoardTabComponent: AutomationsTabContentComponent
  },
  {
    tabLabel: "Invite",
    tabID: "INVITE",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_invite.svg"
    },
    liveBoardTabComponent: InviteTabContentComponent
  },
  {
    tabLabel: "Notifications",
    tabID: "NOTIFICATIONS",
    image: {
      src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/2_icon_notifications.svg"
    },
    liveBoardTabComponent: NotificationsTabContentComponent
  }
];

export const LIVE_BOARD_VW_WIDTH = 90;
export const RIGHT_SECTION_VW_WIDTH = LIVE_BOARD_VW_WIDTH;
export const RIGHT_SECTION_RIGHT_VW_MARGIN = 2;
export const BANNER_RIGHT_VW_MARGIN = 3;
export const BANNER_LEFT_VW_MARGIN = 1;
export const BANNER_VW_WIDTH = LIVE_BOARD_VW_WIDTH - BANNER_LEFT_VW_MARGIN - BANNER_RIGHT_VW_MARGIN;

export const LIVE_BOARD_BUTTON_CLOSE_ICON =
  "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/live-board-2022/button/close_icon.svg";
